import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SettingsService } from '../service/settings.service';
import { MatDialog } from '@angular/material/dialog';
import { Tridys } from './../../../../backend/src/tridys.dto';
import { DeleteConfirmationDialogComponent } from '../delete-confirmation-dialog/delete-confirmation-dialog.component';
import { CommentsService } from '../service/comments.service';
import { QrCodeDialogComponent } from '../qr-code-dialog/qr-code-dialog.component';
import { Location } from '@angular/common';
import { Timestamp } from 'firebase/firestore';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { isDateTime } from '../../../../backend/src/filter';

@Component({
  selector: 'app-tridys-item',
  templateUrl: './tridys-item.component.html',
  styleUrls: ['./tridys-item.component.css']
})
export class TridysItemComponent {
  tridyData:any
  routerUrl:any
  idToShow:any
  finishedTasks:any=[]
  allContentTypes:any=[]
  payload:any
  tridyValues: Tridys
  contentType:string
  panelOpenState=false
  tridyName:any
  tridyDescription:any
  tridyTagline:any
  tridyArray:any
  tridyMap:any
  finishedTasksData:any
  resize:number;
  backToPath:any
  backToPathName:any
  allpayload:any = []
  media:any={}
  mediaData:any
  urlData:any = {}
  outgoing:any
  incoming:any
  contentTypesList:any = []
  allTridysList:any

  constructor(private router: Router,
    private route: ActivatedRoute,
    private settingService: SettingsService,
    public dialog: MatDialog,
    private commentService: CommentsService,
    private location:Location,
    private translate:TranslateService
    ){
    this.routerUrl=this.route.url
    this.idToShow=this.routerUrl.value[this.routerUrl.value.length-1].path  
    if(this.routerUrl.value[2].path=="reports" && this.routerUrl.value[4].path=="filter" ){
      this.backToPath = this.routerUrl.value[3].path
    }else{
      this.backToPath = this.routerUrl.value[this.routerUrl.value.length-2].path
    }
  }

  ngOnInit(): void {
    if(this.settingService.settingsContentTypesWithId.length==0){
      this.getContentTypes()
    }else{
      this.contentTypesList = this.settingService.settingsContentTypesWithId
    }

    if(this.settingService.allTridys$.length!=0){
      this.allTridysList = this.settingService.allTridys$
    }else{
      this.settingService.observeTridysFirestore()
      this.allTridysList = this.settingService.allTridys$
    }
    // this.getContentTypes()

    this.resize = (window.innerWidth <= 760) ? 1 : 2;

    if(this.backToPath=="tridy"){
      this.settingService.allReports$.forEach(element => {
        if(element.id == this.routerUrl.value[2].path){
          this.backToPathName = element.displayName
        }
      })
    
    }else{
      this.backToPathName = "Tridys"
    }
   try{
    this.settingService.observeTridysIdFirestore(this.idToShow).then(tridy => {
      this.tridyData = tridy
      if(this.tridyData.outgoing){
        this.outgoing = this.tridyData.outgoing
      }

      if(this.tridyData.incoming){
        this.incoming = this.tridyData.incoming
      }

      this.tridyValues = new Tridys()
      this.tridyValues.setFromAny(this.tridyData)
      this.payload = this.tridyValues.getPayload()

      if(this.payload){
        this.allpayload = Object.entries(this.payload)
        if(this.payload.name){
          this.tridyName=this.payload.name
        }
  
        if(this.payload.description){
          this.tridyDescription=this.payload.description
        }
  
        if(this.payload.tagline){
          this.tridyTagline=this.payload.tagline
        }
  
        if(this.payload.array){
          this.tridyArray=this.payload.array
        }
  
        if(this.payload.map){
          this.tridyMap=this.payload.map
        }
      }

      if(this.tridyData && this.tridyData.media){
        this.mediaData = this.tridyData.media
        if(this.payload){
          const payloadKeys = Object.keys(this.payload)
        }

        this.tridyData.media.forEach( med=> {
          this.media[med.key] = med.files
          if(med.files){
            med.files.forEach(file => {
              this.supportUrl(file.url, med.key)
            });
          }
        })
      }
      this.contentType = this.tridyValues.contentType
    })
   }catch(error){
    
   }
    
    this.getFinishedTasks()
    this.finishedTasks.forEach(task => {
      const data ={
        
      }
    });

  }

  onResize(event) {
    this.resize = (event.target.innerWidth <= 760) ? 1 : 2;
  }

  backTo(){
    this.location.back()
  }


  getObjectData(payload){
    const values = Object.keys(payload)
    return values
  }

  getFinishedTasks(): void {
    this.settingService.observeTridysIdFinishedTasksFirestore(this.idToShow)
      .then(sortedTasks => {
        this.finishedTasks = sortedTasks;
        //console.log(this.finishedTasks);
      })
      .catch(error => {
        console.error('Error fetching tasks:', error);
      });
  }

  async getContentTypes(){
    try{
      this.allContentTypes = await this.settingService.observeContextsContentTypesFirestoreWithId(this.settingService.contextId$)
    }catch(error){
      console.log(error)
    }
   
  }

  deleteTridy(){
    let dialogRef= this.dialog.open(DeleteConfirmationDialogComponent, {
      data: { 
        values:this.tridyData,
        type:"Tridy"
      },
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if(result[0]){
        this.router.navigate(['home', this.settingService.contextId$,'tridys'])
      }else{
        const message = this.translate.instant("SnackBarConstants.DELETE_DISMISS")
        this.commentService.addSnackBar.emit(message)
      }
    });
  }


  isObject(obj){
    return typeof obj === 'object'; 
  }

  QRoptions(){
    let contentype = this.allContentTypes?.find( ct => ct.id == this.contentType)
    let nfcPrefix = this.getContentTypeIdData(this.tridyData.contentType, 'nfcURLPrefix')
    let dialogRef= this.dialog.open(QrCodeDialogComponent, {
      data: {
        tridy:this.tridyData,
        id: this.idToShow,
        nfcPrefix: nfcPrefix ?? undefined,
        name:  contentype?.displayName ?? contentype?.name ?? this.idToShow
      }
    });
  
  }


  supportUrl(url, key){
    if(url){
      this.urlData[key]=url

     try{
      const imgUrl = new URL(url)
      let hostname = environment.urlHostName
      
      if(imgUrl.protocol=="pass:" ){
        const parts = url.match(/pass:\/\/(.+)\/(.+)/)
        if(parts){
          const passTemplateID = parts[1]
          const path = parts[2]
          const firebaseUrl = `https://${hostname}/v1/pass/${passTemplateID}/${path}`
          this.urlData[key]=firebaseUrl
        }else{
          const newparts = url.match(/pass:\/\/(.+)/)
          if(newparts){
            let firebaseUrl = `https://${hostname}/v1/pass/${this.idToShow}`
            newparts.forEach( (part,index)=> {
              if(index>0){
                firebaseUrl += '/' + part 
              }
            })
            this.urlData=firebaseUrl
          }
        }
      } 

      if(imgUrl.protocol =="tridy:"){
        const parts = url.match(/tridy:\/\/(.+)\/(.+)/)
        if(parts){
          let path =""
          parts.forEach((parte, index) =>{
            if(index>0 && index<parts.length){
              path += "/" + parte
            }
          })
          const firebaseUrl = `https://${hostname}/v1/pass${path}`

          this.urlData[key]=firebaseUrl
        }else{
          let path = "/"+this.idToShow
        const partsx = url.match(/tridy:\/\/(.+)/)

        partsx.forEach((parte, index) =>{
          if(index>0 && index<partsx.length){
            path += "/" + parte
          }
        })
        const firebaseUrl = `https://${hostname}/v1/pass${path}`

        this.urlData[key]=firebaseUrl
        }
      }
     }catch(erro){}
    }
  }

  getContentTypeIdData(id, field){
    const content =  this.contentTypesList.find( ct => ct.id == id)
    if(content && content[field])
    return content[field]
  }

  isDateTime(element){
    return isDateTime(element)
  }

  getTRIDYIdData(tridyId, field){
    if(this.allTridysList){
      const tridy = this.allTridysList.find( id => tridyId == id.id)
      if(tridy && tridy[field]){
        return tridy[field]
      }
    }
  }

}
