import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { deepCopy, supportUrlStructure } from '../../../../backend/utils/object';
import { Template } from '../../../../backend/src/template.dto';
import { widgetTypes } from '../widgetTypes';
import { FormBuilder, FormGroup } from '@angular/forms';
import { languagesList } from '../../../../backend/src/languagesList.dto';
import { SettingsService } from '../service/settings.service';
import { NgDialogAnimationService } from 'ng-dialog-animation';
import { TranslationsDPPService } from '../service/translations-dpp.service';
import { TextTranslationEditComponent } from '../widgets-translationMode/text-translation-edit/text-translation-edit.component';
import { ImageTranslationEditComponent } from '../widgets-translationMode/image-translation-edit/image-translation-edit.component';
import { AnnotatedMapTranslationEditComponent } from '../widgets-translationMode/annotated-map-translation-edit/annotated-map-translation-edit.component';
import { ButtonsTranslationEditComponent } from '../widgets-translationMode/buttons-translation-edit/buttons-translation-edit.component';

@Component({
  selector: '[app-pass-templates-translations]',
  templateUrl: './pass-templates-translations.component.html',
  styleUrls: ['./pass-templates-translations.component.css']
})
export class PassTemplatesTranslationsComponent {
  @Input('app-pass-templates-translations') data:any
  @Output() returnChangesTranslations = new EventEmitter<{state: any, changes:boolean, translations:any}>()
  formData:any
  languagesPassList:any
  editTemp = false
  allWidgets:any
  templateData:any
  passTemplateLanguages:any = []
  passTemplateLanguagesO:any = {}
  darkMode = false
  isTablet: boolean = false
  phoneFullHeightView = false
  idToShow:any
  widgetsNames = widgetTypes
  themeData:any
  languagesList = languagesList
  languageSelected:any
  contextLanguages:any=[]
  contextFirstLanguage:any
  passFirstLanguage:any
  passLanguages:any=[]
  templateForm!:FormGroup
  translationForm!:FormGroup
  changesToSave=false
  contentTypeId:any
  oldTranslations:any
  languagesToTranslate:any
  oldTemplateData:any
  defaultColorsValues:any={
    'accent':'rgb(255, 94, 56)',
    'background':'rgb(255, 255, 255)',
    'title':'rgb(0, 0, 0)',
    'subtitle':'rgb(60, 60, 67,60%)',
    'primary':'rgb(0, 0, 0)',
    'secondary':'rgb(60, 60, 67, 60%)'
  }
  defaultColorsValuesDark:any={
    'accent':'rgb(255, 94, 56)',
    'background':'rgb(0, 0, 0)',//'rgb(255, 255, 255)',
    'title':'rgb(255,255,255)',//'rgb(0, 0, 0)'
    'subtitle':'rgb(129,129,129)',//'rgb(60, 60, 67, 0.60)'
    'primary':'rgb(255,255,255)',//'rgb(0, 0, 0)',
    'secondary':'rgb(129,129,129)'//'rgb(60, 60, 67, 0.60)'
  }
  widgetsWithTranslations = {
    image:['title','subtitle', 'url', 'linkURL'],
    billboardImage:false,
    billboardVideo:false,
    buttons:['title', 'url'],
    callToAction: false,
    text:['title','subtitle', 'text'],
    imageBlocks:false,
    imagesHorizontal:false,
    images:false,
    list:false,
    annotatedMap:['title','subtitle', 'annotations.title', 'annotations.subtittle','annotations.linkURL'],
    video:false,
    splashVideo:false,
    spacer:false,
    "3dmodel":false,
    form: false,
    notification:false,
    productRegistration:false
  }

  widgetsTranslationsFields={
    buttons: [
      {
        key:'title',
        type:'string',
        inputType:'text'
      },
      {
        key:'url',
        type:'string',
        inputType:'url'
      },
    ],
    text: [
      {
        key:'title',
        type:'string',
        inputType:'text'
      },
      {
        key:'subtitle',
        type:'string',
        inputType:'text'
      },
      {
        key:'text',
        type:'string',
        inputType:'textarea'
      }
    ],
    image: [
    {
      key:'title',
      type:'string',
      inputType:'text'
    },
    {
      key:'subtitle',
      type:'string',
      inputType:'text'
    },
    {
      key:'url',
      type:'string',
      inputType:'url'
    },
    {
      key:'linkURL',
      type:'string',
      inputType:'url'
    }
    ],
    annotatedMap: [
      {
        key:'title',
        type:'string',
        inputType:'text'
      },
      {
        key:'subtitle',
        type:'string',
        inputType:'text'
      },
      {
        key:'annotations.title',
        type:'string',
        inputType:'text'
      },
      {
        key:'annotations.subtitle',
        type:'string',
        inputType:'text'
      },
      {
        key:'annotations.linkURL',
        type:'string',
        inputType:'url'
      }

    ]
  }

  constructor(private fb : FormBuilder,
    private settingsService:SettingsService,
    public dialog: NgDialogAnimationService,
    private dppTranslations:TranslationsDPPService
    ){}
  
  ngOnInit(): void {
    this.contextLanguages = deepCopy(this.settingsService.languagesContexts$)
    this.languagesPassList = deepCopy(this.contextLanguages)
    this.contextFirstLanguage = this.settingsService.firstLanguageContexts$

    //console.log(this.data)
    this.formData=deepCopy(this.data[0])
    this.templateData = new Template
    this.templateData.setFromAny(this.formData)
    this.passTemplateLanguages.push(this.formData)
    this.passTemplateLanguagesO['default']= deepCopy(this.formData)

    this.translationForm = this.fb.group({
      translations: [],
      selectedLanguage:[],
      widgetsWithTranslations:[],
      widgetDefaultLanguage:[]
    })

    this.initializeForms()

    this.oldTemplateData=this.data[1]
    this.languagesPassList = this.data[3]

    this.contentTypeId = this.data[4]
    this.idToShow = this.data[5]
    this.passFirstLanguage = this.data[6]//defaultLanguage

    if(this.formData.widgets){
      this.allWidgets = deepCopy(this.formData.widgets)
      this.translationForm.patchValue({widgetsWithTranslations : this.allWidgets})
      this.translationForm.patchValue({widgetDefaultLanguage : this.allWidgets})
    }
    
    if(this.formData.theme)
      this.themeData = this.formData.theme

    if(this.formData.translations){
      this.oldTranslations = deepCopy(this.formData.translations)
      this.translationForm.patchValue({translations : this.formData.translations ? this.formData.translations : {}})
    }

    if(this.dppTranslations.firstLanguage$.value){
      this.passFirstLanguage = this.dppTranslations.firstLanguage$.value
    }
    

    if(this.languagesPassList && this.languagesPassList.length>0){
      this.passFirstLanguage = this.languagesPassList[0]
      this.languageSelected = this.languagesPassList[1]
      this.changePassLanguage(this.languageSelected)
    }else{
      this.languagesPassList = this.data[2]
      if(this.languagesPassList && this.languagesPassList.length>0){
        this.passFirstLanguage = this.languagesPassList[0]
        this.languageSelected = this.languagesPassList[1]
        this.changePassLanguage(this.languageSelected)
      }
    }

    this.languagesToTranslate = deepCopy(this.languagesPassList)

    this.translationForm.get('translations').valueChanges.subscribe(value=>{
      //console.log('values translations', value)
      this.loadTranslationsForSelectedLanguage(this.languageSelected,value)
    })

  }

  initializeForms(){
    this.templateForm = this.fb.group({
      contextId: [],
      id: [],
      theme: [],
      comment: [],
      name: [],
      contentType: [],
      customer: [],
      tridyId: [],
      widgets:[], 
      translations:[],
      languages:[],
      enableShoppingCart:[],
      enableCustomerLogin:[]
    })

    const {id, contextId, theme, comment, name, contentType, customer, tridyId, widgets, translations,languages,enableShoppingCart,enableCustomerLogin} = this.formData
    this.templateForm.patchValue({
      contextId: contextId ? contextId : undefined,
      id: id ? id : undefined,
      theme: theme ? theme : undefined,
      comment: comment ? comment : undefined,
      name: name ? name : undefined,
      contentType: contentType ? contentType : this.contentTypeId,
      customer: customer ? customer : undefined,
      tridyId: tridyId ? tridyId : undefined,
      widgets: widgets ? widgets : undefined,
      translations: translations ? translations : undefined,
      languages:languages ? languages : undefined,
      enableShoppingCart: enableShoppingCart ? enableShoppingCart : false,
      enableCustomerLogin: enableCustomerLogin ? enableCustomerLogin : false
    })

    this.setWidgetSpacingCornerRadius(theme)
    this.templateForm.valueChanges.subscribe(values=> {
      this.changesToSave=true
    })
    
  }

  addTranslations(){
    const newtranslations = deepCopy(this.translationForm.value.translations)
    // this.dppTranslations.addTranslations(newtranslation)
    this.returnChangesTranslations.emit({state: 'add', changes: this.changesToSave,translations: newtranslations})
  }

  closeTranslations(){
    const newtranslations = this.oldTranslations
    this.returnChangesTranslations.emit({state: 'cancel', changes: false,translations: newtranslations})
  }

  checkTypeNumber(el, indexWidget){
    let typeNumber = 1
    this.allWidgets.forEach((widget, index) => {
      if(index<indexWidget){
        if(widget.type == el.type){
          typeNumber ++
        }
      }
    })
    return typeNumber.toString()
  }

  setWidgetSpacingCornerRadius(theme){
    if(theme){
      if(theme.widgetSpacing){
        document.documentElement.style.setProperty('--widgetSpacing',theme.widgetSpacing+'px')
      }
      else if(theme.widgetSpacing === 0){
        document.documentElement.style.setProperty('--widgetSpacing','0')
      }else{
        document.documentElement.style.setProperty('--widgetSpacing','20px')
      }
  
      if(theme.cornerRadius){
        document.documentElement.style.setProperty('--cornerRadius',theme.cornerRadius+'px')
      }
      else if(theme.cornerRadius === 0){
        document.documentElement.style.setProperty('--cornerRadius','0')
      }else{
        document.documentElement.style.setProperty('--cornerRadius','10px')
      }
    }else{
      document.documentElement.style.setProperty('--cornerRadius','10px')
      document.documentElement.style.setProperty('--widgetSpacing','20px')
    }

  }

  changeTheme(value){///change this after change toggle dark(light)
    this.darkMode=value
    if(this.darkMode){
      this.setColorsToDark()
    }else{
      this.setColorsToLight()
    }
  }

  setColorsToDark(){
    if(this.themeData && this.themeData['darkColors']){
      if(this.themeData['darkColors'].accent)
        document.documentElement.style.setProperty('--accent',this.themeData['darkColors'].accent)
      else
        document.documentElement.style.setProperty('--accent',this.defaultColorsValuesDark.accent)

      if(this.themeData['darkColors'].background)
        document.documentElement.style.setProperty('--background',this.themeData['darkColors'].background)
      else
        document.documentElement.style.setProperty('--background',this.defaultColorsValuesDark.background)

      if(this.themeData['darkColors'].title)
        document.documentElement.style.setProperty('--title',this.themeData['darkColors'].title)
      else
        document.documentElement.style.setProperty('--title',this.defaultColorsValuesDark.title)

      if(this.themeData['darkColors'].subtitle)
        document.documentElement.style.setProperty('--subtitle',this.themeData['darkColors'].subtitle)
      else
        document.documentElement.style.setProperty('--subtitle',this.defaultColorsValuesDark.subtitle)

      if(this.themeData['darkColors'].primary)
        document.documentElement.style.setProperty('--primary',this.themeData['darkColors'].primary)
        else
        document.documentElement.style.setProperty('--primary',this.defaultColorsValuesDark.primary)

      if(this.themeData['darkColors'].secondary)
        document.documentElement.style.setProperty('--secondary',this.themeData['darkColors'].secondary)
      else
        document.documentElement.style.setProperty('--secondary',this.defaultColorsValuesDark.secondary)
    }
    else{
      document.documentElement.style.setProperty('--accent',this.defaultColorsValuesDark.accent)
      document.documentElement.style.setProperty('--background',this.defaultColorsValuesDark.background)
      document.documentElement.style.setProperty('--title',this.defaultColorsValuesDark.title)
      document.documentElement.style.setProperty('--subtitle',this.defaultColorsValuesDark.subtitle)
      document.documentElement.style.setProperty('--primary',this.defaultColorsValuesDark.primary)
      document.documentElement.style.setProperty('--secondary',this.defaultColorsValuesDark.secondary)

    }
  }

  setColorsToLight(){
    if(this.themeData && this.themeData['lightColors']){
      if(this.themeData['lightColors'].accent)
        document.documentElement.style.setProperty('--accent',this.themeData['lightColors'].accent)
      else
        document.documentElement.style.setProperty('--accent',this.defaultColorsValues.accent)

      if(this.themeData['lightColors'].background)
        document.documentElement.style.setProperty('--background',this.themeData['lightColors'].background)
      else
        document.documentElement.style.setProperty('--background',this.defaultColorsValues.background)

      if(this.themeData['lightColors'].title)
        document.documentElement.style.setProperty('--title',this.themeData['lightColors'].title)
      else
        document.documentElement.style.setProperty('--title',this.defaultColorsValues.title)

      if(this.themeData['lightColors'].subtitle)
        document.documentElement.style.setProperty('--subtitle',this.themeData['lightColors'].subtitle)
      else
        document.documentElement.style.setProperty('--subtitle',this.defaultColorsValues.subtitle)

      if(this.themeData['lightColors'].primary)
        document.documentElement.style.setProperty('--primary',this.themeData['lightColors'].primary)
      else
        document.documentElement.style.setProperty('--primary',this.defaultColorsValues.primary)

      if(this.themeData['lightColors'].secondary)
        document.documentElement.style.setProperty('--secondary',this.themeData['lightColors'].secondary)
      else
        document.documentElement.style.setProperty('--secondary',this.defaultColorsValues.secondary)
      
    }
    else{
      document.documentElement.style.setProperty('--accent',this.defaultColorsValues.accent)
      document.documentElement.style.setProperty('--background',this.defaultColorsValues.background)
      document.documentElement.style.setProperty('--title',this.defaultColorsValues.title)
      document.documentElement.style.setProperty('--subtitle',this.defaultColorsValues.subtitle)
      document.documentElement.style.setProperty('--primary',this.defaultColorsValues.primary)
      document.documentElement.style.setProperty('--secondary',this.defaultColorsValues.secondary)

    }
  }

  changePassLanguage(lang){
    //console.log('-->changePassLanguage', lang)
    this.translationForm.patchValue({selectedLanguage : lang})

    this.templateData = new Template()
    this.templateData.setFromAny(this.passTemplateLanguagesO['default'])
    // this.showTranslatedPass(lang) //-----comment on 03.09 16:30
    this.loadTranslationsForSelectedLanguage(lang)

    if(!this.passTemplateLanguagesO[lang]){
      this.templateData = new Template()
      this.templateData.setFromAny(this.passTemplateLanguagesO['default'])
    }else{
      this.templateData = new Template()
      this.templateData.setFromAny(this.passTemplateLanguagesO[lang])
    }

  }
  
  showTranslatedPass(lang){
    const copy = deepCopy(this.templateData)
    const template = new Template()
    template.setFromAny(copy)

    let teform = this.fb.group({
      contextId: [],
      id: [],
      theme: [],
      comment: [],
      name: [],
      contentType: [],
      customer: [],
      tridyId: [],
      widgets:[], 
      translations:[]
    })

    const {id, contextId, theme, comment, name, contentType, customer, tridyId, widgets, translations} = template
    teform.patchValue({
      contextId: contextId ? contextId : undefined,
      id: id ? id : undefined,
      theme: theme ? theme : undefined,
      comment: comment ? comment : undefined,
      name: name ? name : undefined,
      contentType: contentType ? contentType : undefined,
      customer: customer ? customer : undefined,
      tridyId: tridyId ? tridyId : undefined,
      widgets: widgets ? widgets : undefined,
      translations: translations ? translations : undefined
    })
    this.languageSelected = lang
      if(this.templateData.translations && this.templateData.translations[lang] ){
        const entries = Object.entries(this.templateData.translations[lang])
        
        entries.forEach( translation => {
          const idField = translation[0].split(".")
          const id = idField[0]
          const field = idField[1]
          const value = translation[1]

          if(teform.value.widgets && value){
            const widgets = deepCopy(teform.value.widgets)
            widgets.forEach( (widget, index) => {
              if(id == widget['id']){
                widget[field] = value
              }

              if(id.includes(widget['id']+'_')){
                if(widget['type']=="annotatedMap"){
                  if(widget['annotations']){
                    const annotations = widget['annotations']
                    annotations.forEach((annotation, index) => {
                      if(id == widget['id']+'_'+annotation.id){
                        annotation[field] = value
                      }
                    });
                  }
                }

                if(widget['type'] == 'buttons'){
                  const buttons = widget['buttons']
                  if(buttons){
                    buttons.forEach((btn, indexBTN) => {
                      if(id == widget['id']+'_'+btn.id){
                        // if(btn[field])
                          btn[field] = value
                      }
                    });
                  }
                }
              }
            })
            teform.patchValue({widgets: widgets})
            this.allWidgets = widgets
          }
        })
        
        if(!this.passTemplateLanguagesO[lang])
          this.passTemplateLanguages.push(teform.value)
        this.passTemplateLanguagesO[lang]=teform.value
      }else{

        if(teform.value.widgets){
          const widgets = deepCopy(teform.value.widgets)
          // widgets.forEach( (widget, index) => {
          //   switch(widget['type']){
          //     case 'billboardImage':{
          //       if(widget['url']){
          //         widget['url'] = supportUrlStructure(widget['url'], this.idToShow)
          //       }
          //       if(widget['linkURL']){
          //         widget['linkURL'] = supportUrlStructure(widget['linkURL'], this.idToShow)
          //       }
          //       break;
          //     }
          //     case 'billboardVideo':{
          //       if(widget['url']){
          //         widget['url'] = supportUrlStructure(widget['url'],this.idToShow)
          //       }
          //       break;
          //     }
          //     case 'text':{
          //       if(widget['image']){
          //         if(widget['image']['url'])
          //           widget['image']['url'] = supportUrlStructure(widget['image']['url'], this.idToShow)

          //         if(widget['image']['linkURL'])
          //           widget['image']['linkURL'] = supportUrlStructure(widget['image']['linkURL'],this.idToShow)
          //       }
          //       break;
          //     }
          //     case 'image':{
          //       if(widget['url']){
          //         widget['url'] = supportUrlStructure(widget['url'], this.idToShow)
          //       }

          //       if(widget['linkURL']){
          //         widget['linkURL'] = supportUrlStructure(widget['linkURL'],this.idToShow)
          //       }
          //       break;
          //     }
          //     case 'images':{
          //       const images = widget['images']
          //       if(images){
          //         images.forEach((image, indexImgs) => {
          //           if(image['url'])
          //             widget['images'][indexImgs]['url'] = supportUrlStructure(image['url'],this.idToShow)
          //           // image['url'] = supportUrlStructure(image['url'])

          //           if(image['linkURL'])
          //             widget['images'][indexImgs]['linkURL'] = deepCopy(supportUrlStructure(image['linkURL'], this.idToShow))
          //         });
          //       }

          //       break;
          //     }
          //     case 'annotatedMap':{
          //       if(widget['annotations']){
          //         const annotations = widget['annotations']
          //         annotations.forEach((annotation, index) => {
          //           if(annotation['linkURL']){
          //             widget['annotations'][index]['linkURL'] = supportUrlStructure(annotation['linkURL'],this.idToShow)
          //           }                      
          //         });
          //       }
          //       break;
          //     }
          //     case 'callToAction':{
          //       if(widget['imageUrl']){
          //         widget['imageUrl'] = supportUrlStructure(widget['imageUrl'],this.idToShow)
          //       }
          //       break;
          //     }
          //     case 'video':{
          //       if(widget['url']){
          //         widget['url'] = supportUrlStructure(widget['url'], this.idToShow)
          //       }
          //       break;
          //     }
          //     case 'splashVideo':{
          //       if(widget['url']){
          //         widget['url'] = supportUrlStructure(widget['url'],this.idToShow)
          //       }
          //       break;
          //     }

          //     case 'form':{
          //       break;
          //     }

          //     case 'buttons':{
          //       const buttons = widget['buttons']
          //       if(buttons){
          //         buttons.forEach((btn, indexBTN) => {
          //         if(btn['url'] ){
          //           widget['buttons'][indexBTN]['url'] = supportUrlStructure(btn['url'], this.idToShow)
          //           // widget['url'] = supportUrlStructure(btn['url'])
          //         }
          //         });
          //       }
          //       break;
          //     }

          //     case 'spacer':{
          //       break;
          //     }s

          //     case 'list':{
          //       break;
          //     }
          //   }
          // })
          teform.patchValue({widgets: widgets})
          this.allWidgets = widgets
        }
        this.passTemplateLanguages.push(teform.value)
        this.passTemplateLanguagesO[lang]=teform.value
      }

    this.translationForm.patchValue({widgetsWithTranslations : this.allWidgets})
  }

  onLanguageEditor(widget, index){
    //console.log('selectedLanguage', this.translationForm.value.selectedLanguage, '___passfirstLanguage: ', this.passFirstLanguage)
    if(this.translationForm.value.selectedLanguage && this.translationForm.value.selectedLanguage != this.passFirstLanguage){
      const widgetDefaultLanguage  = this.formData?.widgets[index]

      if(this.widgetsTranslationsFields && this.widgetsTranslationsFields[widget.type]){
        let alltranslationsWidget = {}
        let translationsWidget
        
        if(this.translationForm.value.translations){
          translationsWidget = Object.keys(this.translationForm.value.translations) 
        }else{
          translationsWidget = []
        }
  
        if(this.languagesPassList)
          translationsWidget=this.languagesPassList
  
        if(translationsWidget.length!=0){
          translationsWidget.forEach(lang => {
            if(lang == this.translationForm.value.selectedLanguage){
              if(this.translationForm.value.translations){
                if(this.translationForm.value.translations[lang]){
                  let entries = Object.entries(this.translationForm.value.translations[lang]);
                  entries.forEach( ent => {
                    if(ent[0].includes(widget.id)){
                      const key = lang+ "." + ent[0]
                      alltranslationsWidget[key] = ent[1]
                    }
                  })
                }
              }
            }
          })          
        }
  
    //----------------------------------------------
        let dialogRef
        let dialogName
        switch(widget.type){
          case 'text':{
            dialogName=TextTranslationEditComponent
            break;
          }
          case 'image':{
            dialogName = ImageTranslationEditComponent
            break;
          }
          case 'annotatedMap':{
            dialogName = AnnotatedMapTranslationEditComponent
            break;
          }
          case 'buttons':{
            dialogName = ButtonsTranslationEditComponent
            break;
          }
        }
        dialogRef = this.dialog.open(dialogName, {
          data:{
            translations: this.translationForm.value.translations,
            fields: this.widgetsTranslationsFields[widget.type],
            columns: this.widgetsWithTranslations[widget.type],//['title', 'subtitle','text'],
            values: widget,
            type: widget.type,
            valuesTranslated: alltranslationsWidget,
            languages: translationsWidget,
            selectedLanguage:this.translationForm.value.selectedLanguage,
            oldTemplateData:this.oldTemplateData,
            passId: this.idToShow,
            contentTypeId: this.contentTypeId,
            widget:index,
            defaultLanguageWidgetData: widgetDefaultLanguage
          },
          panelClass: 'custom-dialog-container',
          position: { right: '0'},
          animation: {
            to: "left",
            incomingOptions: {
              keyframeAnimationOptions: { duration: 200, easing: "ease-in-out" }
            },
            outgoingOptions: {
              keyframeAnimationOptions: { duration: 200, easing: "ease-in-out" }
            }
          }
        });
        dialogRef.afterClosed().subscribe(result =>{
          if(result[1])
          this.addMoreTranslationsToThisWidgetType(result, widget.type)
        })
    //----------------------------------------------
      }
    }else{
      console.log('not possible to add transaltions on default language')
    }
  }

  addMoreTranslationsToThisWidgetType(result, type){
    //console.log('____________add new translations to translations', result)
    let newtranslations = {}
  
    if(this.translationForm.value.translations){
      newtranslations = this.translationForm.value.translations
    }

      if(result[1]){
        const data = result[0]
        const trans = Object.entries(data)
        trans.forEach( value => {
          const key = value[0]
          const keyName = key.split(".")
          const lang = keyName[0]
          const id = keyName[1]
          const field = keyName[2]
          const tranlationField = id +"."+field

          //------------------------widgetData on first Language translations
          // if(lang == this.firstLanguageContexts){
          //   if(this.textEditForm.value[field]){
          //     this.textEditForm.patchValue({[field]: value[1]})
          //   }
          // }
          if(value[1]){
            if(newtranslations[lang]){
              newtranslations[lang][tranlationField] = value[1]
            }else{
              newtranslations[lang]= {}
              newtranslations[lang][tranlationField] = value[1]
            }
          }else{

            if(newtranslations[lang]){
              newtranslations[lang][tranlationField] = undefined

            }else{
              newtranslations[lang] = {}
              newtranslations[lang][tranlationField] = undefined
            }
          }
        })
        this.translationForm.patchValue({translations: newtranslations})
      }
    
  }

  loadTranslationsForSelectedLanguage(lang, formtranslations?){
    const copy = deepCopy(this.templateData)
    const template = new Template()
    template.setFromAny(copy)

    let teform = this.fb.group({
      contextId: [],
      id: [],
      theme: [],
      comment: [],
      name: [],
      contentType: [],
      customer: [],
      tridyId: [],
      widgets:[], 
      translations:[]
    })

    const {id, contextId, theme, comment, name, contentType, customer, tridyId, widgets} = template
    let translations = this.translationForm.value.translations

    if(formtranslations)
      translations = formtranslations

    teform.patchValue({
      contextId: contextId ? contextId : undefined,
      id: id ? id : undefined,
      theme: theme ? theme : undefined,
      comment: comment ? comment : undefined,
      name: name ? name : undefined,
      contentType: contentType ? contentType : undefined,
      customer: customer ? customer : undefined,
      tridyId: tridyId ? tridyId : undefined,
      widgets: widgets ? widgets : undefined,
      translations: translations ? translations : undefined
    })

    this.languageSelected = lang
      if(translations && translations[lang] ){
        const entries = Object.entries(translations[lang])
        
        entries.forEach( translation => {
          const translationField = translation[0]
          const idField = translation[0].split(".")
          const id = idField[0]
          const field = idField[1]
          const value = translation[1]

          if(teform.value.widgets ){//&& value
            const widgets = deepCopy(teform.value.widgets)
            widgets.forEach( (widget, index) => {
              if(value  ){
                if(id == widget['id'])
                  widget[field] = value
  
                if(id.includes(widget['id']+'_')){
                  if(widget['type']=="annotatedMap"){
                    if(widget['annotations']){
                      const annotations = widget['annotations']
                      annotations.forEach((annotation, index) => {
                        if(id == widget['id']+'_'+annotation.id)
                          annotation[field] = value
                      });
                    }
                  }
  
                  if(widget['type'] == 'buttons'){
                    const buttons = widget['buttons']
                    if(buttons){
                      buttons.forEach((btn, indexBTN) => {
                        if(id == widget['id']+'_'+btn.id){
                          // if(btn[field])
                            btn[field] = value
                        }
                      });
                    }
                  }
                }
              }else{

                let newvalue = value
                if(this.oldTranslations && this.oldTranslations[lang] && this.oldTranslations[lang][translationField] && translationField.includes(widget['id'])){

                  if( this.translationForm.value.widgetDefaultLanguage)
                    newvalue = this.translationForm.value.widgetDefaultLanguage[index][field]

                  if(widget.type=="buttons"){
                    const ids = id.split('_')
                    const buttonId = ids[1]
                    const buttonIndex = this.translationForm.value.widgetDefaultLanguage[index]['buttons'].findIndex( btn => btn.id == buttonId)
                    newvalue = this.translationForm.value.widgetDefaultLanguage[index]['buttons'][buttonIndex][field]
                  }

                  if(widget.type=="annotatedMap"){
                    const ids = id.split('_')
                    const pinId = ids[1]
                    const pinIndex = this.translationForm.value.widgetDefaultLanguage[index]['annotations'].findIndex( pin => pin.id == pinId)
                    newvalue = this.translationForm.value.widgetDefaultLanguage[index]['annotations'][pinIndex][field]
                  }

                  if(id == widget['id'])
                    widget[field] = newvalue
    
                  if(id.includes(widget['id']+'_')){
                    if(widget['type']=="annotatedMap"){
                      if(widget['annotations']){
                        const annotations = widget['annotations']
                        annotations.forEach((annotation, index) => {
                          if(id == widget['id']+'_'+annotation.id)
                            annotation[field] = newvalue
                        });
                      }
                    }
    
                    if(widget['type'] == 'buttons'){
                      const buttons = widget['buttons']
                      if(buttons){
                        buttons.forEach((btn, indexBTN) => {
                          if(id == widget['id']+'_'+btn.id){
                            if(btn[field])
                              btn[field] = newvalue
                          }
                        });
                      }
                    }
                  }
                }
              }
              
            })
            teform.patchValue({widgets: widgets})
            this.allWidgets = widgets
          }
        })
        
        if(!this.passTemplateLanguagesO[lang])
          this.passTemplateLanguages.push(teform.value)
        this.passTemplateLanguagesO[lang]=teform.value
      }else{

        if(teform.value.widgets){
          const widgets = deepCopy(teform.value.widgets)
          this.allWidgets = widgets
        }
        this.passTemplateLanguages.push(teform.value)
        this.passTemplateLanguagesO[lang]=teform.value
      }

    this.translationForm.patchValue({widgetsWithTranslations : this.allWidgets})
  }
  
}
