<h2 mat-dialog-title>{{'contentTypes.schema' | translate}}</h2>

<mat-dialog-content class="custom-width">
    <div class="pb-2">
        <section class="mb-3 rounded m-auto w-100" id="section">
            <div>
                <mat-hint style="font-size: 14px;">
                    {{'contentTypes.schemaHint' | translate}}
                </mat-hint>
            </div>
            <button mat-button class="secondary-gray-button my-3" id="btn-add" (click)="addProperty();">
                <mat-icon>add_circle</mat-icon> {{'add' | translate}}
            </button>
            <hr>
            <div id="tree-section">
                <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree w-100 m-auto shadow-sm ">
                    <mat-tree-node *matTreeNodeDef="let node;let index=index" matTreeNodeToggle class="border-bottom" (click)="onLeafNodeClick(node)">
                        <div class="d-flex flex-row flex-wrap justify-content-between align-items-center w-100 ps-2">
                            <div class="d-flex flex-row flex-wrap icon-section align-items-center">
                                <mat-icon class="material-symbols-outlined" *ngIf="node.type=='string'">font_download</mat-icon>
                                <mat-icon class="material-symbols-outlined" *ngIf="node.type=='number'">tag</mat-icon>
                                <mat-icon class="material-symbols-outlined" *ngIf="node.type=='array'">list</mat-icon>
                                <mat-icon class="material-symbols-outlined" *ngIf="node.type=='object'">calendar_view_month</mat-icon>
                                <mat-icon class="material-symbols-outlined" *ngIf="node.type=='boolean'">toggle_on</mat-icon>
                                <mat-icon class="material-symbols-outlined" *ngIf="node.type=='date'">calendar_month</mat-icon>
                                <span class="p-1" style="color:black;" >{{node.key}}</span>
                            </div>
                            <button class="delete-button" style="vertical-align: middle;" mat-icon-button (click)="deleteNode(node, index);">
                                <mat-icon class="material-symbols-outlined">delete</mat-icon>
                            </button>
                        </div>

                    </mat-tree-node>
                    <!-- nested object -->
                    <mat-nested-tree-node *matTreeNodeDef="let node;let index=index; when: hasChild" >
                        <div class="mat-tree-node border-bottom ">
                            <button mat-icon-button matTreeNodeToggle
                                    [attr.aria-label]="'Toggle ' + node.key">
                                <mat-icon class="mat-icon-rtl-mirror">
                                {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                                </mat-icon>
                            </button>
                            <div *ngIf="node.type!='object'" class="d-flex icon-section flex-row flex-wrap justify-content-start align-items-center ps-2">
                                <mat-icon class="material-symbols-outlined" *ngIf="node.type=='string'">font_download</mat-icon>
                                <mat-icon class="material-symbols-outlined" *ngIf="node.type=='number'">tag</mat-icon>
                                <mat-icon class="material-symbols-outlined" *ngIf="node.type=='array'">list</mat-icon>
                                <mat-icon class="material-symbols-outlined" *ngIf="node.type=='object'">calendar_view_month</mat-icon>
                                <mat-icon class="material-symbols-outlined" *ngIf="node.type=='boolean'">toggle_on</mat-icon>
                                <mat-icon class="material-symbols-outlined" *ngIf="node.type=='date'">calendar_month</mat-icon>
                                <span class="p-1" style="color:black;" >{{node.key}}</span>
                                <button class="delete-button" style="vertical-align: middle;" mat-icon-button (click)="deleteNode(node, index);">
                                    <mat-icon class="material-symbols-outlined">delete</mat-icon>
                                </button>
                            </div>
                            <div *ngIf="node.type=='object'" class="d-flex flex-row flex-wrap justify-content-between align-items-center ps-2 w-100">
                                <div class="d-flex flex-row justify-content-start icon-section align-items-center ps-2">
                                    <mat-icon class="material-symbols-outlined" *ngIf="node.type=='string'">font_download</mat-icon>
                                    <mat-icon class="material-symbols-outlined" *ngIf="node.type=='number'">tag</mat-icon>
                                    <mat-icon class="material-symbols-outlined" *ngIf="node.type=='array'">list</mat-icon>
                                    <mat-icon class="material-symbols-outlined" *ngIf="node.type=='object'">calendar_view_month</mat-icon>
                                    <mat-icon class="material-symbols-outlined" *ngIf="node.type=='boolean'">toggle_on</mat-icon>
                                    <mat-icon class="material-symbols-outlined" *ngIf="node.type=='date'">calendar_month</mat-icon>
                                    <span class="p-1" style="color:black;" >{{node.key}}</span>
                                </div>
                                <div class="d-flex flex-row align-items-center">
                                    <button mat-icon-button class="ms-5" style="color:var(--warmOrange);" (click)="addNewProperty(node,index);"> 
                                        <span class="material-symbols-outlined">
                                            add_circle
                                        </span>
                                    </button>
                                    <button class="delete-button" style="vertical-align: middle;" mat-icon-button (click)="deleteNode(node, index);">
                                        <mat-icon class="material-symbols-outlined">delete</mat-icon>
                                    </button>
                                </div>
                        
                            </div>
                        </div>
                        <div [class.example-tree-invisible]="!treeControl.isExpanded(node)"
                            role="group">
                            <ng-container matTreeNodeOutlet></ng-container>
                        </div>
                    </mat-nested-tree-node>
                    <!-- nested array -->
                    <mat-nested-tree-node *matTreeNodeDef="let node;let index=index; when: isArrayType" >
                        <div class="mat-tree-node border-bottom">
                            <button mat-icon-button matTreeNodeToggle
                                    [attr.aria-label]="'Toggle ' + node.key">
                                <mat-icon class="mat-icon-rtl-mirror">
                                {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                                </mat-icon>
                            </button>
                            <div class="d-flex flex-row justify-content-start icon-section flex-wrap align-items-center ps-2">
                                <mat-icon class="material-symbols-outlined" *ngIf="node.type=='string'">font_download</mat-icon>
                                <mat-icon class="material-symbols-outlined" *ngIf="node.type=='number'">tag</mat-icon>
                                <mat-icon class="material-symbols-outlined" *ngIf="node.type=='array'">list</mat-icon>
                                <mat-icon class="material-symbols-outlined" *ngIf="node.type=='object'">calendar_view_month</mat-icon>
                                <mat-icon class="material-symbols-outlined" *ngIf="node.type=='boolean'">toggle_on</mat-icon>
                                <mat-icon class="material-symbols-outlined" *ngIf="node.type=='date'">calendar_month</mat-icon>
                                <span class="p-1" style="color:black;" >{{node.key}}</span>
                                
                                <button class="delete-button" style="vertical-align: middle;" mat-icon-button (click)="deleteNode(node, index);">
                                    <mat-icon class="material-symbols-outlined">delete</mat-icon>
                                </button>
                            </div>
                        </div>
                        <div [class.example-tree-invisible]="!treeControl.isExpanded(node)"
                            role="group">
                            <ng-container matTreeNodeOutlet>
                                <div class="mat-tree-node border-bottom">
                                    <div class="d-flex flex-row flex-wrap icon-section justify-content-start align-items-center ps-2">
                                        <mat-icon class="material-symbols-outlined" *ngIf="!node.items">font_download</mat-icon>
                                        <mat-icon class="material-symbols-outlined" *ngIf="node.items && node.items['type']=='string'">font_download</mat-icon>
                                        <mat-icon class="material-symbols-outlined" *ngIf="node.items && node.items['type']=='number'">tag</mat-icon>
                                        <mat-icon class="material-symbols-outlined" *ngIf="node.items && node.items['type']=='array'">list</mat-icon>
                                        <mat-icon class="material-symbols-outlined" *ngIf="node.items && node.items['type']=='object'">calendar_view_month</mat-icon>
                                        <mat-icon class="material-symbols-outlined" *ngIf="node.items && node.items['type']=='boolean'">toggle_on</mat-icon>
                                        <mat-icon class="material-symbols-outlined" *ngIf="node.items && node.items['type']=='date'">calendar_month</mat-icon>

                                        <span class="p-1" style="color:black;" >List-Items</span>
                                        
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </mat-nested-tree-node>
                </mat-tree>   
            </div>
        </section>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button style="color:var(--warmOrange);" (click)="onCancel();" > {{'cancel' | translate}} </button>
    <button mat-button class="orange-button" (click)="onSave();"  >{{'apply' | translate}}</button>
</mat-dialog-actions>
