<h2 mat-dialog-title>{{'pass.widgets.video' | translate}}</h2>

<!-- <div *ngIf="defaultLanguage" style="position: absolute;top: 25px;right: 10px;">
    <span class="hint-text">{{'defaultLanguage' | translate}} : {{languagesList[defaultLanguage]}}</span>
</div> -->

<mat-dialog-content style="width: 600px;" >
    <div class="pt-2 pb-3">
        <section class="mt-1 mb-3 rounded m-auto w-100" id="section" >
            <form [formGroup]="videoEditForm">

                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'pass.name' | translate}}</mat-label>
                    <input formControlName="name" type="text" matInput placeholder="{{'pass.name' | translate}}">
                </mat-form-field>

                <div class="d-flex flex-row">
                    <div class="pe-2 flex-grow-1">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>{{'pass.title' | translate}}</mat-label>
                            <input formControlName="title" type="text" matInput placeholder="{{'pass.title' | translate}}">
                        </mat-form-field>
                    </div>
                    <div class="ps-2 flex-grow-1">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>{{'pass.subtitle' | translate}}</mat-label>
                            <input formControlName="subtitle" type="text" matInput placeholder="{{'pass.subtitle' | translate}}">
                        </mat-form-field>
                    </div>
                </div>
        
                <mat-card class="mb-4">
                    <mat-card-content>
                        <div class="p-2">
                            <div *ngIf="url || urlData" >
                                <div class="d-flex align-items-center mb-3">
                                    <mat-icon class="material-symbols-outlined">slideshow</mat-icon> 
                                    <span class="px-2">{{'pass.widgets.video' | translate}}</span>
                                </div>
                                <div class="d-flex justify-content-stretch flex-column">
                                    <video *ngIf="!url " [vgMedia]="$any(media)" #media id="singleVideo" preload="auto" controls width="100%"  class="me-2" style=" max-height:300px;" (loadedmetadata)="onVideoMetadataVideo($event)">
                                        <source [src]="urlData" type="video/mp4" >
                                    </video>
                                    <video *ngIf="url && urlData" [vgMedia]="$any(media)" #media id="singleVideo" preload="auto" controls width="100%"  class="me-2" style=" max-height:300px;" (loadedmetadata)="onVideoMetadataVideo($event)">
                                        <source [src]="urlData" type="video/mp4">
                                    </video>
    
                                    <video *ngIf="url && !urlData" [vgMedia]="$any(media)" #media id="singleVideo" preload="auto" controls width="100%" class="me-2" style=" max-height:300px;" (loadedmetadata)="onVideoMetadataVideo($event)">
                                        <source [src]="url" type="video/mp4">
                                    </video>
                
                                    <div class="form-fields mt-4">
                                        <div class="d-flex align-items-start">
                                            <mat-form-field appearance="outline" class="flex-grow-1">
                                                <mat-icon matIconPrefix class="material-symbols-outlined">link</mat-icon>
                                                <mat-label>{{ 'pass.url' | translate }}</mat-label>
                                                <input type="text" *ngIf ="url" formControlName="url" matInput readonly>
                                                <input type="text"*ngIf ="!url" formControlName="url" matInput (change)="checkNewUrl()">
                                            </mat-form-field>
                                            <button mat-icon-button class="delete-button" (click)="delete()" matTooltip="{{'pass.deleteVideo' | translate}}">
                                                <mat-icon class="material-symbols-outlined">delete</mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="!urlData" appDragAndDrop (files)="filesDropped($event)">
                                <mat-card class="upload-section text-center justify-content-center">
                                    <div style="margin:0 auto; height:100%;" class="d-flex justify-content-end flex-column">
                                        <div class="p-2 mb-4">
                                            <mat-icon class="material-symbols-outlined">library_add</mat-icon>
                                            <h2 class="px-3">{{'pass.fileUploadMessage' | translate}}
                                                <button type="button" class="labelImage" (click)="triggerFileInput()">
                                                    <strong style="font-family: 'MyFont-Bold', sans-serif;">{{'pass.browse' | translate}}</strong>
                                                </button>
                                                {{'pass.or' | translate}}
                                                <button type="button" class="labelImage" (click)="typeURL()">
                                                    <strong style="font-family: 'MyFont-Bold', sans-serif;">{{'enterURL' | translate}}</strong>
                                                </button>
                                                <input #fileInput type="file" id="myfile" name="myfile" style="display: none;" accept=" video/*" (change)="readURL($event);"> 
                                            </h2>    
                                        </div>
                                        <p class="py-2 mt-4">{{'pass.supportedFiles' | translate}}: video/*  </p>
                                    </div>
                                </mat-card>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>

                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'pass.copyright' | translate}}</mat-label>
                    <input formControlName="copyright" type="text" matInput placeholder="{{'pass.copyright' | translate}}">
                </mat-form-field>


                <div class="form-control p-3 mb-3 d-flex justify-content-between w-100">
                    <div class="d-flex align-items-center">
                        <span class="material-symbols-outlined me-2">autoplay</span>
                        <label>{{ 'pass.video.auto_play' | translate }}</label>
                    </div>
                    <mat-slide-toggle class="ms-5 example-margin" formControlName="autoPlay"></mat-slide-toggle>
                </div>

                <div class="form-control p-3 mb-3 d-flex justify-content-between w-100">
                    <div class="d-flex align-items-center">
                        <span class="material-symbols-outlined me-2">repeat</span>
                        <label>{{ 'pass.video.auto_replay' | translate }}</label>
                    </div>
                    <mat-slide-toggle class="ms-5 example-margin" formControlName="autoRePlay"></mat-slide-toggle>
                </div>

                <div class="form-control p-3 mb-3 d-flex justify-content-between w-100">
                    <div class="d-flex align-items-center">
                        <span class="material-symbols-outlined me-2">volume_off</span>
                        <label>{{ 'pass.video.muted' | translate }}</label>
                    </div>
                    <mat-slide-toggle class="ms-5 example-margin" formControlName="muted"></mat-slide-toggle>
                </div>

                <div class="form-control p-3 mb-3 d-flex justify-content-between w-100">
                    <div class="d-flex align-items-center">
                        <span class="material-symbols-outlined me-2">tune</span>
                        <label>{{ 'pass.video.controls' | translate }}</label>
                    </div>
                    <mat-slide-toggle class="ms-5 example-margin" formControlName="videoControls"></mat-slide-toggle>
                </div>
        
                <div *ngIf="userHasBetaWidgetRole" [app-widget-styling] = "videoEditForm.value" (returnChanges)="returnChangesStyle($event)"></div>

            </form>
        </section>
    </div>

  <div [app-widget-edit-filters] = "videoEditData" (returnChanges)="returnChangesFilters($event)"></div>

</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button style="color:var(--warmOrange);" (click)="cancelChanges();" >{{'cancel' | translate}}</button>
    <button mat-button class="orange-button" (click)="saveChanges();"  >{{'apply' | translate}}</button>
</mat-dialog-actions>
