
<h2 mat-dialog-title>{{'pass.widgets.countdown' | translate}}</h2>

<mat-dialog-content style="max-width: 600px;">
    <div class="pt-2 pb-3">
        <section class="mt-1 mb-3 rounded m-auto w-100" id="section" >
            <form [formGroup]="countdownEditForm">
        
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'pass.name' | translate}}</mat-label>
                    <input formControlName="name" type="text" matInput placeholder="{{'pass.name' | translate}}">
                </mat-form-field>

                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'pass.countdown.expired_message' | translate}}</mat-label>
                    <input formControlName="expiredMessage" type="text" matInput placeholder="{{'pass.countdown.expired_message' | translate}}">
                </mat-form-field>

                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'pass.countdown.alignment' | translate}}</mat-label>
                    <mat-select formControlName="alignment">
                      <mat-option *ngFor = "let align of alignmentTypes" value="{{align.value}}">{{align.translation | translate }}</mat-option>
                    </mat-select>
                </mat-form-field>

                <div class="list-group ">
                    <div class="list-group-item">
                        <div class="d-flex flex-row justify-content-between align-items-center flex-wrap">
                            <span class=" w-50 bg-white" >{{'pass.background' | translate}}</span>
                        
                            <label class=" d-flex flex-row-reverse justify-content-start align-items-center" style="border-left:none;" >
                                <ngx-colors ngx-colors-trigger [palette]="colorPalette" [hideTextInput]="false" [format]="'hex'" cancelLabel="{{'pass.colorPickerCancel' | translate}}" acceptLabel="{{'pass.colorPickerConfirm' | translate}}" formControlName="backgroundColor" style="margin-left:10px;"></ngx-colors>
                                <span *ngIf="invalid_background" id="invalid_background" style="color:red;">{{'pass.invalid_color' | translate}}</span> 
                            </label>

                        </div>
                    </div>

                    <div class="list-group-item">
                        <div class="d-flex flex-row justify-content-between align-items-center flex-wrap">
                            <span class="w-50 bg-white" >{{'pass.countdown.textColor' | translate}}</span>
                        
                            <label class=" d-flex flex-row-reverse justify-content-start align-items-center" style="border-left:none;" >
                                <ngx-colors ngx-colors-trigger [palette]="colorPalette" [hideTextInput]="false" [format]="'hex'" cancelLabel="{{'pass.colorPickerCancel' | translate}}" acceptLabel="{{'pass.colorPickerConfirm' | translate}}" formControlName="textColor" style="margin-left:10px;"></ngx-colors>
                                <span *ngIf="invalid_textColor" id="invalid_textColor" style="color:red;">{{'pass.invalid_color' | translate}}</span>
                            </label>
                        </div>
                    </div>
                </div>

                <mat-form-field appearance="outline" class="w-100 mt-3">
                    <mat-label>{{'pass.countdown.targetDate' | translate}}</mat-label>
                    <input formControlName="targetDate" type="datetime-local" matInput placeholder="{{'pass.countdown.targetDate' | translate}}" max="9999-12-31T23:59" min="1970-01-01T00:00" >
                    <!-- <mat-hint>Minimum Date is 01.01.1970, 00:00</mat-hint> -->
                    <mat-hint *ngIf="lessMinDate">Minimum Date is 01.01.1970, 00:00</mat-hint>
                </mat-form-field>

                <!-- <mat-tab-group>
                    <mat-tab label="{{'pass.countdown.setDate' | translate}}"> 
                        
                    </mat-tab>
                    <mat-tab label="{{'pass.countdown.setField' | translate}}"> 
                        <mat-form-field appearance="outline" class="w-100 mt-3">
                            <mat-label>{{'pass.countdown.targetDateKey' | translate}}</mat-label>
                            <input formControlName="targetDateKey" type="text" matInput placeholder="{{'pass.countdown.targetDateKey' | translate}}">
                        </mat-form-field>
                    </mat-tab>
                </mat-tab-group> -->


            </form>
        </section>
    </div>

    <div [app-widget-edit-filters] = "countdownData" (returnChanges)="returnChangesFilters($event)"></div>

</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button style="color:var(--warmOrange);" (click)="dialogCancel();" > {{'cancel' | translate}} </button>
    <button mat-button 
            class="orange-button"
            (click)="dialogSave();"
            [disabled]="invalid_background || invalid_textColor || !countdownEditForm.valid"
            >{{'apply' | translate}}</button>
</mat-dialog-actions>