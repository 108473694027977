import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { removeUndefined } from '../../../../../../backend/utils/object';

@Component({
  selector: '[app-widget-styling]',
  templateUrl: './widget-styling.component.html',
  styleUrls: ['./widget-styling.component.css']
})
export class WidgetStylingComponent {
  @Input('app-widget-styling') data:any;
  @Output() returnChanges = new EventEmitter<{newStyle: any, widget: any, widgetId:string}>()
  widgetStyleForm!:FormGroup
  widgetStyleData:any = {}
  colorPalette:any=[]
  invalid_lightforegroundColor = false
  invalid_darkforegroundColor = false
  invalid_lightbackgroundColor = false
  invalid_darkbackgroundColor = false

  lightbackgroundColor=false 
  darkbackgroundColor=false
  lightbackgroundImageURL=false
  darkbackgroundImageURL=false
  lightforegroundColor=false
  darkforegroundColor=false
  contentAlignment= false
  fontName=false
  fontUrl=false

  constructor(public dialogRef: MatDialogRef<WidgetStylingComponent>,
    private fb: FormBuilder){}

    ngOnInit(): void{

      this.initializePaletteColors()

      if(this.data.widgetStyle){
        this.widgetStyleData = this.data.widgetStyle

        switch(this.data.type){
          //set true to hide/disable
          case 'annotatedMap':{
            this.lightbackgroundColor=false 
            this.darkbackgroundColor=false
            this.lightbackgroundImageURL=true
            this.darkbackgroundImageURL=true
            this.lightforegroundColor=false
            this.darkforegroundColor=false
            this.contentAlignment= false
            this.fontName=true
            this.fontUrl=true
            break;
          }

          case 'composed':{
            this.lightbackgroundColor=false 
            this.darkbackgroundColor=false
            this.lightbackgroundImageURL=true
            this.darkbackgroundImageURL=true
            this.lightforegroundColor=false
            this.darkforegroundColor=false
            this.contentAlignment= true
            this.fontName=true
            this.fontUrl=true
            break;
          }

          case 'form':{
            this.lightbackgroundColor=false 
            this.darkbackgroundColor=false
            this.lightbackgroundImageURL=true
            this.darkbackgroundImageURL=true
            this.lightforegroundColor=false
            this.darkforegroundColor=false
            this.contentAlignment= false
            this.fontName=true
            this.fontUrl=true
            break;
          }

          case 'image':{
            this.lightbackgroundColor=false 
            this.darkbackgroundColor=false
            this.lightbackgroundImageURL=true
            this.darkbackgroundImageURL=true
            this.lightforegroundColor=false
            this.darkforegroundColor=false
            this.contentAlignment= false
            this.fontName=true
            this.fontUrl=true
            break;
          }

          case 'images':{
            this.lightbackgroundColor=false 
            this.darkbackgroundColor=false
            this.lightbackgroundImageURL=true
            this.darkbackgroundImageURL=true
            this.lightforegroundColor=false
            this.darkforegroundColor=false
            this.contentAlignment= false
            this.fontName=true
            this.fontUrl=true
            break;
          }

          case 'spacer':{
            this.lightbackgroundColor=false 
            this.darkbackgroundColor=false
            this.lightbackgroundImageURL=true
            this.darkbackgroundImageURL=true
            this.lightforegroundColor=true
            this.darkforegroundColor=true
            this.contentAlignment= true
            this.fontName=true
            this.fontUrl=true
            break;
          }

          case 'text':{
            this.lightbackgroundColor=false 
            this.darkbackgroundColor=false
            this.lightbackgroundImageURL=true
            this.darkbackgroundImageURL=true
            this.lightforegroundColor=false
            this.darkforegroundColor=false
            this.contentAlignment= true
            this.fontName=true
            this.fontUrl=true
            break;
          }

          case 'video':{
            this.lightbackgroundColor=false 
            this.darkbackgroundColor=false
            this.lightbackgroundImageURL=true
            this.darkbackgroundImageURL=true
            this.lightforegroundColor=false
            this.darkforegroundColor=false
            this.contentAlignment= false
            this.fontName=true
            this.fontUrl=true
            break;
          }

        }
      }

      const { light, dark , contentAlignment, font} = this.widgetStyleData

      this.widgetStyleForm = this.fb.group({
        lightbackgroundColor: {value:light?.backgroundColor ? light?.backgroundColor : undefined, disabled: this.lightbackgroundColor}, 
        darkbackgroundColor:{value:dark?.backgroundColor ? dark?.backgroundColor : undefined, disabled: this.darkbackgroundColor}, 
        lightbackgroundImageURL: {value:light?.backgroundImageURL ? light?.backgroundImageURL : undefined, disabled: this.lightbackgroundImageURL},
        darkbackgroundImageURL: {value:dark?.backgroundImageURL ? dark?.backgroundImageURL : undefined, disabled: this.darkbackgroundImageURL},
        lightforegroundColor: {value:light?.foregroundColor ? light?.foregroundColor : undefined, disabled:this.lightforegroundColor}, 
        darkforegroundColor: {value:dark?.foregroundColor ? dark?.foregroundColor : undefined, disabled: this.darkforegroundColor},
        contentAlignment: {value:contentAlignment ? contentAlignment : undefined, disabled:this.contentAlignment}, 
        fontName:{value:font?.name ? font?.name : undefined, disabled: this.fontName}, 
        fontUrl:{value:font?.url ? font?.url : undefined, disabled: this.fontUrl}
      })

      this.widgetStyleForm.valueChanges.subscribe( value => {
        const newStyle = this.getfomatedDataStyling(value)
        this.returnChanges.emit({newStyle: newStyle, widget: this.data, widgetId: this.data.id})
      })

    }


    getfomatedDataStyling(data){

      const { lightbackgroundColor, darkbackgroundColor, lightbackgroundImageURL, darkbackgroundImageURL,
        lightforegroundColor, darkforegroundColor,contentAlignment, fontName, fontUrl} = data

      let style = {
        light:{
          backgroundColor: lightbackgroundColor ? lightbackgroundColor :undefined,
          // backgroundImageURL: lightbackgroundImageURL ? lightbackgroundImageURL : undefined,
          foregroundColor: lightforegroundColor ? lightforegroundColor : undefined
        },
        dark: {
          backgroundColor: darkbackgroundColor ? darkbackgroundColor : undefined,
          // backgroundImageURL: darkbackgroundImageURL ? darkbackgroundImageURL : undefined,
          foregroundColor: darkforegroundColor ? darkforegroundColor : undefined
        },
        contentAlignment: contentAlignment ? contentAlignment :undefined,
        // font: {
        //   name: fontName ? fontName : undefined,
        //   url: fontUrl ? fontUrl : undefined
        // }
      }

      if(!lightbackgroundColor && !lightforegroundColor) //&& !lightbackgroundImageURL
        delete style.light
      if(!darkbackgroundColor && !darkforegroundColor)//&& !darkbackgroundImageURL
        delete style.dark

      // if(!style.font?.name || !style.font?.url)
      //   delete style.font

      return removeUndefined(style)
    }


    initializePaletteColors(){
      var colors=[]
      const warmOrange= getComputedStyle(document.documentElement).getPropertyValue('--warmOrange')
      const darkblue= getComputedStyle(document.documentElement).getPropertyValue('--darkBlue')
      const pureWhite= getComputedStyle(document.documentElement).getPropertyValue('--pureWhite')
      const salbei= getComputedStyle(document.documentElement).getPropertyValue('--salbei')
      colors.push(warmOrange)
      colors.push(darkblue)
      colors.push(pureWhite)
      colors.push(salbei)
      this.colorPalette=colors
    }

 

    checkValidColors(){
      //-------foregroundColor
      if(this.widgetStyleForm.controls['lightforegroundColor'].hasError('invalidColor')){
        this.invalid_lightforegroundColor=true
      }else{
        this.invalid_lightforegroundColor = false
      }
  
      if(this.widgetStyleForm.controls['darkforegroundColor'].hasError('invalidColor')){
        this.invalid_darkforegroundColor=true
      }else{
        this.invalid_darkforegroundColor=false
      }
  
      //-----backgroundColor
      if(this.widgetStyleForm.controls['lightbackgroundColor'].hasError('invalidColor')){
        this.invalid_lightbackgroundColor=true
      }else{
        this.invalid_lightbackgroundColor=false
      }
  
      if(this.widgetStyleForm.controls['darkbackgroundColor'].hasError('invalidColor')){
        this.invalid_darkbackgroundColor=true
      }else{
        this.invalid_darkbackgroundColor=false
      }
  

  
  
    }

    
}
