<div class="p-1 d-flex align-items-center justify-content-end">
    <button mat-icon-button (click)="closeQRDialog();">
        <span class="material-symbols-outlined">
            close
        </span>
    </button>
</div>

<mat-dialog-content class="custom-width">
    <div class="pb-3">
        <section class="mb-2 rounded m-auto" id="section" >
            <div class="d-flex flex-column justify-content-center" style="text-align: center;">
                <qrcode [qrdata]="QRdata" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
                <div class="mt-2 button-group">
                    <button mat-button class="secondary-gray-button w-50" (click)="downloadQR()" style="margin-right:5px;" >
                        <mat-icon>download</mat-icon>
                        {{'download' | translate}}
                    </button>
                    <!-- <a href="{{QRdata}}" target="_blank" style="flex-grow: 1;padding-left: 10px;"> -->
                        <button mat-button class="secondary-gray-button w-50" (click)="openQRurl()" style="margin-left: 5px;">
                            <mat-icon>open_in_new</mat-icon>
                            {{'open' | translate}}
                        </button>
                    <!-- </a> -->

                </div>
                <div class="mt-2 button-group justify-content-center">
                    <button mat-button class="secondary-gray-button w-100" [cdkCopyToClipboard]="QRdata">
                        <mat-icon>content_copy</mat-icon>
                        {{"copyURL" | translate}}
                    </button>
                </div>
            </div>
        </section>
    </div>
</mat-dialog-content>