import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CommentsService } from 'src/app/service/comments.service';
import { UploadService } from 'src/app/service/upload.service';
import { ImageCompressionService } from 'src/app/service/image-compression.service';
import { FileHandle } from 'src/app/directive/drag-and-drop.directive';
import { LanguageEditorDialogComponent } from 'src/app/language-editor-dialog/language-editor-dialog.component';
import { SettingsService } from 'src/app/service/settings.service';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { Template } from '../../../../../backend/src/template.dto';
import { deepCopy, removeUndefined, supportUrlStructure } from '../../../../../backend/utils/object';
import { MediaDetailsDialogComponent } from 'src/app/widgets/editWidgets/media-details-dialog/media-details-dialog.component';
import { QuillEditorComponent } from 'ngx-quill';
import { MatMenuTrigger } from '@angular/material/menu';
import MarkdownIt from 'markdown-it';
import TurndownService from 'turndown';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-text-translation-edit',
  templateUrl: './text-translation-edit.component.html',
  styleUrls: ['./text-translation-edit.component.css']
})
export class TextTranslationEditComponent {

  @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;
  @ViewChild(QuillEditorComponent, { static: true }) editor!: QuillEditorComponent;

  textDataEdit:any
  oldData:Template
  textTranslationsEditForm!: FormGroup
  url:any
  changes=false
  newImage:any=[]
  widgetNumber:any
  passId:any
  files: FileHandle[] = []
  drop=false
  passTranslations:any
  translationForm!:FormGroup
  payloadKeys: string[] = [];
  workTaskTemplatesList = []
  languageClass: string = '';

  firstLanguageContexts:any
  urlData:any
  passLanguagesList:any
  oldTranslations:any
  addUrl = false
  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;
  urlRegex = /^(http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
  urlRegexDoc = /^pass?:\/\/?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
  linkUrlTransform:any
  selectedLanguage:string
  widgetId:string
  oldTemplateData:any

  quillModules = {
    toolbar: {
      container: '#toolbar',
    },
  };

  private md: MarkdownIt;
  private turndownService: TurndownService;
  private langChangeSub: Subscription;

  constructor(public dialogRef: MatDialogRef<TextTranslationEditComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dialog: MatDialog,
              private uploadService: UploadService,
              private fb: FormBuilder,
              private imageCompressionService: ImageCompressionService,
              private commentService: CommentsService,
              private settingsService: SettingsService,
              private translate:TranslateService){
                dialogRef.disableClose = true;
                this.md = new MarkdownIt();
                this.turndownService = new TurndownService();
  }
    
  ngOnInit(): void {
    //console.log(this.data)
    this.selectedLanguage = this.data.selectedLanguage
    this.widgetId = this.data.values.id
    this.textDataEdit = this.data.values
    this.oldData= new Template
    this.oldData.setFromAny(this.data)
    this.oldTemplateData = deepCopy(this.data.oldTemplateData)
    this.passId = this.data.passId
    this.firstLanguageContexts= this.settingsService.firstLanguageContexts$
    if(this.data.url){
      this.urlData = deepCopy(this.data.url)
    }else{
      if(this.textDataEdit.image && this.textDataEdit.image.url)
        this.supportUrlStructure(this.textDataEdit.image.url)

    }

    if(this.data.valuesTranslated){
      this.passTranslations = this.data.valuesTranslated
      this.oldTranslations = deepCopy(this.data.valuesTranslated)
    }else{
      this.passTranslations = {}
      this.oldTranslations = {}
    }
    
    if(this.textDataEdit.image)
      this.url = this.textDataEdit.image.url
    
    this.widgetNumber= this.data.widget
    this.passLanguagesList = this.data.languages
    this.textTranslationsEditForm = this.fb.group({
      id:[{value:undefined, disabled:true}],
      type:[{value:undefined, disabled:true}],
      title:[],
      subtitle:[],
      text:[],
      image:[{value:undefined, disabled:true}],
      imageUrl:[{value:undefined, disabled:true}],
      imageLinkURL:[{value:undefined, disabled:true} ,Validators.pattern(this.urlRegex)],
      imagePosition:[{value:undefined, disabled:true}],
      name:[{value:undefined, disabled:true}],
      textAlignment:[{value:undefined, disabled:true}]
    })
    const {id, type, image, imagePosition, name, textAlignment} = this.data.values
    
    const title = this.data.valuesTranslated[this.selectedLanguage +'.'+id+'.title']
    const subtitle = this.data.valuesTranslated[this.selectedLanguage +'.'+id+'.subtitle']
    const text = this.data.valuesTranslated[this.selectedLanguage +'.'+id+'.text']
    

    this.textTranslationsEditForm.patchValue({
      id: id? id : undefined,
      type: type ? type :undefined,
      title: title ? title : undefined,
      subtitle: subtitle ? subtitle : undefined,
      text: text ? text : undefined,
      image: image ? image : undefined,
      imageUrl: image?.url ? image?.url : undefined,
      imageLinkURL: image?.linkURL ? image?.linkURL : undefined,
      // imageDescription: image.description ? image.description : undefined,
      imagePosition: imagePosition ? imagePosition : undefined,
      name: name ? name : undefined,
      textAlignment: textAlignment ? textAlignment : undefined
    })

    this.translationForm = this.fb.group({translations:[]})
    this.translationForm.patchValue({
      translations: this.passTranslations ? this.passTranslations : undefined
    })
    this.translationForm.valueChanges.subscribe(value => {
      //console.log(value)
    })
    
    // check for payload keys and add them
    this.fetchAndInspect()

    // Load existing markdown
    if (this.data && this.data.text) {
      this.textTranslationsEditForm.patchValue({ text: this.data.text });
      this.loadContent(this.data.text); // Load markdown into editor
    }

    // Set initial class based on current language
    this.languageClass = this.getLanguageClass();
    // Subscribe to language change events
    this.langChangeSub = this.translate.onLangChange.subscribe(() => {
      this.languageClass = this.getLanguageClass();
    });
  }


  ngOnDestroy() {
    if (this.langChangeSub) {
      this.langChangeSub.unsubscribe();
    }
  }

  getLanguageClass(): string {
    const currentLang = this.translate.currentLang || this.translate.defaultLang;
    return `lang-${currentLang}`;
  }

  // START WYSWIG EDITOR

  private quillEditor: any;
  onEditorCreated(quill: any) {
    this.quillEditor = quill;
    
    if (this.textTranslationsEditForm.value.text) {
      this.markdownToQuill(this.textTranslationsEditForm.value.text);
    }

    // remove unwanted pasted formatting

    quill.clipboard.addMatcher(Node.ELEMENT_NODE, (node: any, delta: any) => {
      // define supported styles
      const retainStyles = ['bold', 'italic', 'link', 'list'];

      delta.ops.forEach((op: any) => {
        if (op.attributes) {
          // delete unsupported styles
          Object.keys(op.attributes).forEach((attr) => {
            if (!retainStyles.includes(attr)) {
              delete op.attributes[attr];
            }
          });
        }
      });

      return delta; // cleaned delta
    });
  }
  private isInsertingContent: boolean = false;
  //private isProcessingMarkdown: boolean = false;
  
  markdownToQuill(markdown: string) {
    if (this.editor && this.editor.quillEditor) {
      if (this.isInsertingContent) {
        return; // Prevent re-entrance
      }
      this.isInsertingContent = true;
      // Convert markdown to HTML
      const html = this.md.render(markdown);
      // handles the HTML without breaking
      this.editor.quillEditor.clipboard.dangerouslyPasteHTML(html);
  
      this.isInsertingContent = false;
    }
  }
  
  saveMarkdown() {
    if (this.editor && this.editor.quillEditor) {
        const html = this.editor.quillEditor.root.innerHTML; // Get HTML
        const markdownContent = this.turndownService.turndown(html); // HTML to Markdown
        //console.log('Markdown content:', markdownContent);
        return markdownContent;
    }
    return '';
  }

  loadContent(markdown: string) {
    if (this.editor && this.editor.quillEditor) {
      const html = this.md.render(markdown); // markdown to HTML
      this.editor.quillEditor.clipboard.dangerouslyPasteHTML(html); // Insert HTML
    }
  }

  addPayloadKeyAtCursor(payloadKey: string) {
    const quillEditor = this.editor.quillEditor;
    const range = quillEditor.getSelection(true);
    if (range) {
      const formattedPayloadKey = `\${${payloadKey}}`;
      setTimeout(() => {
        quillEditor.deleteText(range.index, range.length); 
        quillEditor.insertText(range.index, formattedPayloadKey);
      }, 0);
    }
  }

  fetchAndInspect() {
    this.getWorkTasksList();
    setTimeout(() => {
      this.inspectWorkTaskTemplatesList();
    }, 500);
  }

  inspectWorkTaskTemplatesList() {
    if (Array.isArray(this.workTaskTemplatesList)) {
      this.payloadKeys = [];  // Clear previous keys

      this.workTaskTemplatesList.forEach((workTask) => {
        workTask.formSections?.forEach((section) => {
          section.fields?.forEach((field) => {
            if (field.payloadKey) {
              this.payloadKeys.push(field.payloadKey);
            }
          });
        });
      });
    } else {
      //console.log('Work Task Templates List is empty');
    }
  }
  
   getWorkTasksList() {
    const contentTypeId = this.data['contentTypeId']
    if(this.settingsService.settingsWorkTaskTemplates){
      this.workTaskTemplatesList = this.settingsService.settingsWorkTaskTemplates
    }
    try{
      this.commentService.progressSpin.emit(true)
      this.workTaskTemplatesList = this.settingsService.observeContextsWorkTasksTemplatesFirestoreWithContentTypeId(contentTypeId)
      this.commentService.progressSpin.emit(false)
    }catch(error){
      this.commentService.progressSpin.emit(false)
      const message = this.translate.instant("SnackBarConstants.LOAD_FAILED")
      this.commentService.addSnackBar.emit(message)
    }
  }
  
  // END WYSWIG EDITOR


  async readURL(event: any): Promise<void> {
    // if (event.target.files && event.target.files[0]) {
    //   const file = event.target.files[0];
    //   const maxSizeInBytes = 2 * 1024 * 1024; // 2 MB size limit for compression
    //   const maxAllowedSizeInBytes = 20 * 1024 * 1024; // 20 MB size limit for direct upload

    //   // Check for supported file types
    //   const supportedTypes = ['image/jpeg', 'image/png', 'image/webp'];
    //   if (!supportedTypes.includes(file.type)) {
    //     // Unsupported file type
    //     const message = this.translate.instant("SnackBarConstants.UNSUPPORTED_IMG_TYPE");
    //     this.commentService.addSnackBar.emit(message);
    //     return;
    //   }
  
    //   if (file.size > maxAllowedSizeInBytes) {
    //     // File is too large for both upload and compression
    //     const message = this.translate.instant("SnackBarConstants.IMAGE_FILE_TOO_LARGE");
    //     this.commentService.addSnackBar.emit(message);
    //     return;
    //   }

    //   let imageToUpload = file;
  
    //   if (file.size > maxSizeInBytes) {
    //     try {
    //       const compressedImage = await this.imageCompressionService.compressImage(file, maxSizeInBytes);
    //       if (!compressedImage) {
    //         const message = this.translate.instant("SnackBarConstants.IMAGE_TOO_LARGE");
    //         this.commentService.addSnackBar.emit(message);
    //         return;
    //       }
    //       imageToUpload = compressedImage;
    //     } catch (error) {
    //       console.log(error);
    //       const message = this.translate.instant("SnackBarConstants.IMAGE_COMPRESSION_FAILED");
    //       this.commentService.addSnackBar.emit(message);
    //       return;
    //     }
    //   }
  
    //   const reader = new FileReader();
    //   this.newImage = imageToUpload;
    //   reader.readAsDataURL(imageToUpload);
  
    //   reader.onload = async (event) => {
    //     if (event.target) {
    //       const urlNew = event.target.result as string;
    //       if (urlNew) {
    //         this.changes = true;
  
    //         try {
    //           const uploadURL = await this.uploadService.uploadNewTextImage(this.passId, this.newImage, this.widgetNumber);
  
    //           if (uploadURL) {
    //             this.url = uploadURL;
    //             this.urlData = urlNew;
    //             this.textTranslationsEditForm.patchValue( {imageUrl : this.url })
    //             if (this.textTranslationsEditForm.value.textAlignment && this.textTranslationsEditForm.value.textAlignment == 'center')
    //               this.textTranslationsEditForm.patchValue({ textAlignment: 'left' });
    //           }
    //         } catch (error) {
    //           console.error(error);
    //           const message = this.translate.instant("SnackBarConstants.UPLOAD_IMG_FAILED");
    //           this.commentService.addSnackBar.emit(message);
    //         }
    //       }
    //     }
    //   };
    // }
  }

  async filesDropped(files: FileHandle[]): Promise<void> {

    // this.files = files;
    // const fileToUpload = files[0].file;
    // const maxSizeInBytes = 2 * 1024 * 1024; // 2 MB size limit for compression
    // const maxAllowedSizeInBytes = 20 * 1024 * 1024; // 20 MB size limit for direct upload
  
    // if (fileToUpload.size > maxAllowedSizeInBytes) {
    //   // File is too large for both upload and compression
    //   const message = this.translate.instant("SnackBarConstants.IMAGE_FILE_TOO_LARGE");
    //   this.commentService.addSnackBar.emit(message);
    //   return;
    // }
  
    // if (fileToUpload.size > maxSizeInBytes) {
    //   // File is between 2 MB and 20 MB, attempt compression
    //   try {
    //     const compressedImage = await this.imageCompressionService.compressImage(fileToUpload, maxSizeInBytes);
    //     if (!compressedImage) {
    //       const message = this.translate.instant("SnackBarConstants.IMAGE_TOO_LARGE");
    //       this.commentService.addSnackBar.emit(message);
    //       return;
    //     }
    //     this.newImage = compressedImage;
    //   } catch (error) {
    //     console.error("Compression error:", error);
    //     const message = this.translate.instant("SnackBarConstants.IMAGE_COMPRESSION_FAILED");
    //     this.commentService.addSnackBar.emit(message);
    //     return;
    //   }
    // } else {
    //   // File is less than or equal to 2 MB, no compression needed
    //   this.newImage = fileToUpload;
    // }
    // const newUrl = files[0].url;
  
    // if (fileToUpload.type == 'image/jpeg' || fileToUpload.type == 'image/png' || fileToUpload.type == 'image/webp') {
    //   if (newUrl) {
    //     this.drop = true;
    //     this.changes = true;
  
    //     try {
    //       const uploadURL = await this.uploadService.uploadNewTextImage(this.passId, this.newImage, this.widgetNumber);
  
    //       if (uploadURL) {
    //         this.url = uploadURL;
    //         this.urlData = newUrl;
    //         this.textTranslationsEditForm.patchValue( {imageUrl : this.url })
  
    //         if (this.textTranslationsEditForm.value.textAlignment && this.textTranslationsEditForm.value.textAlignment == 'center')
    //           this.textTranslationsEditForm.patchValue({ textAlignment: 'left' });
    //       }
    //     } catch (error) {
    //       console.error(error);
    //       const message = this.translate.instant("SnackBarConstants.UPLOAD_IMG_FAILED");
    //       this.commentService.addSnackBar.emit(message);
    //     }
    //   }
    // } else {
    //   const message = this.translate.instant('SnackBarConstants.UNSUPPORTED_IMG_TYPE');
    //   this.commentService.addSnackBar.emit(message);
    // }
  }

  checkNewUrl(){
    this.url = this.textTranslationsEditForm.value.imageUrl
    this.supportUrlStructure(this.textTranslationsEditForm.value.imageUrl)
  }
  
  typeURL(){
    this.addUrl = true
    this.urlData = './../../assets/img/default.jpg';
  }

  triggerFileInput(): void {
    this.fileInput.nativeElement.click();
  }

  deleteImg(x:any){
    this.url=undefined
    this.textTranslationsEditForm.patchValue({ image:undefined, imageUrl: undefined, imageLinkURL: undefined })
    this.urlData=""
  }


  onCancelEdit(){
    this.passTranslations = this.oldData.translations
    this.dialogRef.close([this.oldData, false])
  }

  async onSaveEdit(){
    this.commentService.progressSpin.emit(true)

    this.textTranslationsEditForm.get('text')?.setValue(this.editor.quillEditor.root.innerHTML);
    const markdownContent = this.saveMarkdown();
    this.textTranslationsEditForm.get('text')?.setValue(markdownContent);

    const data = this.getWidgetFormatedData(this.textTranslationsEditForm.value)
    this.commentService.progressSpin.emit(false)
    this.dialogRef.close([data, true]);
  }

  readDocument(event: any): void {
    // if (event.target.files && event.target.files[0]) {
    //   const file = event.target.files[0];
    //   const maxSizeInBytes = 10 * 1024 * 1024; // 10 MB size limit
      
    //   // Define allowed file types
    //   const allowedTypes = [
    //     'application/pdf',
    //     'image/jpeg',
    //     'image/png',
    //     'image/webp',
    //     'application/msword',
    //     'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    //     'application/vnd.ms-excel',
    //     'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    //     'text/plain',
    //     'text/csv',
    //     'text/vcard',
    //     'application/rtf'
    //   ];
  
    //   // Validate file type
    //   if (!allowedTypes.includes(file.type)) {
    //     const message = this.translate.instant("SnackBarConstants.INVALID_FILE_FORMAT");
    //     this.commentService.addSnackBar.emit(message);
    //     return;
    //   }
      
    //   // Validate file size
    //   if (file.size > maxSizeInBytes) {
    //     const message = this.translate.instant("SnackBarConstants.FILE_TOO_LARGE");
    //     this.commentService.addSnackBar.emit(message);
    //     return;
    //   }
      
    //   // Proceed with reading and uploading the file
    //   const reader = new FileReader();
    //   this.newImage = file;
    //   reader.readAsDataURL(file);
    //   reader.onload = async (e) => {
    //     if (e.target) {
    //       const urlNew = e.target.result as string;
  
    //       try {
    //         const uploadURL = await this.uploadService.uploadDocument(this.passId, this.newImage, this.widgetNumber);
    //         if (uploadURL) {
    //           this.textTranslationsEditForm.get("imageLinkURL")?.setValidators([Validators.pattern(this.urlRegexDoc)]);
    //           this.linkUrlTransform = uploadURL;
    //           this.textTranslationsEditForm.patchValue({ imageLinkURL: this.linkUrlTransform });
    //         }
    //       } catch (error) {
    //         console.error(error);
    //         const message = this.translate.instant("SnackBarConstants.UPLOAD_IMG_FAILED");
    //         this.commentService.addSnackBar.emit(message);
    //       }
    //     }
    //   };
    // }
  }  

  supportUrlStructure(url){
    this.urlData = url
    if(url){
      this.urlData = supportUrlStructure(url, this.passId,this.settingsService.contextId$)
    }
  }

  getWidgetFormatedData(data){
    let {title, subtitle, text} = data
    return {
      [this.selectedLanguage+'.'+this.widgetId+'.title']: title ? title : undefined,
      [this.selectedLanguage+'.'+this.widgetId+'.subtitle']: subtitle ? subtitle : undefined,
      [this.selectedLanguage+'.'+this.widgetId+'.text']: text ? text : undefined,
    }

    // let dataToReturn = {}
    // if(this.oldTemplateData && this.oldTemplateData.translations){
    //   if(this.oldTemplateData.translations[this.selectedLanguage] ){
    //     if(this.oldTemplateData.translations[this.selectedLanguage][this.widgetId+'.title'])
    //         dataToReturn[this.selectedLanguage+'.'+this.widgetId+'.title'] = title ? title : deleteField
    //     if(this.oldTemplateData.translations[this.selectedLanguage][this.widgetId+'.subtitle'])
    //       dataToReturn[this.selectedLanguage+'.'+this.widgetId+'.subtitle'] = subtitle ? subtitle : deleteField
    //     if(this.oldTemplateData.translations[this.selectedLanguage][this.widgetId+'.text'])
    //       dataToReturn[this.selectedLanguage+'.'+this.widgetId+'.text'] = text ? text : deleteField
    //   }
    // }
    // return dataToReturn

  }

  checkLinkUrlPattern(){
    const linkUrl = this.textTranslationsEditForm.value.imageLinkURL
    if(linkUrl){
      try{
        const imgUrl = new URL(linkUrl)
  
        if(imgUrl.protocol=="pass:" )
          this.textTranslationsEditForm.get("imageLinkURL").setValidators([Validators.pattern(this.urlRegexDoc)]);

        if(imgUrl.protocol=="https:" )
          this.textTranslationsEditForm.get("imageLinkURL").setValidators([Validators.pattern(this.urlRegex)]);

      }catch(e){}
    }

  }
}
