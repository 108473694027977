import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CommentsService } from '../service/comments.service';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ContentType } from '../../../../backend/src/contentType.dto';
import { ContentTypeService } from '../service/content-type.service';
import { SettingsService } from '../service/settings.service';
import { ActivatedRoute } from '@angular/router';
import { ContentTypesSchemaViewDialogComponent } from '../content-types-schema-view-dialog/content-types-schema-view-dialog.component';
import { deepCopy } from '../../../../backend/utils/object';
import { ContentTypeDisplayTemplateDialogComponent } from '../content-type-display-template-dialog/content-type-display-template-dialog.component';

@Component({
  selector: 'app-content-types-view-dialog',
  templateUrl: './content-types-view-dialog.component.html',
  styleUrls: ['./content-types-view-dialog.component.css']
})
export class ContentTypesViewDialogComponent {
  oldContentTypeData:ContentType
  routerUrl:any
  idToShow:any
  currentRoute:any
  allContents:any
  contentData:ContentType
  editMode:any
  contentForm!:FormGroup
  id:any
  subtitleKeys:string[]=[]
  idUndefined=false
  oldData:any
  deleteMode=false
  contenttype:any
  displayName:any
  titleKey:any
  contentTypeUserRights_update:any
  contentTypeUserRights_delete:any
  nfcURLPrefix:any
  coverImagesAll:any
  routerExtras:any
  routerParams=false
  schemaDefinition:any

  constructor(public dialogRef: MatDialogRef<ContentTypesViewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any[],
    public dialog: MatDialog,
    private router: ActivatedRoute,
    private commentsService: CommentsService,
    private fb: FormBuilder,
    private translate:TranslateService,
    private contentTypeService: ContentTypeService,
    private commentService: CommentsService,
    private settingsService: SettingsService
    ){
      dialogRef.disableClose = true;
  }

  ngOnInit(){
    this.idToShow = this.data['contentTypeId']
    this.contentTypeUserRights_update = this.settingsService.userRolesRights$['contentTypes']['U']
    this.contentTypeUserRights_delete = this.settingsService.userRolesRights$['contentTypes']['D']
    if(!this.contentTypeUserRights_update)
    document.getElementById("btn-edit").style.backgroundColor='#e0e0e0'

    this.contentForm = this.fb.group({
      contextId:[],
      id:[],
      passTemplateId:[],
      passId:[],
      displayName:[],
      displaySections:[],
      schema:[],
      titleKey: [],
      subtitleKeys:[],
      nfcURLPrefix: [],
      advancedExperienceId:[]
    })

    this.getContentTypeId()
  }

  async getContentTypeId(){
    try{
      this.commentService.progressSpin.emit(true)
      if(!this.data['contentType'])
        this.contenttype = await this.settingsService.observeContextsContentTypesIdFirestore(this.idToShow)
      else{
        this.contenttype = deepCopy(this.data['contentType'])
        if(this.contenttype.creationDate)
          this.contenttype.creationDate = new Date(this.contenttype.creationDate)
          
        if(this.contenttype.timeStamp)
          this.contenttype.timeStamp = new Date(this.contenttype.timeStamp)
      }
      this.contentData= new ContentType 
      this.contentData.setFromAny(this.contenttype)
        if(this.contenttype.displayName)
        this.displayName = this.contenttype.displayName

        if(this.contenttype.titleKey)
        this.titleKey = this.contenttype.titleKey
        
        this.oldData = deepCopy(this.contentData)

      if(!this.contentData['id']){
        this.contentData['id']= this.idToShow
      }
  
      this.oldContentTypeData = new ContentType()
      this.oldContentTypeData.setFromAny(this.contentData)

      this.initializeFormWithdata()
      this.commentService.progressSpin.emit(false)

    }catch(error){
      console.log(error)
      this.commentService.progressSpin.emit(false)
      const message = this.translate.instant("SnackBarConstants.LOAD_FAILED")
      this.commentService.addSnackBar.emit(message)
    }
  }

  initializeFormWithdata() {
    const { contextId, id, passTemplateId, displayName,  titleKey, subtitleKeys, nfcURLPrefix,schema, advancedExperienceId, displaySections} = this.contenttype
    this.contentForm.patchValue({
      contextId: contextId ? contextId : undefined,
      id: id ? id : this.idToShow,
      passTemplateId:  passTemplateId ? passTemplateId : undefined,
      displayName : displayName ? displayName : undefined,
      titleKey : titleKey ? titleKey : undefined,
      nfcURLPrefix: nfcURLPrefix ? nfcURLPrefix : undefined,
      schema: schema ? schema : undefined,
      advancedExperienceId : advancedExperienceId ? advancedExperienceId : undefined,
      displaySections: displaySections ? displaySections : undefined
    })
    
    // Init  subtitleKeys
    if (subtitleKeys) {
      this.subtitleKeys = subtitleKeys;
    } else {
      this.subtitleKeys = [];
    }

    // Dynamically add controls
    this.subtitleKeys.forEach((key, index) => {
      this.contentForm.addControl('subtitleKey' + index, this.fb.control(key));
    });

    if(this.schemaDefinition){
      this.editMode=true
      this.contentForm.patchValue({schema: this.schemaDefinition})
    }
    
  }

  openDisplayTemplateView(){
    let dialogRef= this.dialog.open(ContentTypeDisplayTemplateDialogComponent, {
      data: {
        contentType:this.contentForm.value,
        id: this.idToShow
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result[0]){
        this.contentForm.patchValue({displaySections: result[1]})
      }
    });
  }

  openSchemaView(){
    let dialogRef= this.dialog.open(ContentTypesSchemaViewDialogComponent, {
      data: {
        contentType:this.contentForm.value,
        id: this.idToShow
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result[0]){
        if(result[1].properties && result[1].properties.length!=0 )
          this.contentForm.patchValue({schema: result[1]})
        else
          this.contentForm.patchValue({schema: {}})
      }
    });
  }

  addSubtitleKey(){
  
    this.subtitleKeys.push(this.translate.instant("pass.subtitle"));
    const newIndex = this.subtitleKeys.length - 1;
    this.contentForm.addControl('subtitleKey' + newIndex, this.fb.control(this.translate.instant("pass.subtitle")));
  
    this.contentForm.patchValue({
      subtitleKeys: this.subtitleKeys
    });
 
  }

  getSubtitleControl(index: number): FormControl {
    return this.contentForm.get('subtitleKey' + index) as FormControl;
  }
    deleteSubtitleKey(index: number) {
      this.subtitleKeys.splice(index, 1);
      this.contentForm.removeControl('subtitleKey' + index);
    
      // Update the remaining controls
      this.subtitleKeys.forEach((_, i) => {
        if (i >= index) {
          const oldName = 'subtitleKey' + (i + 1);
          const newName = 'subtitleKey' + i;
    
          // Rename
          const control = this.contentForm.get(oldName);
          if (control) {
            this.contentForm.removeControl(oldName);
            this.contentForm.addControl(newName, control);
          }
        }
      });
    
      this.contentForm.patchValue({
        subtitleKeys: this.subtitleKeys
      });
    }
    
  editTemplate(){
    if(this.contentTypeUserRights_update){
      this.editMode=true
    }else{
      const message = this.translate.instant("SnackBarConstants.NOT_ALLOWED_UPDATE")
      this.commentService.addSnackBar.emit(message)
    }

  }

  cancelEditContentType(){
    const message = this.translate.instant("SnackBarConstants.UPDATE_DISMISS")
    this.commentService.addSnackBar.emit(message) //showSnackbar
    this.dialogRef.close([false, this.oldContentTypeData])
  }

  async resetList(){
    try{
      this.commentService.progressSpin.emit(true)
      await this.settingsService.observeContextsContentTypesFirestoreWithId(this.settingsService.contextId$)
      this.commentService.progressSpin.emit(false)

    }catch(error){
      this.commentService.progressSpin.emit(false)
    }
  }

  async saveEditContentType() {
    this.commentService.progressSpin.emit(true);
  
    try {
      const value = this.contentForm.value;
      const contentType = new ContentType();

      const { id, contextId, passTemplateId, displayName, titleKey, schema, displaySections } = value;
  
      const subtitleKeys = this.subtitleKeys.map((_, index) => this.contentForm.get('subtitleKey' + index)?.value);
  
      const newContentType = {
        id,
        contextId,
        passTemplateId,
        displayName,
        titleKey,
        subtitleKeys,
        schema,
        displaySections
      };
  
      contentType.setFromAny(newContentType);

      await this.contentTypeService.updateContentType(contentType, this.oldContentTypeData);

      this.commentService.progressSpin.emit(false);
      const message = this.translate.instant("SnackBarConstants.UPDATE_OK");
      this.commentService.addSnackBar.emit(message);
      this.resetList();
      this.dialogRef.close([true, contentType]);
  
    } catch (error) {
      this.commentService.progressSpin.emit(false);
      const message = this.translate.instant("SnackBarConstants.UPDATE_FAILED");
      this.commentService.addSnackBar.emit(message);
    }
  }
}
