<section>

    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title style="color:#595c5f;">
                <mat-icon style="margin-right: 10px;">format_color_fill</mat-icon>
                {{'pass.widgetStyle' | translate}}
            </mat-panel-title>
        </mat-expansion-panel-header>

        <form [formGroup]="widgetStyleForm" class="m-1">
    
            <div [hidden]="lightbackgroundColor && lightforegroundColor">
                <span class="text-muted">{{'pass.light' | translate}}</span>
                <div class="list-group mb-4 ">
                    <div class="list-group-item" [hidden]="lightbackgroundColor">
                        <div class="d-flex flex-row justify-content-between align-items-center flex-wrap">
                            <span class=" w-50 bg-white" >{{'pass.buttons.backgroundColor' | translate}}</span>
                        
                            <label class=" d-flex flex-row-reverse justify-content-start align-items-center" style="border-left:none;" >
                                <ngx-colors ngx-colors-trigger [palette]="colorPalette" [hideTextInput]="false" [format]="'hex'" cancelLabel="{{'pass.colorPickerCancel' | translate}}" acceptLabel="{{'pass.colorPickerConfirm' | translate}}" formControlName="lightbackgroundColor" style="margin-left:10px;"></ngx-colors>
                                <span *ngIf="invalid_lightbackgroundColor" id="invalid_lightbackgroundColor" style="color:red;">{{'pass.invalid_color' | translate}}</span> 
                            </label>
                        </div>
                    </div>
        
        
                    <div class="list-group-item" [hidden]="lightforegroundColor">
                        <div class="d-flex flex-row justify-content-between align-items-center flex-wrap">
                            <span class="w-50 bg-white" >{{'pass.buttons.foregroundColor' | translate}}</span>
                            
                            <div class=" d-flex flex-row-reverse justify-content-start align-items-center" style="border-left:none;" >
                                <ngx-colors ngx-colors-trigger [palette]="colorPalette" [hideTextInput]="false" [format]="'hex'" cancelLabel="{{'pass.colorPickerCancel' | translate}}" acceptLabel="{{'pass.colorPickerConfirm' | translate}}" formControlName="lightforegroundColor" style="margin-left:10px;"></ngx-colors>
                                <span *ngIf="invalid_lightforegroundColor" id="invalid_lightforegroundColor" style="color:red;">{{'pass.invalid_color' | translate}}</span>
                            </div>
                        </div>
                    </div>  
                </div>
            </div>
    
            <div [hidden]="darkbackgroundColor && darkforegroundColor">
                <span class="text-muted">{{'pass.dark' | translate}}</span>
                <div class="list-group mb-4 ">
                    <div class="list-group-item" [hidden]="darkbackgroundColor">
                        <div class="d-flex flex-row justify-content-between align-items-center flex-wrap">
                            <span class=" w-50 bg-white" >{{'pass.buttons.backgroundColor' | translate}}</span>
                        
                            <label class=" d-flex flex-row-reverse justify-content-start align-items-center" style="border-left:none;" >
                                <ngx-colors ngx-colors-trigger [palette]="colorPalette" [hideTextInput]="false" [format]="'hex'" cancelLabel="{{'pass.colorPickerCancel' | translate}}" acceptLabel="{{'pass.colorPickerConfirm' | translate}}" formControlName="darkbackgroundColor" style="margin-left:10px;"></ngx-colors>
                                <span *ngIf="invalid_darkbackgroundColor" id="invalid_darkbackgroundColor" style="color:red;">{{'pass.invalid_color' | translate}}</span> 
                            </label>
                        </div>
                    </div>
        
        
                    <div class="list-group-item" [hidden]="darkforegroundColor">
                        <div class="d-flex flex-row justify-content-between align-items-center flex-wrap">
                            <span class="w-50 bg-white" >{{'pass.buttons.foregroundColor' | translate}}</span>
                            
                            <div class=" d-flex flex-row-reverse justify-content-start align-items-center" style="border-left:none;" >
                                <ngx-colors ngx-colors-trigger [palette]="colorPalette" [hideTextInput]="false" [format]="'hex'" cancelLabel="{{'pass.colorPickerCancel' | translate}}" acceptLabel="{{'pass.colorPickerConfirm' | translate}}" formControlName="darkforegroundColor" style="margin-left:10px;"></ngx-colors>
                                <span *ngIf="invalid_darkforegroundColor" id="invalid_darkforegroundColor" style="color:red;">{{'pass.invalid_color' | translate}}</span>
                            </div>
                        </div>
                    </div>  
                </div>
            </div>

            <mat-form-field appearance="outline" class="w-100" [hidden]="contentAlignment">
                <mat-label>{{'pass.contentAlignment' | translate}}</mat-label>
                <mat-select formControlName="contentAlignment" class="input-group">
                  <mat-option value="trailing"><mat-icon>format_align_right</mat-icon>{{'pass.trailing' | translate}}</mat-option>
                  <mat-option value="leading"><mat-icon>format_align_left</mat-icon>{{'pass.leading' | translate}}</mat-option>
                  <mat-option value="center"><mat-icon>format_align_center</mat-icon>{{'pass.center' | translate}}</mat-option>
                </mat-select>
            </mat-form-field>
        </form>
    </mat-expansion-panel>


</section>