import { Component, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { Router } from '@angular/router';
import { SettingsService } from '../service/settings.service';
import { ContentTypeService } from '../service/content-type.service';
import { CommentsService } from '../service/comments.service';
import { TranslateService } from '@ngx-translate/core';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { deepCopy } from '../../../../backend/utils/object';
import { ContentTypesSchemaPropertiesDialogComponent } from '../content-types-schema-properties-dialog/content-types-schema-properties-dialog.component';
import { ContentType } from '../../../../backend/src/contentType.dto';

interface schemaNode {
  key?: string;
  type:string;
  mandatory?:boolean;
  description?:string;
  properties?: schemaNode[];
  items?:schemaNode
}

@Component({
  selector: 'app-content-types-schema-view-dialog',
  templateUrl: './content-types-schema-view-dialog.component.html',
  styleUrls: ['./content-types-schema-view-dialog.component.css']
})
export class ContentTypesSchemaViewDialogComponent {
  @ViewChild(MatAccordion) accordion: MatAccordion;
  routerUrl:any
  idToShow:any
  currentRoute:any
  contentTypeUserRights_update:any
  contentTypeUserRights_delete:any
  schemaData:any
  schemaForm!:FormGroup
  contentForm!:FormGroup
  contenttype:any
  contentData:any
  displayName:any
  propertiesTypes={
   
  }
  oldSchema:any
  allproperties:schemaNode[]
  TREE_DATA:schemaNode[]
  treeControl = new NestedTreeControl<schemaNode>(node => node.properties);
  dataSource = new MatTreeNestedDataSource<schemaNode>();


  constructor(
    public dialog: MatDialog,
    private settingsService: SettingsService,
    private contentTypeService: ContentTypeService,
    private fb: FormBuilder,
    private commentService: CommentsService,
    private translate:TranslateService,
    public dialogRef: MatDialogRef<ContentTypesSchemaViewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any[],){
  }

  ngOnInit(){
    this.idToShow = this.data['id']
    this.contentData = this.data['contentType']
    this.contentForm = this.fb.group({
      schema:[]
    })
    this.schemaForm = this.fb.group({
      properties:[],
      type:[]
    })
    
    if(this.contentData){
      this.contenttype = deepCopy(this.contentData)
      if(this.contenttype.displayName)
        this.displayName = this.contenttype.displayName
      if(this.contenttype.schema){
        this.schemaData = this.contenttype.schema
        this.oldSchema = deepCopy(this.schemaData)
        if(this.schemaData.properties)
          this.allproperties=this.schemaData.properties
        else
          this.allproperties=[]
        this.initializeForm()
      }else{
        this.initializeDefaultForm()

      }

    }else{
      this.getContentTypeId()
    }
    this.contentTypeUserRights_update = this.settingsService.userRolesRights$['contentTypes']['U']
    this.contentTypeUserRights_delete = this.settingsService.userRolesRights$['contentTypes']['D']

  }

  refreshTree(){
    let _data = this.dataSource.data;
    this.dataSource.data = null;
    this.dataSource.data = _data;
    }

  hasChild = (_: number, node: schemaNode) => !!node.properties && node.properties.length >= 0;
  isArrayType = (_: number, node: schemaNode) => node.type=='array' || node.items;

  getAncestors(array, key) {
    if (typeof array != "undefined") {
      for (let i = 0; i < array.length; i++) {
        if (array[i].key === key) {
          return [array[i]];
        }
        const a = this.getAncestors(array[i].properties, key);
        if (a !== null) {
          a.unshift(array[i]);
          return a;
        }
      }
    }
    return null;
  }

  onLeafNodeClick(node: schemaNode) {    
    const ancestors = this.getAncestors(this.dataSource.data, node.key);
    let indexBreadcrumbs=""
    let breadcrumbs = "";
    ancestors.forEach((ancestor,index) => {
      breadcrumbs += `${ancestor.key}/`;
      indexBreadcrumbs += `${index}/`;
    });

    let all={ancestors, breadcrumbs}
    return all
  }


  initializeForm(){
    const {schema} =  this.contenttype
    const {properties, type}=this.schemaData

    this.contentForm.patchValue({
      schema: schema ? schema : undefined
    })

    this.schemaForm.patchValue({
      properties: properties ? properties : [],
      type: type ? type : undefined
    })
    
    this.dataSource.data = this.allproperties

  }

  initializeDefaultForm(){

    this.allproperties = []

    this.contentForm.patchValue({
      schema: undefined
    })

    this.schemaForm.patchValue({
      properties: [],
      type: "object"
    })
    
    this.dataSource.data = this.allproperties

  }

  backTo(){
  }

  addProperty(){
    let dialogRef= this.dialog.open(ContentTypesSchemaPropertiesDialogComponent, {
      data: { 
        type:"add",
        values:this.schemaData
      },
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if(result[0]){
        let properties = this.allproperties
        if(properties){
          if(properties.length==0){
            properties.push(result[1])
            this.schemaForm.patchValue({type: 'object'})
          }else{
            properties.push(result[1])
          }
        }else{
          properties=[]
          properties.push(result[1])
          this.schemaForm.patchValue({type: 'object'})
        }

        this.schemaForm.patchValue({properties: properties})
        this.allproperties=properties
        this.dataSource.data = this.allproperties

        this.refreshTree()
      }
    });
  }


  async getContentTypeId(){
    try{
      this.commentService.progressSpin.emit(true)
      this.contenttype = await this.settingsService.observeContextsContentTypesIdFirestore(this.idToShow)
      this.contentData= new ContentType 
      this.contentData.setFromAny(this.contenttype)

      if(this.contenttype.displayName)
        this.displayName = this.contenttype.displayName

      if(!this.contentData['id']){
        this.contentData['id']= this.idToShow
      }
  
      this.commentService.progressSpin.emit(false)

    }catch(error){
      console.log(error)
      this.commentService.progressSpin.emit(false)
      const message = this.translate.instant("SnackBarConstants.LOAD_FAILED")
      this.commentService.addSnackBar.emit(message)

    }
  }

  clickPropertyList(data, index){
  }

  addNewProperty(node,index){
    const all = this.onLeafNodeClick(node)
   const node_descendants =  this.treeControl.getDescendants(node)

    let dialogRef= this.dialog.open(ContentTypesSchemaPropertiesDialogComponent, {
      data: { 
        type:"add_toObject",
        values:node
      },
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if(result[0]){
        let newPorpertiesValues =[]
        let properties = node.properties
        if(properties){
          if(properties.length==0){
            properties.push(result[1])
          }else{
            properties.push(result[1])
          }
        }else{
          properties=[]
          properties.push(result[1])
        }

        this.schemaForm.patchValue({properties: this.allproperties})
        this.dataSource.data = this.allproperties
        this.refreshTree()

      }

    });
  }

  deletePropierty(data, index){
  }

  onCancel(){
    this.dialogRef.close([false])
  }

  onSave(){
    this.dialogRef.close([true, this.schemaForm.value])
  }

  clickList(){
  }
  
  deleteNode(node: schemaNode, index: number) {
    // Recursive function to delete a node and its children
    const deleteNodeRecursive = (nodes: schemaNode[], targetNode: schemaNode) => {
      for (let i = 0; i < nodes.length; i++) {
        if (nodes[i] === targetNode) {
          // Remove the node from the array
          nodes.splice(i, 1);
          return true; // Node found and deleted
        }
        if (nodes[i].properties) {
          // Recursively search in nested properties
          if (deleteNodeRecursive(nodes[i].properties, targetNode)) {
            return true; // Node found and deleted in nested properties
          }
        }
        if (nodes[i].items) {
          // Recursively search in array items
          if (deleteNodeRecursive([nodes[i].items], targetNode)) {
            return true; // Node found and deleted in array items
          }
        }
      }
      return false; // Node not found
    };
  
    // Start the recursive deletion from the root
    deleteNodeRecursive(this.allproperties, node);
  
    // Refresh the tree
    this.dataSource.data = this.allproperties;
    this.refreshTree();
  }

/*   deleteNode(node, index){
    if(this.allproperties){
      const idx = this.allproperties.indexOf(node);
      if (idx > -1) {
        this.allproperties.splice(idx, 1);
      }
    }

    if(node.properties){
      node.properties.forEach(n => {
        const idx = this.allproperties.indexOf(n);
        if (idx > -1) {
          this.allproperties.splice(idx, 1);
        }
      })
    }
    this.dataSource.data = this.allproperties

    this.refreshTree()
  } */
 /*    deleteSchema(){
    this.schemaForm.patchValue({properties: []})
    this.allproperties=[]
    this.dataSource.data = this.allproperties

    this.refreshTree()
  } */
}
