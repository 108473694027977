import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { deepCopy } from '../../../../backend/utils/object';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { languagesList } from '../../../../backend/src/languagesList.dto';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-pass-templates-settings-edit',
  templateUrl: './pass-templates-settings-edit.component.html',
  styleUrls: ['./pass-templates-settings-edit.component.css']
})

export class PassTemplatesSettingsEditComponent {
  passData:any
  passEditForm!:FormGroup
  oldData:any
  contextId:any
  languagesList = languagesList
  languagesPass:any=[]
  oldLanguages:any
  languages:any
 
  constructor(public dialogRef: MatDialogRef<PassTemplatesSettingsEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any[],
    private fb: FormBuilder,){
      dialogRef.disableClose = true;
  }

  ngOnInit():void{
    this.passData=deepCopy(this.data[0])
    this.oldData=deepCopy(this.data[0])
    this.contextId=this.data[1]
    this.languages = Object.entries(languagesList)
    this.languages.sort( (a,b) => { return a[1].toLowerCase().localeCompare(b[1].toLowerCase());})

    if(this.passData.languages){
      this.languagesPass = deepCopy(this.passData.languages)
      this.oldLanguages = deepCopy(this.passData.languages)
    }else{
      this.languagesPass= []
    }

    this.passEditForm = this.fb.group({
      id:[this.passData.id],
      widgets:[this.passData.widgets],
      contextId:[this.passData.contextId],
      translations:[this.passData.translations],
      theme: [this.passData.theme],
      comment:[this.passData.comment],
      name:[this.passData.name, Validators.required],
      templateName:[this.passData.templateName],
      contentType:[this.passData.contentType],
      customer:[this.passData.customer],
      tridyId:[this.passData.tridyId],
      languages:[this.passData.languages],
      enableShoppingCart: [this.passData.enableShoppingCart],
      enableCustomerLogin: [this.passData.enableCustomerLogin]
    })

  }


  addLanguageToPass(lang){
    if(this.passEditForm.value.languages){
      if(this.passEditForm.value.languages.length!=0){
        if(!this.passEditForm.value.languages.includes(lang)){
          const langform = this.passEditForm.value.languages
          langform.push(lang)
          this.languagesPass = langform
          this.passEditForm.patchValue({languages: langform})
        }
      }else{

        let languages = []
        languages.push(lang)
        this.languagesPass = languages
        this.passEditForm.patchValue({languages: languages})
      }
    }else{
      let languages = []
      languages.push(lang)
      this.languagesPass = languages
      this.passEditForm.patchValue({languages: languages})
    }
    
  }

  deleteLanguageOfPass(lang, index){
    const bdelete = this.languagesPass
    bdelete.splice(index,1)
    this.passEditForm.patchValue({languages: bdelete})
  }

  languagesIsAdded(lang){
    let exists = false
    if(this.passEditForm.value.languages){
      if(this.passEditForm.value.languages.includes(lang)){
        exists=true
      }
    }
    return exists
  }
  

  dropLanguage(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.languagesPass, event.previousIndex, event.currentIndex);
    this.passEditForm.patchValue({languages: this.languagesPass})

  }


  onCancelEdit(){
    this.dialogRef.close([false,this.oldData])
  }

  onSaveEdit(){
    this.dialogRef.close([true,this.passEditForm.value])
    // remove empty strings?
  }

}