<section class="container">

    <div class="row mt-5">
        <div class="col-12 mt-5">

          <h1 *ngIf="accountName">{{'home.hi' | translate}}, <span style="color:var(--warmOrange);">{{accountName}}</span>!</h1>
          <h1 *ngIf="!accountName">{{'home.hi_unknown' | translate}}</h1>
          <p class="lead" style="font-family:'MyFont-Light';">{{ 'home.todo' | translate}}</p>
          <hr class="my-5 opacity-100" style="border-color:#F0F0F0;">
  
          <div class="row row-cols-2 row-cols-lg-5 g-2 g-lg-3">

            <div class="col" [ngClass]="{'opacity-25': !contentTypes}" [matTooltip]="contentTypes ? null : ('home.tooltip' | translate)">
              <mat-card>
                <mat-card-content class="custom-card p-3 cursor-pointer" (click)="contentTypes ? openRoute('contentTypes') : null">
                  <div class="py-2">
                    <span class="card-icon material-symbols-outlined">book_online</span>
                  </div>
                  <div class="py-2">DPP Publisher</div>
                </mat-card-content>
              </mat-card>
            </div>

            <div class="col" [ngClass]="{'disabled': !taskTemplates}" [matTooltip]="taskTemplates ? null : ('home.tooltip' | translate)">
              <mat-card>
                <mat-card-content class="custom-card p-3 cursor-pointer" (click)="taskTemplates ? openRoute('taskTemplates') : null">
                  <div class="py-2">
                    <span class="card-icon material-symbols-outlined">aod</span>
                  </div>
                  <div class="py-2">{{ 'topNavbar.tracer' | translate }}</div>
                </mat-card-content>
              </mat-card>
            </div>

            <div class="col" [ngClass]="{'disabled': !content}" [matTooltip]="content ? null : ('home.tooltip' | translate)">
              <mat-card>
                <mat-card-content class="custom-card p-3 cursor-pointer" (click)="content ? openRoute('content') : null">
                  <div class="py-2">
                      <span class="card-icon material-symbols-outlined">widgets</span>
                  </div>
                  <div class="py-2">{{'topNavbar.content' | translate}}</div>
                </mat-card-content>
              </mat-card>
            </div>

            <div class="col" [ngClass]="{'disabled': !reports}" [matTooltip]="reports ? null : ('home.tooltip' | translate)">
              <mat-card>
                <mat-card-content class="custom-card p-3 cursor-pointer" (click)="reports ? openRoute('reports') : null">
                  <div class="py-2">
                      <span class="card-icon material-symbols-outlined">receipt_long</span>
                  </div>
                  <div class="py-2">{{'topNavbar.reports' | translate}}</div>
                </mat-card-content>
              </mat-card>
            </div>

            <div class="col" [ngClass]="{'disabled': !taskTemplates}" [matTooltip]="taskTemplates ? null : ('home.tooltip' | translate)">
              <mat-card>
                <mat-card-content class="custom-card p-3 cursor-pointer" (click)="taskTemplates ? openRoute('finishedTasks') : null">
                  <div class="py-2">
                      <span class="card-icon material-symbols-outlined">task</span>
                  </div>
                  <div class="py-2">{{'topNavbar.finishedTasks' | translate}}</div>
                </mat-card-content>
              </mat-card>
            </div>

            <div class="col" [ngClass]="{'disabled': !tridys}" [matTooltip]="tridys ? null : ('home.tooltip' | translate)">
              <mat-card>
                <mat-card-content class="custom-card p-3 cursor-pointer" (click)="openRoute('tridys')">
                  <div class="py-2">
                      <span class="card-icon material-symbols-outlined">qr_code</span>
                  </div>
                  <div class="py-2">{{'topNavbar.tridys' | translate}}</div>
                </mat-card-content>
              </mat-card>
            </div>
            <div class="col" [ngClass]="{'disabled': !context}" [matTooltip]="context ? null : ('home.tooltip' | translate)">
              <mat-card>
                <mat-card-content class="custom-card p-3 cursor-pointer" (click)="openRoute('cdSettings')">
                  <div class="py-2">
                      <span class="card-icon material-symbols-outlined">design_services</span>
                  </div>
                  <div class="py-2">{{'topNavbar.cdSettings' | translate}}</div>
                </mat-card-content>
              </mat-card>
            </div>
            
          </div>
  
        </div>
    </div>
  </section>
  
<div class="version-info"> {{buildEnvironment}} - {{version}} ( {{buildNumber}} )</div>
