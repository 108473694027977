import { Injectable } from '@angular/core';
import { SettingsService } from './settings.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { NetworkConstants } from 'src/network.constants';
import { Template } from '../../../../backend/src/template.dto';
import { removeUndefinedValuesFromObject } from '../../../../backend/utils/object';
import { CommentsService } from './comments.service';
import { deleteField } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class PassDefinitionsService {
  newPassId:any={}
  contextCDData:any
  
  constructor(private settingsService: SettingsService,
    private firestore: AngularFirestore,
    private commentsService: CommentsService) { }


  async createPassDefinition(passDefinitionName:string, contentypeId, languages):Promise<string>{
    return new Promise(async (resolve,reject)=> {
      try{
        this.commentsService.progressSpin.emit(true)
        const passDefinitionsRefaa = this.firestore.collection(NetworkConstants.COLLECTION_CONTEXTS)
        .doc(this.settingsService.contextId$)
        .collection(NetworkConstants.COLLECTION_PASSDEFINITIONS).doc().ref

        const newid = passDefinitionsRefaa.id
        this.newPassId[contentypeId]=newid
        let newPass = {}
        newPass['id'] = passDefinitionsRefaa.id
        newPass['contextId'] = this.settingsService.contextId$
        newPass['creationDate'] = new Date()
        newPass['timeStamp'] = new Date()
        newPass['widgets'] = []
        newPass['name'] = passDefinitionName
        newPass['languages'] = languages

        if (this.settingsService.contextAccount$?.design){
          const cdSettings = this.settingsService.contextAccount$?.design
          if(cdSettings.theme && cdSettings.theme.fonts){
            if(cdSettings.theme && cdSettings.theme.fonts.title){
              if(!cdSettings.theme.fonts.title.url || !cdSettings.theme.fonts.title.name ){
                delete cdSettings.theme.fonts.title
              }
            }

            if(cdSettings.theme && cdSettings.theme.fonts.subtitle){
              if(!cdSettings.theme.fonts.subtitle.url || !cdSettings.theme.fonts.subtitle.name ){
                delete cdSettings.theme.fonts.subtitle
              }
            }

            if(cdSettings.theme && cdSettings.theme.fonts.plain ){
              if(!cdSettings.theme.fonts.plain.url || !cdSettings.theme.fonts.plain.name ){
                delete cdSettings.theme.fonts.plain
              }
            }

            if(cdSettings.theme && cdSettings.theme.fonts.italic ){
              if(!cdSettings.theme.fonts.italic.url || !cdSettings.theme.fonts.italic.name ){
                delete cdSettings.theme.fonts.italic
              }
            }

            if(cdSettings.theme && cdSettings.theme.fonts.bold ){
              if(!cdSettings.theme.fonts.bold.url || !cdSettings.theme.fonts.bold.name ){
                delete cdSettings.theme.fonts.bold
              }
            }

            const fonts = Object.keys(cdSettings.theme.fonts)
            if(fonts.length==0)
            delete cdSettings.theme.fonts

          }
          newPass['theme'] = cdSettings.theme
        }

        this.firestore.collection(NetworkConstants.COLLECTION_CONTEXTS)
        .doc(this.settingsService.contextId$)
        .collection(NetworkConstants.COLLECTION_PASSDEFINITIONS).doc(newid)
        .set({...newPass})

        resolve(newid)
        this.commentsService.progressSpin.emit(false)

      }catch(error){
        this.commentsService.progressSpin.emit(false)
        reject(error)
        console.log(error)
      }
    })
  }

  updatePassDefinition(passDefinition:Template): Promise<void>{
    return new Promise(async (resolve,reject)=> {
      const data = removeUndefinedValuesFromObject(passDefinition)
     
      try{
        data['timeStamp'] = new Date()
        await this.firestore.collection(NetworkConstants.COLLECTION_CONTEXTS)
        .doc(this.settingsService.contextId$)
        .collection(NetworkConstants.COLLECTION_PASSDEFINITIONS)
        .doc(passDefinition.id)
        .update(data)

        resolve()
      }
      catch(error){
        reject(error)
        console.log(error)
      }
    })
  }
  setPassDefinition(passDefinition: Template): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        const cleanedData = removeUndefinedValuesFromObject(passDefinition);
        cleanedData['timeStamp'] = new Date();
  
        const updateData: any = {};
        for (const [key, value] of Object.entries(cleanedData)) {
          // Keep valid values, delete null or empty string values
          if (value == null || value === '') {
            updateData[key] = deleteField()
            //console.log('Deleted field:', key);
          } else {
            updateData[key] = value;
          }
        }
  
        await this.firestore.collection(NetworkConstants.COLLECTION_CONTEXTS)
          .doc(this.settingsService.contextId$)
          .collection(NetworkConstants.COLLECTION_PASSDEFINITIONS)
          .doc(passDefinition.id)
          .set(updateData, { merge: true });
  
        resolve();
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  }

  updatePassDefinitionAfterUpdateContentType(passDefinitionId:string, passDefinitionName:string): Promise<void>{
    return new Promise(async (resolve, reject) => {
     
      const data = {name: passDefinitionName, id: passDefinitionId}
      try{
        data['timeStamp'] = new Date()

        await this.firestore.collection(NetworkConstants.COLLECTION_CONTEXTS)
        .doc(this.settingsService.contextId$)
        .collection(NetworkConstants.COLLECTION_PASSDEFINITIONS)
        .doc(passDefinitionId)
        .update(data)

        resolve()
      }catch(error){
        reject(error)
      }
    }) 
  
  
  }

  resetNewPassId(contentypeId){
    delete this.newPassId[contentypeId]
  }

  deletePassDefinition(passDefinitionId:string){
    return new Promise(async (resolve, reject) => {
      try {
        const d = this.firestore.collection(NetworkConstants.COLLECTION_CONTEXTS)
        .doc(this.settingsService.contextId$)
        .collection(NetworkConstants.COLLECTION_PASSDEFINITIONS)
        .doc(passDefinitionId)
        .delete()
        resolve(d)
      } catch (error) {
        reject(error)  
        console.log(error)      
      } 
    })
  }


  async importPassDefinition(passDefinition, contentypeId):Promise<string>{
    return new Promise(async (resolve,reject)=> {
      try{
        //this.commentsService.progressSpin.emit(true)
        const passDefinitionsRefaa = this.firestore.collection(NetworkConstants.COLLECTION_CONTEXTS)
        .doc(this.settingsService.contextId$)
        .collection(NetworkConstants.COLLECTION_PASSDEFINITIONS).doc().ref

        const newid = passDefinitionsRefaa.id

        this.newPassId[contentypeId]=newid
        let newPass = passDefinition
        newPass['id'] = passDefinitionsRefaa.id
        newPass['contextId'] = this.settingsService.contextId$
        if(newPass['contentType'])
          newPass['contentType'] = contentypeId
        newPass['timeStamp'] = new Date()
        newPass['timeStamp'] = new Date()
        newPass['name'] += " import_"

        this.firestore.collection(NetworkConstants.COLLECTION_CONTEXTS)
        .doc(this.settingsService.contextId$)
        .collection(NetworkConstants.COLLECTION_PASSDEFINITIONS).doc(newid)
        .set({...newPass})

        resolve(newid)
        //this.commentsService.progressSpin.emit(false)

      }catch(error){
        //this.commentsService.progressSpin.emit(false)
        reject(error)
        console.log(error)
      }
    })
  }

}
