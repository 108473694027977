import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SettingsService } from '../service/settings.service';
import { removeUndefinedValuesFromObject } from '../../../../backend/utils/object';
import { CommentsService } from '../service/comments.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-content-types-schema-properties-dialog',
  templateUrl: './content-types-schema-properties-dialog.component.html',
  styleUrls: ['./content-types-schema-properties-dialog.component.css']
})
export class ContentTypesSchemaPropertiesDialogComponent {
  schemaPropertyForm!:FormGroup
  schemaPropertyData:any
  typesOptions:any=[
    {
      type:"string",
      icon:"font_download",
      name:"Text",
      mat_icon:"font_download"
    },
    {
      type:"number",
      icon:"tag",
      name:"Number",
      mat_icon:"tag"
    },
    {
      type:"boolean",
      icon:"toggle_on",
      name:"Boolean",
      mat_icon:"toggle_on"
    },
    {
      type:"array",
      icon:"list",
      name:"List",
      mat_icon:"format_list_bulleted"
    },
    {
      type:"object",
      icon:"calendar_view_month",
      name:"Key / Value",
      mat_icon:"grid_on"
    },
    {
      type:"date",
      icon:"calendar_month",
      name:"Date",
      mat_icon:"calendar_today"
    },
    
  ]

  listTypesOptions:any=[
    {
      type:"string",
      icon:"font_download",
      name:"Text",
      mat_icon:"font_download"
    },
    {
      type:"number",
      icon:"tag",
      name:"Number",
      mat_icon:"tag"
    },
    {
      type:"boolean",
      icon:"toggle_on",
      name:"Boolean",
      mat_icon:"toggle_on"
    },
    {
      type:"object",
      icon:"calendar_view_month",
      name:"Key / Value",
      mat_icon:"grid_on"
    },
    {
      type:"date",
      icon:"calendar_month",
      name:"Date",
      mat_icon:"calendar_today"
    },
    
  ]
  constructor(public dialogRef: MatDialogRef<ContentTypesSchemaPropertiesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any[],
            private fb: FormBuilder,
    public dialog: MatDialog,
    private settingsService:SettingsService,
    private commentsService: CommentsService,
    private translate:TranslateService ){
      dialogRef.disableClose = true;
  }

  ngOnInit(){

    this.schemaPropertyForm=this.fb.group({
      type:[,Validators.required],
      key:[,[Validators.required,Validators.pattern('^(?![_\\.])(?!.*[_\\.]$)(?!.*[\'\";,#%&<>(){}\\[\\]/\\\\|`*@$^=+?!]).*$')]],
      description:[],
      mandatory:[],
      listItemsType:[],
      properties:[],
      items:[]
    })

    this.schemaPropertyForm.patchValue({
      type:undefined,
      key:undefined,
      description:undefined,
      mandatory:undefined,
      listItemsType:undefined,
      properties:undefined,
      items:undefined
    })

  }

  onCancelEdit(){
    this.dialogRef.close([false, this.schemaPropertyForm.value])
  }

  onSaveEdit(){
      if(this.schemaPropertyForm.valid){
        const {type, key, description,mandatory,listItemsType,properties,items} = this.schemaPropertyForm.value

        const values = {
          type: type ? type : undefined,
          key: key ? key : undefined, 
          description: description ? description : undefined,
          mandatory: mandatory ? mandatory : undefined,
          listItemsType: listItemsType ? listItemsType : undefined,
          properties: properties ? properties : undefined,
          items: items ? items : undefined
        }

        if(type=='object'){
          values.properties=[]
        }
        if(listItemsType){
          delete values['listItemsType']
          values['items']={
            type:listItemsType
          }
        }
        const data = removeUndefinedValuesFromObject(values)
        this.dialogRef.close([true, data])
    }else{
      const message = this.translate.instant("SnackBarConstants.MANDATORY_FIELDS_EMPTY")
      this.commentsService.addSnackBar.emit(message)
    }
  }
}
