
<section class="px-3" id="phoneSection-{{imageData.id}}">
<!--     <button *ngIf="editMode" class="edit-button"><span class="material-symbols-outlined" (click)="openDialogEdit();">
        edit_square
        </span>
    </button> -->
   
    <div class="ps-0">
        <span *ngIf="imageData.title" id="title" class="text-wrap" style="color:var(--title); font-size:18pt; word-wrap: break-word;" [style.color]="titleColor">{{imageData.title}}</span>
        <p *ngIf="imageData.subtitle" id="subtitle" class="text-wrap" style="color:var(--subtitle); font-size:13pt; word-wrap: break-word;" [style.color]="subtitleColor">{{imageData.subtitle}}</p>
    </div>

    <img *ngIf="!imageData.linkURL && !url" src={{imageData.url}} alt={{imageData.id}} class="w-100 " style=" border-radius: var(--cornerRadius);" onerror="this.src='./../../assets/img/default.jpg'">
    <img *ngIf="!imageData.linkURL && url" src={{url}} alt={{imageData.id}} class="w-100 " style=" border-radius: var(--cornerRadius);" onerror="this.src='./../../assets/img/default.jpg'">
    <a *ngIf="imageData.linkURL && linkURL" href="{{linkURL}}" target="_blank">
        <img *ngIf="url" src={{url}} alt={{imageData.id}} class="w-100 " style=" border-radius:var(--cornerRadius);" onerror="this.src='./../../assets/img/default.jpg'">
        <img *ngIf="!url" src={{imageData.url}} alt={{imageData.id}} class="w-100 " style=" border-radius:var(--cornerRadius);" onerror="this.src='./../../assets/img/default.jpg'">
    </a>

    <a *ngIf="imageData.linkURL && !linkURL" href="{{imageData.linkURL}}" target="_blank">
        <img *ngIf="url" src={{url}} alt={{imageData.id}} class="w-100 " style=" border-radius:var(--cornerRadius);" onerror="this.src='./../../assets/img/default.jpg'">
        <img *ngIf="!url" src={{imageData.url}} alt={{imageData.id}} class="w-100 " style=" border-radius:var(--cornerRadius);" onerror="this.src='./../../assets/img/default.jpg'">
    </a>
    <span *ngIf="dynamic" class="material-symbols-outlined" style="right: 10px; position: absolute; filter: drop-shadow(2px 4px 6px black);">
        finance_chip
    </span>
    
</section>