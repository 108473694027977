import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SettingsService } from '../service/settings.service';
import { SettingsDialogComponent } from '../settings-dialog/settings-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { userRolesRightsAll } from 'src/roles';
import { defaultModulesAll, defaultModulesPublisher, defaultModulesTracer } from 'src/modules';
import { NavbarAccesService } from '../service/navbar-acces.service';
import { AuthService } from '../service/auth.service';
import { AccountLanguagesDialogComponent } from '../account-languages-dialog/account-languages-dialog.component';
import { PlaceholderListComponent } from '../placeholder-list/placeholder-list.component';
import { NgDialogAnimationService } from 'ng-dialog-animation';

@Component({
  selector: 'app-top-navbar',
  templateUrl: './top-navbar.component.html',
  styleUrls: ['./top-navbar.component.css']
})
export class TopNavbarComponent implements OnInit{
  userData:any
  accountName:any
  languageSelected:any
  languageFlag:any
  userName:any
  hostName:any
  context=true
  contentTypes=true
  content=true
  tridys=true
  coverImages=false
  products=true
  taskTemplates=true
  workTasks=true
  users=true
  reports=true
  interfaces=true
  settingsData={
    contextIds:[],
    contextIdsNames:[],
    account:"",
    user:""
  }
  serviceRoles:any
  contextId:any
  contextData:any

  constructor(private router: Router,
    public dialog: NgDialogAnimationService,
    private settingsService: SettingsService,
    private translate: TranslateService,
    private authService: AuthService,
    private navbarAcces: NavbarAccesService){
      const currentLang = this.translate.currentLang
      this.languageSelected = currentLang
      if(this.languageSelected=="en"){
        this.languageFlag="gb"
      }else{
        this.languageFlag=this.languageSelected
      }
      this.serviceRoles = this.settingsService.userRolesRights$
  }

  ngOnInit(): void{

    //getContextId
    this.settingsService.contextIdChange$.subscribe(value=> {
      this.contextId = value
    })

    //get data of selected context
    this.settingsService.selectedContextDataChange$.subscribe(value=> {
      this.contextData = value
    })

    this.settingsService.userNameChange$.subscribe(value=>{
      this.userName = value
    })

    this.contextId = this.settingsService.contextId$

    this.navbarAcces.contextChange.subscribe( value => {
      this.context=value
    })

    this.navbarAcces.contentTypesChange.subscribe( value => {
      this.contentTypes=value
    })

    this.navbarAcces.contentChange.subscribe( value => {
      this.content=value

    })
    this.navbarAcces.tridysChange.subscribe( value => {
      this.tridys=value
    })

    this.navbarAcces.coverImagesChange.subscribe( value => {
      this.coverImages=value
    })
    this.navbarAcces.productsChange.subscribe( value => {
      this.products=value

    })
    this.navbarAcces.taskTemplatesChange.subscribe( value => {
      this.taskTemplates=value
    })
    this.navbarAcces.workTasksChange.subscribe( value => {
      this.workTasks=value
    })

    this.navbarAcces.usersChange.subscribe( value => {
      this.users = value
    })
    this.navbarAcces.reportsChange.subscribe( value => {
      this.reports = value
    })
    this.navbarAcces.interfacesChange.subscribe( value => {
      this.interfaces = value
    })
  }

  isRouteActive(route: string): boolean {
    const currentRoute = this.router.url;
    const segments = currentRoute.split('/');
    return segments.some(segment => route.split(',').includes(segment));
  }
  
  openDialogSettings():void{
    let dialogRef= this.dialog.open(SettingsDialogComponent, {
      data: { 
        values: this.userData,
        title: this.translate.instant('account')
      },
    });

    dialogRef.afterClosed().subscribe(async (result:any) => {
      if(result[1]){
        try{
          this.contextId = result[0][0]
          await this.settingsService.updateContextId(result[0][0])
          this.accountName=result[1].id
          this.observeData()
          setTimeout( () => {
            //this.updateNavBar()
          },200)
       
        }catch(error){

        }
         
      }
     
    });
    this.settingsService.changeAccount=false
  }

  openLanguagesDialog() {
    const dialogRef = this.dialog.open(AccountLanguagesDialogComponent, {
      disableClose: true,
      panelClass: 'custom-dialog-container',
      position: { right: '0'},
      animation: {
        to: "left",
        incomingOptions: {
          keyframeAnimationOptions: { duration: 200, easing: "ease-in-out" }
        },
        outgoingOptions: {
          keyframeAnimationOptions: { duration: 200, easing: "ease-in-out" }
        }
      }
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  async observeData(){

    try{
      this.settingsService.resetSettingsValues()
      const a = await this.settingsService.observeContextsFirestore()
      const cu = await this.settingsService.observeContextsIdLoggedFirestore(this.settingsService.contextId$)
      const usersWithcontextId = this.settingsService.observeUsersWithcontextId(this.settingsService.contextId$)
      const pstpmId = this.settingsService.observeContextsPassDefinitionsFirestoreWithId(this.settingsService.contextId$)
      this.settingsService.observeContextsContentTypesFirestoreWithId(this.settingsService.contextId$)
      this.settingsService.observeContextsWorkTasksTemplatesGroupsFirestoreWithId()
    }catch(error){

    }
    
  }

  useLanguage(language: string): void {
    this.translate.use(language);
    this.languageSelected= language
    if(language=="en"){
      this.languageFlag="gb"
    }else{
      this.languageFlag=language
    }
  }

  openPlaceholdersDialog(){

    let dialogRef = this.dialog.open(PlaceholderListComponent, {
      disableClose: true,
      panelClass: 'custom-dialog-container',
      position: { right: '0'},
      animation: {
        to: "left",
        incomingOptions: {
          keyframeAnimationOptions: { duration: 200, easing: "ease-in-out" }
        },
        outgoingOptions: {
          keyframeAnimationOptions: { duration: 200, easing: "ease-in-out" }
        }
      }
    });

/*     const dialogRef = this.dialog.open(PlaceholderListComponent, {
      width: '400px',
      disableClose: true
    }); */
    dialogRef.afterClosed().subscribe(result => {});
  }

  async signOutUser(){
    try{
      this.settingsService.resetLogout()
      await this.authService.signOut()
      this.settingsData.contextIds=[],
      this.settingsData.contextIdsNames=[],
      this.settingsData.account="",
      this.settingsData.user=""    
      setTimeout(async () => {
        this.router.navigate(['/logIn'])
      }, 300)
    }catch(error){
      console.log(error)
    }
  }

  setNavbarRolesAccess(){
    // this.contextId = this.settingsService.preReloadContextId$
    // if(!this.contextId){
    //   this.contextId = this.settingsService.contextId$
    // } 
    // if(this.settingsService.contextModules$){
    //   if(this.settingsService.contextModules$.publisher && !this.settingsService.contextModules$.tracer){
    //     const modulesAcces = defaultModulesPublisher
    //     if(modulesAcces['contentTypes']){
    //       if(!modulesAcces['contentTypes']['R']){
    //         this.contentTypes=false
    //         this.coverImages=false
    //       }else{
    //         this.contentTypes=true
    //         this.coverImages=true
    //       }
    //     }
        
    //     if(modulesAcces['content']){
    //       if(!modulesAcces['content']['R']){
    //         this.content=false
    //       }else{
    //         this.content=true
    //       }
    //     }

    //     if(modulesAcces['context']){
    //       if(!modulesAcces['context']['R']){
    //         this.context=false
    //       }else{
    //         this.context=true
    //       }
    //     }

    //     if(modulesAcces['interfaces']){
    //       if(!modulesAcces['interfaces']['R']){
    //         this.interfaces=false
    //       }else{
    //         this.interfaces=true
    //       }
    //     }
        
    //     if(modulesAcces['products']){
    //       if(!modulesAcces['products']['R']){
    //         this.products=false
    //       }else{
    //         this.products=true
    //       }
    //     }
        
    //     if(modulesAcces['reports']){
    //       if(!modulesAcces['reports']['R']){
    //         this.tridys=false
    //         this.reports=false
    //       }else{
    //         this.tridys=true
    //         this.reports=true
    //       }
    //     }
        
    //     if(modulesAcces['users']){
    //       if(!modulesAcces['users']['R']){
    //         this.users=false
    //       }else{
    //         this.users=true
    //       }
    //     }
       
    //     if(modulesAcces['workTask']){
    //       if(!modulesAcces['workTask']['R']){
    //         this.workTasks=false
    //       }else{
    //         this.workTasks=true
    //       }
    //     }
        
    //     if(modulesAcces['workTaskTemplates']){
    //       if(!modulesAcces['workTaskTemplates']['R']){
    //         this.taskTemplates=false
    //       }else{
    //         this.taskTemplates=true
    //       }
    //     }
    //   }

    //   if(this.settingsService.contextModules$.tracer && !this.settingsService.contextModules$.publisher){
    //     const modulesAcces = defaultModulesTracer
    //     if(modulesAcces['contentTypes']){
    //       if(!modulesAcces['contentTypes']['R']){
    //         this.contentTypes=false
    //         this.coverImages=false
    //       }else{
    //         this.contentTypes=true
    //         this.coverImages=true
    //       }
    //     }
        
    //     if(modulesAcces['content']){
    //       if(!modulesAcces['content']['R']){
    //         this.content=false
    //       }else{
    //         this.content=true
    //       }
    //     }

    //     if(modulesAcces['context']){
    //       if(!modulesAcces['context']['R']){
    //         this.context=false
    //       }else{
    //         this.context=true
    //       }
    //     }

    //     if(modulesAcces['interfaces']){
    //       if(!modulesAcces['interfaces']['R']){
    //         this.interfaces=false
    //       }else{
    //         this.interfaces=true
    //       }
    //     }
        
    //     if(modulesAcces['products']){
    //       if(!modulesAcces['products']['R']){
    //         this.products=false
    //       }else{
    //         this.products=true
    //       }
    //     }
        
    //     if(modulesAcces['reports']){
    //       if(!modulesAcces['reports']['R']){
    //         this.tridys=false
    //         this.reports=false
    //       }else{
    //         this.tridys=true
    //         this.reports=true
    //       }
    //     }
        
    //     if(modulesAcces['users']){
    //       if(!modulesAcces['users']['R']){
    //         this.users=false
    //       }else{
    //         this.users=true
    //       }
    //     }
       
    //     if(modulesAcces['workTask']){
    //       if(!modulesAcces['workTask']['R']){
    //         this.workTasks=false
    //       }else{
    //         this.workTasks=true
    //       }
    //     }
        
    //     if(modulesAcces['workTaskTemplates']){
    //       if(!modulesAcces['workTaskTemplates']['R']){
    //         this.taskTemplates=false
    //       }else{
    //         this.taskTemplates=true
    //       }
    //     }
    //   }

    //   if(!this.settingsService.contextModules$.publisher && !this.settingsService.contextModules$.tracer){
    //     const modulesAcces = defaultModulesAll
    //     if(modulesAcces['contentTypes']){
    //       if(!modulesAcces['contentTypes']['R']){
    //         this.contentTypes=false
    //         this.coverImages=false
    //       }else{
    //         this.contentTypes=true
    //         this.coverImages=true
    //       }
    //     }
        
    //     if(modulesAcces['content']){
    //       if(!modulesAcces['content']['R']){
    //         this.content=false
    //       }else{
    //         this.content=true
    //       }
    //     }

    //     if(modulesAcces['context']){
    //       if(!modulesAcces['context']['R']){
    //         this.context=false
    //       }else{
    //         this.context=true
    //       }
    //     }

    //     if(modulesAcces['interfaces']){
    //       if(!modulesAcces['interfaces']['R']){
    //         this.interfaces=false
    //       }else{
    //         this.interfaces=true
    //       }
    //     }
        
    //     if(modulesAcces['products']){
    //       if(!modulesAcces['products']['R']){
    //         this.products=false
    //       }else{
    //         this.products=true
    //       }
    //     }
        
    //     if(modulesAcces['reports']){
    //       if(!modulesAcces['reports']['R']){
    //         this.tridys=false
    //         this.reports=false
    //       }else{
    //         this.tridys=true
    //         this.reports=true
    //       }
    //     }
        
    //     if(modulesAcces['users']){
    //       if(!modulesAcces['users']['R']){
    //         this.users=false
    //       }else{
    //         this.users=true
    //       }
    //     }
       
    //     if(modulesAcces['workTask']){
    //       if(!modulesAcces['workTask']['R']){
    //         this.workTasks=false
    //       }else{
    //         this.workTasks=true
    //       }
    //     }
        
    //     if(modulesAcces['workTaskTemplates']){
    //       if(!modulesAcces['workTaskTemplates']['R']){
    //         this.taskTemplates=false
    //       }else{
    //         this.taskTemplates=true
    //       }
    //     }
    //   }

    //   if(this.settingsService.contextModules$.publisher && this.settingsService.contextModules$.tracer){
    //     const modulesAcces = defaultModulesAll
    //     if(modulesAcces['contentTypes']){
    //       if(!modulesAcces['contentTypes']['R']){
    //         this.contentTypes=false
    //         this.coverImages=false
    //       }else{
    //         this.contentTypes=true
    //         this.coverImages=true
    //       }
    //     }
        
    //     if(modulesAcces['content']){
    //       if(!modulesAcces['content']['R']){
    //         this.content=false
    //       }else{
    //         this.content=true
    //       }
    //     }

    //     if(modulesAcces['context']){
    //       if(!modulesAcces['context']['R']){
    //         this.context=false
    //       }else{
    //         this.context=true
    //       }
    //     }

    //     if(modulesAcces['interfaces']){
    //       if(!modulesAcces['interfaces']['R']){
    //         this.interfaces=false
    //       }else{
    //         this.interfaces=true
    //       }
    //     }
        
    //     if(modulesAcces['products']){
    //       if(!modulesAcces['products']['R']){
    //         this.products=false
    //       }else{
    //         this.products=true
    //       }
    //     }
        
    //     if(modulesAcces['reports']){
    //       if(!modulesAcces['reports']['R']){
    //         this.tridys=false
    //         this.reports=false
    //       }else{
    //         this.tridys=true
    //         this.reports=true
    //       }
    //     }
        
    //     if(modulesAcces['users']){
    //       if(!modulesAcces['users']['R']){
    //         this.users=false
    //       }else{
    //         this.users=true
    //       }
    //     }
       
    //     if(modulesAcces['workTask']){
    //       if(!modulesAcces['workTask']['R']){
    //         this.workTasks=false
    //       }else{
    //         this.workTasks=true
    //       }
    //     }
        
    //     if(modulesAcces['workTaskTemplates']){
    //       if(!modulesAcces['workTaskTemplates']['R']){
    //         this.taskTemplates=false
    //       }else{
    //         this.taskTemplates=true
    //       }
    //     }
    //   }

    // }else{

    //   if(this.settingsService.currentUser$){
    //     if(this.settingsService.userRolesRights$){
    //       if(this.settingsService.userRolesRights$['context']){
    //         if(!this.settingsService.userRolesRights$['context']['R']){
    //           this.context=false
    //         }
    //       }else{
    //         this.context=false
    //       }
  
    //       if(this.settingsService.userRolesRights$['users']){
    //         if(!this.settingsService.userRolesRights$['users']['R']){
    //           this.users=false
    //         }
    //       }else{
    //         this.users=false
    //       }
  
    //       if(this.settingsService.userRolesRights$['interfaces']){
    //         if(!this.settingsService.userRolesRights$['interfaces']['R']){
    //           this.interfaces=false
    //         }
    //       }else{
    //         this.interfaces=false
    //       }
          
    //       if(this.settingsService.userRolesRights$['contentTypes']){
    //         if(!this.settingsService.userRolesRights$['contentTypes']['R']){
    //           this.contentTypes=false
    //           this.coverImages=false
    //         }
    //       }else{
    //         this.contentTypes=false
    //         this.coverImages=false
    //       }
          
    //       if(this.settingsService.userRolesRights$['content']){
    //         if(!this.settingsService.userRolesRights$['content']['R']){
    //           this.content=false
    //         }
    //       }else{
    //         this.content=false
    //       }
  
    //       //media
    //       if(this.settingsService.userRolesRights$['reports']){
    //         if(!this.settingsService.userRolesRights$['reports']['R']){
    //           this.reports=false
    //         }
    //       }else{
    //         this.reports=false
    //       }
          
    //       if(this.settingsService.userRolesRights$['workTaskTemplates']){
    //         if(!this.settingsService.userRolesRights$['workTaskTemplates']['R']){
    //           this.taskTemplates=false
    //         }
    //       }else{
    //         this.taskTemplates=false
    //       }
          
    //       if(this.settingsService.userRolesRights$['workTask']){
    //         if(!this.settingsService.userRolesRights$['workTask']['R']){
    //           this.workTasks=false
    //         }
    //       }else{
    //         this.workTasks=false
    //       }
          
    //       if(this.settingsService.userRolesRights$['products']){
    //         if(!this.settingsService.userRolesRights$['products']['R']){
    //           this.products=false
    //         }
    //       }else{
    //         this.products=false
    //       }
  
    //       if(!this.settingsService.userRolesRights$['context'] && !this.settingsService.userRolesRights$['users'] && 
    //           !this.settingsService.userRolesRights$['interfaces'] && !this.settingsService.userRolesRights$['contentTypes'] && 
    //           !this.settingsService.userRolesRights$['passTemplates'] && !this.settingsService.userRolesRights$['content'] && 
    //           !this.settingsService.userRolesRights$['media'] && !this.settingsService.userRolesRights$['workTaskTemplates'] &&
    //           !this.settingsService.userRolesRights$['workTask'] && !this.settingsService.userRolesRights$['products'] &&
    //           !this.settingsService.userRolesRights$['tridys'] && !this.settingsService.userRolesRights$['reports']){
    //             this.contextId = this.settingsService.preReloadContextId$
    //             this.context=true
    //             this.contentTypes=true
    //             this.content=true
    //             this.tridys=true
    //             this.coverImages=true
    //             this.products=true
    //             this.taskTemplates=true
    //             this.workTasks=true
    //             this.users=true
    //             this.reports=true
    //             this.interfaces=true
    //       }
          
    //     }else{
        
    //     }
    //   }
    // }
  }

  openRoute(menuItem:string){
    this.contextId = this.settingsService.preReloadContextId$
      if(!this.contextId){
        this.contextId = this.settingsService.contextId$
      }
      this.router.navigateByUrl('/home/' + this.contextId + '/' + menuItem);
  }

  updateNavBar(roles?:any){
    // let rolesValues
    // if(roles){
    //   rolesValues = roles
    // }else{
    //   rolesValues = this.settingsService.userRolesRights$
    // }

    // if(this.settingsService.contextModules$){

    //   if(this.settingsService.contextModules$.publisher && !this.settingsService.contextModules$.tracer){
    //     this.navbarAcces.setAccesModulesPublisher()
    //   }

    //   if(this.settingsService.contextModules$.tracer && !this.settingsService.contextModules$.publisher){
    //     this.navbarAcces.setAccesModulesTracer()
    //   }

    //   if(!this.settingsService.contextModules$.publisher && !this.settingsService.contextModules$.tracer){
    //     this.navbarAcces.setAccesModulesAll()
    //   }

    //   if(this.settingsService.contextModules$.publisher && this.settingsService.contextModules$.tracer){
    //     this.navbarAcces.setAccesModulesPublisherTracer()
    //   }

    // }else{
    //   if(this.settingsService.currentUser$){
    //     this.navbarAcces.setAccessRoles(this.settingsService.userRolesRights$)
    //   }
    // }
  }

  updateNavBarWithRoles(roles?:any){
    // let rolesValues
    // if(roles){
    //   rolesValues = roles
    // }else{
    //   rolesValues = this.settingsService.userRolesRights$
    // }

    // if(this.settingsService.currentUser$){
    //   if(rolesValues){
    //     if(rolesValues['contentTypes']){
    //       if(!rolesValues['contentTypes']['R']){
    //         this.contentTypes=false
    //         this.coverImages=false
    //       }else{
    //         this.contentTypes=true
    //         this.coverImages=true
    //       }
    //     }
        
    //     if(rolesValues['content']){
    //       if(!rolesValues['content']['R']){
    //         this.content=false
    //       }else{
    //         this.content=true
    //       }
    //     }

    //     if(rolesValues['context']){
    //       if(!rolesValues['context']['R']){
    //         this.context=false
    //       }else{
    //         this.context=true
    //       }
    //     }

    //     if(rolesValues['interfaces']){
    //       if(!rolesValues['interfaces']['R']){
    //         this.interfaces=false
    //       }else{
    //         this.interfaces=true
    //       }
    //     }
        
    //     if(rolesValues['products']){
    //       if(!rolesValues['products']['R']){
    //         this.products=false
    //       }else{
    //         this.products=true
    //       }
    //     }
        
    //     if(rolesValues['tridys']){
    //       if(!rolesValues['tridys']['R']){
    //         this.tridys=false
    //       }else{
    //         this.tridys=true
    //       }
    //     }

    //     if(rolesValues['reports']){
    //       if(!rolesValues['reports']['R']){
    //         this.reports=false
    //       }else{
    //         this.reports=true
    //       }
    //     }
        
    //     if(rolesValues['users']){
    //       if(!rolesValues['users']['R']){
    //         this.users=false
    //       }else{
    //         this.users=true
    //       }
    //     }
       
    //     if(rolesValues['workTask']){
    //       if(!rolesValues['workTask']['R']){
    //         this.workTasks=false
    //       }else{
    //         this.workTasks=true
    //       }
    //     }
        
    //     if(rolesValues['workTaskTemplates']){
    //       if(!rolesValues['workTaskTemplates']['R']){
    //         this.taskTemplates=false
    //       }else{
    //         this.taskTemplates=true
    //       }
    //     }

        
    //   }else{
    //     if(!userRolesRightsAll['contentTypes']['R']){
    //       this.contentTypes=false
    //       this.coverImages=false
    //     }else{
    //       this.contentTypes=true
    //       this.coverImages=true
    //     }

    //     if(!userRolesRightsAll['content']['R']){
    //       this.content=false
    //     }else{
    //       this.content=true
    //     }

    //     if(!userRolesRightsAll['context']['R']){
    //       this.context=false
    //     }else{
    //       this.context=true
    //     }

    //     if(!userRolesRightsAll['products']['R']){
    //       this.products=false
    //     }else{
    //       this.products=true
    //     }

    //     if(!userRolesRightsAll['tridys']['R']){
    //       this.tridys=false
    //     }else{
    //       this.tridys=true
    //     }

    //     if(!userRolesRightsAll['reports']['R']){
    //       this.reports=false
    //     }else{
    //       this.reports=true
    //     }

    //     if(!userRolesRightsAll['interfaces']['R']){
    //       this.interfaces=false
    //     }else{
    //       this.interfaces=true
    //     }

    //     if(!userRolesRightsAll['users']['R']){
    //       this.users=false
    //     }else{
    //       this.users=true
    //     }

    //     if(!userRolesRightsAll['workTask']['R']){
    //       this.workTasks=false
    //     }else{
    //       this.workTasks=true
    //     }

    //     if(!userRolesRightsAll['workTaskTemplates']['R']){
    //       this.taskTemplates=false
    //     }else{
    //       this.taskTemplates=true
    //     }
    //   }
    // }

  }

  activeAll(){
    this.context=true
    this.contentTypes=true
    this.content=true
    this.tridys=true
    this.coverImages=false
    this.products=true
    this.taskTemplates=true
    this.workTasks=true
    this.users=true
    this.reports=true
    this.interfaces=true
  }

  

}
