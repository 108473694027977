import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SettingsService } from '../service/settings.service';
import { deepCopy, removeUndefinedValuesFromObject } from '../../../../backend/utils/object';
import { ReportService } from '../service/report.service';
import { ReportAddFilterFieldDialogComponent } from '../report-add-filter-field-dialog/report-add-filter-field-dialog.component';
import { CommentsService } from '../service/comments.service';
import { TranslateService } from '@ngx-translate/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { TaskTemplate } from '../../../../backend/src/tasktemplate.dto';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';
import { isDate, isDateTime, isTime } from '../../../../backend/src/filter';

@Component({
  selector: 'app-report-create-dialog',
  templateUrl: './report-create-dialog.component.html',
  styleUrls: ['./report-create-dialog.component.css']
})
export class ReportCreateDialogComponent {
  reportForm!:FormGroup
  contentTypesList:any
  valuesReport:any
  allFilter:any=[]
  allFields:any=[]
  allFixedFilters:any=[]
  empty:boolean
  allKeysColumns:any=[]
  duplicatedKey = false
  duplicatedKeyValue = []
  workTaskTemplatesList = []

  typeOptions = [
    {
      name:"Text",
      type:"string",
      translation:"typeOptions.text"
    },
    {
      name:"Number",
      type:"number",
      translation:"typeOptions.number"

    },
    {
      name:"Decimal",
      type:"decimal",
      translation:"typeOptions.decimal"

    },
    {
      name:"Boolean",
      type:"boolean",
      translation:"typeOptions.boolean"

    },
    {
      name:"Date",
      type:"date",
      translation:"typeOptions.date"

    },
    {
      name:"Time",
      type:"time",
      translation:"typeOptions.time"

    },
    {
      name:"Date/Time",
      type:"dateTime",
      translation:"typeOptions.dateTime"

    },
    {
      name:"Media",
      type:"media",
      translation:"typeOptions.media"

    },
  ]

  typeValues = ['string', 'number', 'decimal', 'boolean', 'date', 'time', 'dateTime', 'media']

  typesFieldsWT: any = [
    {
      translation:'taskTemplates.add_display',
      type:'display',
      icon:'visibility'
    },
    {
      translation:'taskTemplates.add_hidden',
      type:'hidden',
      icon:'visibility_off'
    },
    {
      translation:'taskTemplates.add_text',
      type:'text',
      icon:'text_format'
    },
    {
      translation:'taskTemplates.add_longText',
      type:'longText',
      icon:'subject'
    },
    {
      translation:'taskTemplates.add_number',
      type:'number',
      icon:'pin'
    },
    {
      translation:'taskTemplates.add_decimal',
      type:'decimal',
      icon:'numbers'
    },
    {
      translation:'taskTemplates.add_currency',
      type:'currency',
      icon:'euro'
    },
    {
      translation:'taskTemplates.add_date',
      type:'dateTime',
      icon:'calendar_month'
    },
    {
      translation:'taskTemplates.add_switch',
      type:'boolean',
      icon:'toggle_on'
    },
    {
      translation:'taskTemplates.add_list',
      type:'options',
      icon:'list'
    },
    {
      translation:'taskTemplates.add_weight',
      type:'weight',
      icon:'weight'
    },
    {
      translation:'taskTemplates.add_tridy',
      type:'tridy',
      icon:'contactless'
    },
    {
      translation:'taskTemplates.add_barcode',
      type:'barcode',
      icon:'qr_code_scanner'
    },
    {
      translation:'taskTemplates.add_signature',
      type:'signature',
      icon:'signature'
    },
    {
      translation:'taskTemplates.add_doc',
      type:'document',
      icon:'attach_file'
    },
    {
      translation:'taskTemplates.add_nfc',
      type:'nfc',
      icon:'nfc'
    },


  ]

  constructor(
    public dialogRef: MatDialogRef<ReportCreateDialogComponent>,
    private fb: FormBuilder,
    private settingsService: SettingsService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any[],
    private reportSettings: ReportService,
    private commentsService: CommentsService,
    private translate:TranslateService
  ){
    dialogRef.disableClose = true;
  }

  ngOnInit(){
    this.contentTypesList = this.settingsService.settingsContentTypesWithId

    this.empty = true

    this.reportForm = this.fb.group({
      displayName:[, Validators.required],
      name:[],
      contentTypeId:[, Validators.required],
      filters: [, Validators.required],
      sortedBy:[],
      id:[],
      resultFields:[, Validators.required],
      fixedFilters:[]
    })

    this.reportForm.patchValue({
      displayName:  undefined,
      name:  undefined,
      contentTypeId:  undefined,
      filters: [],
      resultFields: [],
      sortedBy: undefined,
      fixedFilters:[]

    })

    if(this.data['type']=="edit" ){
      this.valuesReport = this.data['values']
      const {displayName, name, contentTypeId, filters, resultFields, id,sortedBy, fixedFilters} = this.valuesReport
      this.reportForm.patchValue({
        displayName: displayName ? displayName : undefined,
        name: name ? name : undefined,
        contentTypeId: contentTypeId ? contentTypeId : undefined,
        filters: filters ? filters : [],
        resultFields: resultFields ? resultFields : [],
        sortedBy: sortedBy ? sortedBy : undefined,
        id: id ? id : undefined,
        fixedFilters:fixedFilters ? fixedFilters : []

      })
      this.empty = false
    }

    this.reportForm.valueChanges.subscribe(value => {
      this.getKeysColumns()
    })

    this.reportForm.get('resultFields').valueChanges.subscribe(value => {
      this.getKeysColumns()
    })

    this.reportForm.get('fixedFilters').valueChanges.subscribe(fixedFilters => {
      if(fixedFilters){
        fixedFilters.forEach(filter => {
          if(filter && filter.type=='date' && filter.operator != 'inrange'){
            filter.value = this.isDatePrefilter(filter.value)
          }
        });
      }
    })

    this.getKeysColumns()

    this.getWorkTasksList()
  }


  getWorkTasksList() {
    if(this.settingsService.settingsWorkTaskTemplates){
      this.workTaskTemplatesList = this.settingsService.settingsWorkTaskTemplates
    }

    try{
      this.commentsService.progressSpin.emit(true)
      this.workTaskTemplatesList = this.settingsService.observeContextsWorkTasksTemplatesFirestoreWithId()
      this.commentsService.progressSpin.emit(false)

    }catch(error){
      this.commentsService.progressSpin.emit(false)
      const message = this.translate.instant("SnackBarConstants.LOAD_FAILED")
      this.commentsService.addSnackBar.emit(message)
    }
  }

  getKeysColumns(){
    const columns = deepCopy(this.reportForm.value.resultFields)
    this.allKeysColumns = []

    if(columns){
      columns.forEach( column => {
        if(column.key){
          this.allKeysColumns.push(column.key)
        }
      })
    }

    this.checkDuplicatesColumnsKeys()
  }

  checkDuplicatesColumnsKeys(){
    let unique = []
    let duplicated = []
    let duplicatedKeys = false

    if(this.allKeysColumns){
      this.allKeysColumns.forEach(key => {
        if(unique.includes(key)){
          duplicated.push(key)
          duplicatedKeys = true
        }else{
          unique.push(key)
        }
      });
    }

    return duplicatedKeys
  }

  checkDuplicatesColumnsKeysNew(newKey){
    const columns = this.reportForm.value.resultFields
    let duplicatedKeys = []
    this.duplicatedKeyValue = []

    if(columns){
      if(this.allKeysColumns.includes(newKey)){
        duplicatedKeys.push(newKey)
        this.duplicatedKey = true
        this.duplicatedKeyValue.push(newKey)
      }else{
        this.duplicatedKey = false
      }
    }
  }

  onCancel(){
    this.dialogRef.close([this.reportForm.value, false])
  }

  dropResultFields(event: CdkDragDrop<string[]>) { 
     moveItemInArray(this.reportForm.value.resultFields, event.previousIndex, event.currentIndex);
     //this.updateFormValue()
  }
  dropFixedFilters(event: CdkDragDrop<string[]>) { 
    moveItemInArray(this.reportForm.value.fixedFilters, event.previousIndex, event.currentIndex);
    //this.updateFormValue()
 }

  onSave(){
    const a = this.checkDuplicatesColumnsKeys()

    if(!a){
      //add filters based on columns
      const columns = this.reportForm.value.resultFields
      let filters = []
      // let filters = this.reportForm.value.filters
      if(columns){
        columns.forEach( column => {
          const inFilter = filters.find( fil => fil.key == column.key)
          // const inFilter = this.reportForm.value.filters.find( fil => fil.key == column.key)
          if(!inFilter){
            const field = deepCopy(column)
            // field.description = ''
            field.operator = '='
            filters.push(field)
          }
        })
      }
      this.reportForm.patchValue({filters: filters})

      if(this.data['type']=="create"){
        if(this.reportForm.value.contentTypeId && this.reportForm.value.resultFields.length!=0){
          try{
            this.commentsService.progressSpin.emit(true)
            const data = removeUndefinedValuesFromObject(this.reportForm.value)
            const newreport = this.reportSettings.createReport(data, this.settingsService.contextId$)
            if(newreport){
              this.dialogRef.close([data,true])
              this.commentsService.progressSpin.emit(false)
            }
    
          }catch(error){
            console.log(error)
          }
        }else{
          const message = this.translate.instant("SnackBarConstants.MANDATORY_FIELDS_EMPTY")
          this.commentsService.addSnackBar.emit(message)
        }
      }
  
      if(this.data['type']=="edit"){
        if(this.reportForm.value.contentTypeId && this.reportForm.value.resultFields.length!=0){
          try{
            this.commentsService.progressSpin.emit(true)
            const data =removeUndefinedValuesFromObject(this.reportForm.value)
            const newreport = this.reportSettings.updateReport(data)
            if(newreport){
              this.dialogRef.close([data,true])
              this.commentsService.progressSpin.emit(false)
            }
    
          }catch(error){
            console.log(error)
          }
        }else{
          const message = this.translate.instant("SnackBarConstants.MANDATORY_FIELDS_EMPTY")
          this.commentsService.addSnackBar.emit(message)
        }
      }
    }else{
      const message = this.translate.instant('reports.column_keys')
      this.commentsService.addSnackBar.emit(message)
    }

  
  }

  addFilter(){
    let dialogRef = this.dialog.open(ReportAddFilterFieldDialogComponent, {
      data: { 
        values:{},
        type: 'add_filter',
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result[1]){
        if(result[2]=='filter'){
          let filters = this.reportForm.value.filters
          filters.push(result[0])
          this.reportForm.patchValue({...filters})
        }
      }
    });
  }

  editFilter(element,index){
    let dialogRef = this.dialog.open(ReportAddFilterFieldDialogComponent, {
      data: { 
        values:{data:element},
        type: 'edit_filter',
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result[1]){
        if(result[2]=='edit_filter'){
          let filters = this.reportForm.value.filters
          filters[index]=result[0]
          this.reportForm.patchValue({filters:filters})
        }
      }
    });
  }

  deleteFilter(index){
    let filters =[]
    const filtersForm = this.reportForm.value.filters
    filtersForm.forEach((filter, ind) => {
      if(index!=ind){
        filters.push(filter)
      }
    })

    this.reportForm.patchValue({filters: filters})
  }

  addField(){ // column add
    let dialogRef = this.dialog.open(ReportAddFilterFieldDialogComponent, {
      data: { 
        values:{},
        type: 'add_field',
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result[1]){
        if(result[2]=='result_field'){
          let resultFields = deepCopy(this.reportForm.value.resultFields)
          resultFields.push(result[0])
          const key = this.checkDuplicatesColumnsKeysNew(result[0].key)
          if(!this.duplicatedKey){
             this.reportForm.patchValue({resultFields:resultFields})
          // this.reportForm.patchValue({...resultFields})
          }else{
            const message = this.translate.instant('SnackBarConstants.REPORT_KEY_DUPLICATED')
            this.commentsService.addSnackBar.emit(message)
          }
        }
      }

      
    });
  }

  editResultField(element, index){//columns edit
    let dialogRef = this.dialog.open(ReportAddFilterFieldDialogComponent, {
      data: { 
        values:{data:element},
        type: 'edit_field',
      },
    });

    dialogRef.afterClosed().subscribe(result => {

      if(result[1]){
        if(result[2]=='edit_result_field'){
          let resultFields = this.reportForm.value.resultFields
          resultFields[index]=result[0]
          this.reportForm.patchValue({resultFields: resultFields})
        }
      }

      
    });
  }

  deleteResultField(index){///columns delete
    let resultFields =[]
    const resultFieldsForm = this.reportForm.value.resultFields
    resultFieldsForm.forEach((filter, ind) => {
      if(index!=ind){
        resultFields.push(filter)
      }
    })

    this.reportForm.patchValue({resultFields: resultFields})
  }

  editFixedFilter(element, index){
    let dialogRef = this.dialog.open(ReportAddFilterFieldDialogComponent, {
      data: { 
        values:{data:element},
        type: 'edit_fixedFilter',
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result[1]){
        if(result[2]=='edit_fixed_filters'){
          let fixedFilters = this.reportForm.value.fixedFilters
          fixedFilters[index] = result[0]
          this.reportForm.patchValue({fixedFilters:fixedFilters})
        }
      }

      
    });
  }

  addFixedFilter(){
    let dialogRef = this.dialog.open(ReportAddFilterFieldDialogComponent, {
      data: { 
        values:{},
        type: 'add_fixedFilter',
      },
    });

    dialogRef.afterClosed().subscribe(result => {

      if(result[1]){
        if(result[2]=='fixed_filters'){
          let fixedFilters = this.reportForm.value.fixedFilters
          fixedFilters.push(result[0])
          this.reportForm.patchValue({...fixedFilters})
        }
      }

      
    });
  }

  deleteFixedFilter(index){
    let fixedFilters =[]
    const fixedFiltersForm = this.reportForm.value.fixedFilters
    fixedFiltersForm.forEach((filter, ind) => {
      if(index!=ind){
        fixedFilters.push(filter)
      }
    })

    this.reportForm.patchValue({fixedFilters: fixedFilters})
  }

  importColumnFromWorkTask(wt, id){
    let newColumns = []
    let fieldNumber = -1
    let existingFields = false
    if(wt){
      this.commentsService.progressSpin.emit(true)
      const workTask = new TaskTemplate()
      workTask.setFromAny(wt)

      if(workTask.formSections){

          if(workTask.formSections?.length!=0){
            const sections = workTask.formSections

            sections.forEach( (section, indexSection) => {
              if(section['fields']){
                const fields = section['fields']
                fields?.forEach((field, indexField) => {
                  existingFields = true
                  fieldNumber++
                  let {key, payloadKey,type, title} = field
                  if(!key)
                      key = 'field' +fieldNumber//+ indexSection + indexField
                  if(!title)
                    title = 'field' +fieldNumber//+ indexSection + indexField

                  let newfield = {
                    key:payloadKey ? payloadKey : key,type,
                    displayName: title
                  }

                  if(this.typeValues.includes(type)){
                    if(type == 'dateTime'){
                      // type = field.style
                      newfield.type = field.style
                    }
                    newColumns.push(newfield)
                  }else{
                    if(type == 'text' || type == 'currency' || type == 'options' || type == 'longText'){
                      type = 'string'  
                      newfield.type = type
                      newColumns.push(newfield)
                    }

                    if(type == 'weight'){
                      type = 'number'  
                      newfield.type = type
                      newfield.key += '.value'
                      newColumns.push(newfield)
                    }

                    if(type == 'signature' || type == 'document'){
                      type = 'media'  
                      newfield.type = type
                      newColumns.push(newfield)
                    }
                  }
                });
              }
            })
          }
      }

      if(existingFields){
        this.commentsService.progressSpin.emit(false)

        let dialogRef = this.dialog.open(ErrorDialogComponent, {
          data: { 
             message: 'reports.overwrite_keys_after_import',
             cancelBtn: true
          },
        });
    
        dialogRef.afterClosed().subscribe(result => {
          if(result[0]){
            this.commentsService.progressSpin.emit(true)
            this.reportForm.patchValue({resultFields: newColumns})
            this.commentsService.progressSpin.emit(false)
          }else{
            const message = this.translate.instant("SnackBarConstants.IMPORT_columns_CANCEL")
            this.commentsService.progressSpin.emit(false)
          }
        });
      }else{
        const message = this.translate.instant('reports.worktask_empty_fields')
        this.commentsService.addSnackBar.emit(message)
        this.commentsService.progressSpin.emit(false)

      }

    }
  }

  isDate(element, operator){
    if(operator=='inrange'){
      const rangeRegex = /^(\[|\()(\-?\d+(\.\d+)?),(\-?\d+(\.\d+)?)(\]|\))$/
      const match = rangeRegex.exec(element)
      if (match) {
        const startValue = parseFloat(match[2])
        const endValue = parseFloat(match[4])
        return '['+isDate(startValue)+ ', '+ isDate(endValue) + ']'
      }
    }else
    return isDate(element)
  }

  isTime(element){
    return isTime(element)
  }

  isDateTime(element, operator){
    if(operator=='inrange'){
      const rangeRegex = /^(\[|\()(\-?\d+(\.\d+)?),(\-?\d+(\.\d+)?)(\]|\))$/
      const match = rangeRegex.exec(element)
      if (match) {
        const startValue = parseFloat(match[2])
        const endValue = parseFloat(match[4])
        return '['+isDateTime(startValue)+ ', '+ isDateTime(endValue) + ']'
      }
    }else
    return isDateTime(element)
  }

  isDatePrefilter(element){
      let dateValue
      dateValue = element
  
      if(element){
        if(element['_nanoseconds'] || element['_seconds']){
          const NS_TO_MS_MULTIPLIER = 1/1000000
          const SEC_TO_MS_MULTIPLIER = 1000
          const timestampInMilliseconds = element['_seconds'] * SEC_TO_MS_MULTIPLIER + element['_nanoseconds'] * NS_TO_MS_MULTIPLIER
          const date = new Date(timestampInMilliseconds)
          element= date
          // dateValue = date.toLocaleDateString('de-DE',{year: 'numeric', month: '2-digit', day:'2-digit'})
        }
  
        if(element['nanoseconds'] || element['seconds']){
          const NS_TO_MS_MULTIPLIER = 1/1000000
          const SEC_TO_MS_MULTIPLIER = 1000
          const timestampInMilliseconds = element['seconds'] * SEC_TO_MS_MULTIPLIER + element['nanoseconds'] * NS_TO_MS_MULTIPLIER
          const date = new Date(timestampInMilliseconds)
          element= date
          // dateValue = date.toLocaleDateString('de-DE',{year: 'numeric', month: '2-digit', day:'2-digit'})
        }
  
        try{
          const newdate = new Date(element)
          if(newdate)
              dateValue = newdate
              let year = newdate.getFullYear();
              let month = newdate.getMonth() + 1; 
              let day = newdate.getDate() ;
              let newmonth = month < 10 ? '0' + month : month;
              let newday = day < 10 ? '0' + day : day;
              let dateString = `${year}-${newmonth}-${newday}`;

              dateValue = dateString
              // dateValue = newdate.toLocaleDateString('de-DE',{year: 'numeric', month: '2-digit', day:'2-digit'})
        }catch(errro){}
      }
     return dateValue
    }

}
