import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { VideoEditComponent } from '../editWidgets/video-edit/video-edit.component';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { checkDynamicContent, supportUrlStructure } from '../../../../../backend/utils/object';
import { SettingsService } from 'src/app/service/settings.service';

@Component({
  selector: '[app-video-phone]',
  templateUrl: './video-phone.component.html',
  styleUrls: ['./video-phone.component.css']
})

export class VideoPhoneComponent implements OnInit {
  @Input('app-video-phone') data:any
  @Output() returnChanges = new EventEmitter<{newdata: any, widget: any, widgetType:any, images:any}>()

  videoData:any
  editMode:any
  errorVideo:any
  url:any
  widgetNumber:any
  videoToStorage:any=[]
  routerUrl:any
  idToShow:any
  noUrl=false
  urlData:any
  dynamic= false
  darkModeActive= false
  titleColor = "var(--title)"
  subtitleColor = "var(--subtitle)"

  constructor(public dialog: MatDialog,
    private router: ActivatedRoute,
    private settingsService:SettingsService,
    private renderer: Renderer2,
    private element: ElementRef
    ){
      this.routerUrl=this.router.url
      this.idToShow=this.routerUrl.value[this.routerUrl.value.length-1].path  
  }

  ngOnInit(): void{
    this.videoData=this.data[0]
    this.editMode=this.data[1]
    if(this.editMode){
      this.widgetNumber = this.data[2]
    }
    if(this.data[5]){
      this.darkModeActive = this.data[5]
      // this.setWidgetStyle(this.videoData)  
    }

    if(!this.videoData.url){
      this.noUrl=true
    }

    if(this.videoData.url){
      try{
        const partsd = this.videoData.url.includes("${")
        if(partsd){
          //this.videoData.url = checkDynamicContent(this.videoData.url)
          this.dynamic = true
        }
        let url = supportUrlStructure( this.videoData.url, this.idToShow, this.settingsService.contextId$)
        if(url)
        this.urlData=url
        // const imgUrl = new URL(this.videoData.url)
        // let hostname = environment.urlHostName
        // if(imgUrl.protocol=="pass:" ){
        //   const parts = this.videoData.url.match(/pass:\/\/(.+)\/(.+)/)
        //   if(parts){
        //     const passTemplateID = parts[1]
        //     const path = parts[2]
        //     const firebaseUrl = `https://${hostname}/v1/pass/${passTemplateID}/${path}`
        //     this.urlData=firebaseUrl
        //   }else{
        //     const newparts = this.videoData.url.match(/pass:\/\/(.+)/)
        //     if(newparts){
        //       let firebaseUrl = `https://${hostname}/v1/pass/${this.idToShow}`
        //       newparts.forEach( (part,index)=> {
        //         if(index>0){
        //           firebaseUrl += '/' + part 
        //         }
        //       })
        //       this.urlData=firebaseUrl
        //     }
        //   }
        // }
        // if(imgUrl.protocol =="tridy:"){
        //   const parts = this.videoData.url.match(/tridy:\/\/(.+)\/(.+)/)
        //   let path =""
        //   parts.forEach((parte, index) =>{
        //     if(index>0 && index<parts.length){
        //       path += "/" + parte
        //     }
        //   })
        //   const firebaseUrl = `https://${hostname}/v1/pass${path}`
        //   this.urlData=firebaseUrl
        // }
      }catch(error){}
    }

/*     if(this.videoData.title)
      this.videoData.title = checkDynamicContent(this.videoData.title)

    if(this.videoData.subtitle)
      this.videoData.subtitle = checkDynamicContent(this.videoData.subtitle) */
  }

  ngOnChanges(){
    this.videoData=this.data[0]
    this.editMode=this.data[1]
    if(this.editMode){
      this.widgetNumber = this.data[2]
    }
    this.darkModeActive = this.data[5]
    
    setTimeout(() => {
      this.setWidgetStyle(this.videoData)     
    }, 200);
  }

  openDialogEdit():void{
    let dialogRef = this.dialog.open(VideoEditComponent, {
      data:{
        values: this.videoData,
        widget: this.widgetNumber,
        passId: this.idToShow,
        url: this.urlData
      },
    });

    dialogRef.afterClosed().subscribe(result => {
     
      if(result[1]){
        this.videoData=result[0]
        this.url=result[0].url
        if(result[2].length!=0){
          this.videoToStorage.push(result[2])
        }
      this.returnChanges.emit({newdata: this.videoData, widget: this.widgetNumber, widgetType: this.videoData.type,images: this.videoToStorage})

      }
    })
  }

  setWidgetStyle(data){
    const elementId = 'phoneSection-'+data.id
    const element = this.element.nativeElement.querySelector('#'+elementId);
    if(data?.widgetStyle){
      if(this.darkModeActive){
        if( data.widgetStyle.dark){
          if(data.widgetStyle.dark.backgroundColor){
            document.getElementById(elementId).style.backgroundColor = data.widgetStyle.dark.backgroundColor
          }else{
            if(document.getElementById(elementId).style && document.getElementById(elementId).style.backgroundColor)
            this.renderer.removeStyle(element, 'backgroundColor');
          }
          if( data.widgetStyle.dark.foregroundColor){
            if(data.title)
              this.titleColor = data.widgetStyle.dark.foregroundColor
            if(data.subtitle)
              this.subtitleColor = data.widgetStyle.dark.foregroundColor
            document.getElementById(elementId).style.color = data.widgetStyle.dark.foregroundColor
          }else{
            if(document.getElementById(elementId).style && document.getElementById(elementId).style.color)
            this.renderer.removeStyle(element, 'color');
            if(data.title)
              this.titleColor = "var(--title)"
            if(data.subtitle)
              this.subtitleColor = "var(--subtitle)"
          }
        }else{
          if(document.getElementById(elementId).style && document.getElementById(elementId).style.color)
            this.renderer.removeStyle(element, 'color');
          if(document.getElementById(elementId).style && document.getElementById(elementId).style.backgroundColor)
            this.renderer.removeStyle(element, 'backgroundColor');
            if(data.title)
              this.titleColor = "var(--title)"
            if(data.subtitle)
              this.subtitleColor = "var(--subtitle)"
        }
      }else{
        if( data.widgetStyle.light){
          if( data.widgetStyle.light.backgroundColor){
            document.getElementById(elementId).style.backgroundColor = data.widgetStyle.light.backgroundColor
          }else{
            if(document.getElementById(elementId).style && document.getElementById(elementId).style.backgroundColor)
            this.renderer.removeStyle(element, 'backgroundColor');
          }

          if( data.widgetStyle.light.foregroundColor){
            document.getElementById(elementId).style.color = data.widgetStyle.light.foregroundColor
            if(data.title)
              this.titleColor = data.widgetStyle.light.foregroundColor
            if(data.subtitle)
              this.subtitleColor = data.widgetStyle.light.foregroundColor
          }else{
            if(document.getElementById(elementId).style && document.getElementById(elementId).style.color)
            this.renderer.removeStyle(element, 'color');
            if(data.title)
              this.titleColor = "var(--title)"
            if(data.subtitle)
              this.subtitleColor = "var(--subtitle)"
          }
        }else{
          if(document.getElementById(elementId).style && document.getElementById(elementId).style.backgroundColor)
            this.renderer.removeStyle(element, 'backgroundColor');
          if(document.getElementById(elementId).style && document.getElementById(elementId).style.color)
            this.renderer.removeStyle(element, 'color');

            if(data.title)
              this.titleColor = "var(--title)"
            if(data.subtitle)
              this.subtitleColor = "var(--subtitle)"
        }
      }

      if(data.widgetStyle.contentAlignment){
        if(data.widgetStyle.contentAlignment=='leading')
          document.getElementById(elementId).style.textAlign = 'left'

        if(data.widgetStyle.contentAlignment=='center')
          document.getElementById(elementId).style.textAlign = 'center'

        if(data.widgetStyle.contentAlignment=='trailing')
          document.getElementById(elementId).style.textAlign = 'end'

      }else{
        if(document.getElementById(elementId)?.style && document.getElementById(elementId)?.style.textAlign)
          this.renderer.removeStyle(element, 'textAlign');
      }
    }
  }

}
