
<nav class="navbar navbar-expand-md d-flex flex-nowrap align-items-start justify-content-between navbar-light bg-white p-3 mb-0">

  <div style="height: 100%; padding:10px;">
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarTogglerDemo03">

      <a class="navbar-brand p-0" (click)="openRoute('home');">
        <img src="./../../assets/img/narravero-logo.svg"
            height="40"
            alt="Narravero Logo"
            loading="lazy"
            id="logo"/>
      </a>

      <ul class="navbar-nav me-auto mt-3 mt-md-auto custom-mat-nav">

        <li *ngIf="contentTypes">
          <button mat-button [class.active-link]="isRouteActive('contentTypes')" (click)="openRoute('contentTypes')">
            {{'topNavbar.contentTypes' | translate}}
          </button>
        </li>
      
        <li *ngIf="workTasks || taskTemplates">
          <button mat-button [matMenuTriggerFor]="tracerMenu" [class.active-link]="isRouteActive('taskTemplates') || isRouteActive('taskTemplatesGroups')" style="flex-direction: row-reverse;">
            {{ 'topNavbar.tracer' | translate }} <mat-icon>expand_more</mat-icon>
          </button>
          <mat-menu #tracerMenu="matMenu">
            <button mat-menu-item *ngIf="workTasks" [class.active-link]="isRouteActive('taskTemplates')" (click)="openRoute('taskTemplates')">
              {{'topNavbar.taskTemplates' | translate}}
            </button>
            <button mat-menu-item *ngIf="workTasks" [class.active-link]="isRouteActive('taskTemplatesGroups')" (click)="openRoute('taskTemplatesGroups')">
              {{'topNavbar.taskTemplatesGroups' | translate}}
            </button>
          </mat-menu>
        </li>
      
        <li *ngIf="content">
          <button mat-button [class.active-link]="isRouteActive('content')" (click)="openRoute('content')">
            {{'topNavbar.content' | translate}}
          </button>
        </li>
      
        <li *ngIf="reports">
          <button mat-button [matMenuTriggerFor]="reportsMenu" [class.active-link]="isRouteActive('reports') || isRouteActive('finishedTasks')" style="flex-direction: row-reverse;">
            {{'topNavbar.reports' | translate}} <mat-icon>expand_more</mat-icon>
          </button>
          <mat-menu #reportsMenu="matMenu">
            <button mat-menu-item *ngIf="reports" [class.active-link]="isRouteActive('reports')" (click)="openRoute('reports')">
              {{'topNavbar.allReports' | translate}}
            </button>

            <button mat-menu-item *ngIf="tridys" [class.active-link]="isRouteActive('tridys')" (click)="openRoute('tridys')">
              {{'topNavbar.tridys' | translate}}
            </button>

            <button mat-menu-item *ngIf="taskTemplates" [class.active-link]="isRouteActive('finishedTasks')" (click)="openRoute('finishedTasks')">
              {{'topNavbar.finishedTasks' | translate}}
            </button>
          </mat-menu>
        </li>
      
        <li *ngIf="taskTemplates && !reports">
          <button mat-button [class.active-link]="isRouteActive('finishedTasks')" (click)="openRoute('finishedTasks')">
            {{'topNavbar.finishedTasks' | translate}}
          </button>
        </li>
      
      </ul>

    </div>
  </div>

  <div class="navbar-brand">
    <button mat-icon-button [matMenuTriggerFor]="language" style="color:var(--warmOrange); margin-right:5px;">
      <span class="fi fi-{{languageFlag}} menu-image"></span>
      <span class="material-symbols-outlined">
        arrow_drop_down
      </span>
    </button>

    <mat-menu #language="matMenu">
      <button mat-menu-item (click)="useLanguage('en')"><span class="fi fi-gb dd-menu-image"></span>  English </button>
      <button mat-menu-item (click)="useLanguage('de')"><span class="fi fi-de dd-menu-image"></span>  Deutsch </button>
      <button mat-menu-item (click)="useLanguage('es')"><span class="fi fi-es dd-menu-image"></span>  Español </button>
    </mat-menu>

    <button mat-icon-button [matMenuTriggerFor]="account" style="color:var(--warmOrange);">
      <mat-icon>account_circle</mat-icon>
      <span class="material-symbols-outlined">arrow_drop_down</span>
    </button>

    <mat-menu #account="matMenu" class="account-menu">

      <div mat-menu-item disable class="mt-0 gradient-bg" style="pointer-events: none;">
        <mat-icon style="color:white!important;">account_circle</mat-icon> <span style="color:white!important;">{{userName}}</span>
      </div>

      <button mat-menu-item (click)="openDialogSettings();">
        <mat-icon >contacts</mat-icon> {{'account' | translate}}
      </button>

      <hr class="m-0 opacity-100" style="color:#E7E7E7;">
      <button *ngIf="context" mat-menu-item (click)="openLanguagesDialog();">
        <mat-icon>language</mat-icon> {{'account_languages' | translate}}
      </button>
      <button *ngIf="users" mat-menu-item (click)="openRoute('users');">
        <mat-icon>manage_accounts</mat-icon> {{'topNavbar.users' | translate}}
      </button>
      <button *ngIf="context" (click)="openRoute('cdSettings')" mat-menu-item> 
        <mat-icon>design_services</mat-icon> {{'topNavbar.cdSettings' | translate}}
      </button>
      <button mat-menu-item *ngIf="interfaces" (click)="openRoute('interfaces');">
        <mat-icon>api</mat-icon> {{'topNavbar.interfaces' | translate}}
      </button>

      <button mat-menu-item (click)="openPlaceholdersDialog();">
        <mat-icon>data_object</mat-icon> {{'topNavbar.placeholderList' | translate}}
      </button>
      <hr class="m-0 opacity-100" style="color:#E7E7E7;">
      <button mat-menu-item (click)="signOutUser();">
        <mat-icon>logout</mat-icon> {{'logout' | translate}}</button>
    </mat-menu>

  </div>
</nav>