<section class="container">
    <div class="row mt-4">
        <div class="mt-3">
            <h1 class="mb-1">Corporate Design</h1>
            <p style="font-family:'MyFont-Light';">{{'headlines.subCdSettings' | translate}}</p>
        </div>
    </div>

    <div class="row mt-4">
        <div class="col-xs-4 col-md-4">
            <div class="d-flex mobile-wrapper justify-content-start align-self-center">
                <div class="mobile">
                    <h2 class="text-center">{{'content.preview' | translate}}</h2>
                    <div class="mobile-frame-container"></div>
                    <div class="mobile-frame" [style.background-color]="darkMode ? cdSettingsForm.value.darkBackground : cdSettingsForm.value.lightBackground">
                        <div>
                            <div class="p-2">
                                <div class="image" [ngStyle]="{
                                    'margin-bottom.px': cdSettingsForm.value.widgetSpacing,
                                    'border-radius.px': cdSettingsForm.value.cornerRadius,}"
                                >
                                    <img src="./../../assets/img/default.jpg" 
                                        alt="..."
                                        [ngStyle]="{
                                            'width.%': 100,
                                            'height.%': 100,
                                            'border-radius.px': cdSettingsForm.value.cornerRadius
                                        }"
                                    />
                                </div>

                                <div class="text" style="padding-bottom: var(--widgetSpacing);">
                                    <div class="text-wrap py-2" 
                                        [style.font-family]="cdSettingsForm.value.titleFont ? 'titleFont' : 'default'" 
                                        [style.color]="darkMode ? cdSettingsForm.value.darkTitle : cdSettingsForm.value.lightTitle" 
                                        style="font-size: 18pt; word-wrap: break-word;">
                                        {{'pass.title' | translate}}
                                    </div>
                                    <div class="subtitleText text-break text-wrap pb-2" 
                                        [style.font-family]="cdSettingsForm.value.subtitleFont ? 'subtitleFont' : 'default'" 
                                        [style.color]="darkMode ? cdSettingsForm.value.darkSubtitle : cdSettingsForm.value.lightSubtitle" 
                                        style="word-wrap: break-word;">
                                        {{'pass.subtitle' | translate}}
                                    </div>
                                    <div class="textPrimaryPre" 
                                        [style.font-family]="cdSettingsForm.value.plainFont ? 'plainFont' : 'default'"
                                        [style.color]="darkMode ? cdSettingsForm.value.darkPrimary : cdSettingsForm.value.lightPrimary"
                                        style="word-wrap: break-word;">
                                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, eirmod tempor invidunt ut <em [style.font-family]="cdSettingsForm.value.italicFont ? 'italicFont' : 'default'">labore et dolore magna aliquyam erat</em>, sed diam voluptua.
                                            <strong [style.font-family]="cdSettingsForm.value.boldFont ? 'boldFont' : 'default'">At vero eos et accusam et justo duo</strong> dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum.<br/>
                                            <a [style.color]="darkMode ? cdSettingsForm.value.darkAccent : cdSettingsForm.value.lightAccent" style="text-decoration:none;" href="#"><strong [style.font-family]="cdSettingsForm.value.boldFont ? 'boldFont' : 'default'">Link</strong></a>
                                    </div>
                                </div>
<!--                                 <div [ngStyle]="{
                                    'margin-bottom.px': cdSettingsForm.value.widgetSpacing,
                                    'border-radius.px': cdSettingsForm.value.cornerRadius,
                                    'min-height.px': 30,
                                    'background-color': darkMode ? cdSettingsForm.value.darkAccent : cdSettingsForm.value.lightAccent
                                  }" class="button d-flex my-3 w-100 justify-content-center align-items-center">
                                  <span style="font-size: 12px;color:#fff">Example Button</span>
                                </div> -->
                                
                            </div> 

                        </div>
                    </div> 
                </div>
            </div>
        </div>
        <div class="col-xs-8 col-md-8">
            <div class="d-flex flex-row-reverse bd-highlight mb-4 justify-content-between align-items-center">

                <div *ngIf="showSpinner" class="overlay">
                    <div class="overlay__inner">
                        <div class="overlay__content">
                        <mat-spinner color="accent"></mat-spinner>
                        </div>
                    </div>
                </div>

                <mat-card *ngIf="formLoaded" class="cd-window w-100">
                    <form [formGroup]="cdSettingsForm">
                        <mat-card-header class="p-0">
                            <mat-tab-group mat-stretch-tabs class="w-100" animationDuration="300ms">

                                <!-- //////////  LOGO SETTINGS  ///////////////// -->
                                <mat-tab>
                                    <ng-template mat-tab-label>
                                        <mat-icon class="material-symbols-outlined margin-right">branding_watermark</mat-icon>  Logo
                                    </ng-template>
                                    <mat-card-content class="p-4">
                                        <div *ngIf="cdSettingsForm.value.imageUrl" class="mt-2">
                                            <div class="custom-form-field mb-4">
                                                <label class="custom-label">{{'content.preview' | translate}}</label>
                                                <div class="custom-input">
                                                    <img [src]="getImageUrl(cdSettingsForm.value.imageUrl)" alt="Image Preview" style="max-width: 100%; max-height: 250px;">
                                                    <button mat-icon-button class="delete-img delete-button" (click)="deleteImage(cdSettingsForm.value.imageUrl)">
                                                        <mat-icon class="material-symbols-outlined">delete</mat-icon>
                                                    </button>
                                                </div>
                                            </div>
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>{{'pass.mediaURL' | translate}}</mat-label>
                                                <input type="text" formControlName="imageUrl" [value]="cdSettingsForm.value.imageUrl" matInput>
                                            </mat-form-field>
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>{{'pass.content_description' | translate}}</mat-label>
                                                <input type="text" formControlName="imageDescription" [value]="cdSettingsForm.value.imageDescription" matInput>
                                            </mat-form-field>
                                        </div>
                                        <div *ngIf="!cdSettingsForm.value.imageUrl" appDragAndDrop (files)="imageDropped($event, 'title')">
                                            <mat-card class="upload-section text-center justify-content-center">
                                                <div style="margin:0 auto; height:100%;" class="d-flex justify-content-end flex-column">
                                                    <div class="py-2 mb-5">
                                                        <mat-icon class="material-symbols-outlined">add_photo_alternate</mat-icon>

                                                        <h2>
                                                            {{ 'pass.fileUploadMessage' | translate }}
                                                            <button type="button" class="labelFonts" (click)="triggerImageFileInput()">
                                                                <strong style="font-family: 'MyFont-Bold', sans-serif;">{{ 'pass.browse' | translate }}</strong>
                                                            </button>
                                                          <input type="file" id="myfile-pick-image" name="myfile-pick-image" style="display: none;" accept=".jpeg, .jpg, .png" (change)="readImageURL($event)">
                                                        </h2>
 
                                                    </div>
                                                    <p class="py-2 mt-5">{{'pass.supportedFiles' | translate}}: .jpg, .jpeg, .png  |  Max 10 MB</p>
                                                </div>
                                                
                                            </mat-card>
                                        </div>

                                    </mat-card-content>
                                </mat-tab>

                                <!-- //////////  COLOR SETTINGS  ///////////////// -->
                                <mat-tab>
                                    <ng-template mat-tab-label>
                                        <mat-icon class="material-symbols-outlined margin-right">palette</mat-icon>  {{'pass.colors' | translate}}
                                    </ng-template>
                                    <mat-card-content class="p-4">
                                        <mat-button-toggle-group name="darkModeToggle" class="w-100 justify-content-evenly"  formControlName="darkMode">
                                            <mat-button-toggle class="w-100" matTooltip="Light-Mode" [value]="false" (click)="changeTheme(false)">
                                                <mat-icon [class.material-symbols-outlined]="darkMode">light_mode</mat-icon>
                                                {{'pass.lightTheme' | translate}}
                                            </mat-button-toggle>
                                            <mat-button-toggle class="w-100" matTooltip="Dark-Mode" [value]="true" (click)="changeTheme(true)">
                                                <mat-icon  [class.material-symbols-outlined]="!darkMode">dark_mode</mat-icon>
                                                {{'pass.darkTheme' | translate}}
                                            </mat-button-toggle>
                                        </mat-button-toggle-group>
                                        <mat-list class="mt-2">
                                            <mat-list-item role="listitem">
                                                <div class="flex-between-center">
                                                    <div>{{'pass.background' | translate}}</div>
                                                    <div class="flex-between-center">
                                                        <span class="px-3" *ngIf="!darkMode && !invalid_lightBackground">{{cdSettingsForm.value.lightBackground}}</span>
                                                        <span *ngIf="!darkMode && invalid_lightBackground" id="invalid_lightBackground" style="color:red;">{{'pass.invalid_color' | translate}}</span> 
                                                        <ngx-colors ngx-colors-trigger [palette]="colorPalette" [hideTextInput]="false" [format]="'hex'" cancelLabel="{{'pass.colorPickerCancel' | translate}}" acceptLabel="{{'pass.colorPickerConfirm' | translate}}" formControlName="lightBackground" style="margin-left:10px;" *ngIf="!darkMode"></ngx-colors>
                                                        
                                                        <span class="px-3" *ngIf="darkMode && !invalid_darkBackground">{{cdSettingsForm.value.darkBackground}}</span>
                                                        <span *ngIf="darkMode && invalid_darkBackground" id="invalid_darkBackground" style="color:red;">{{'pass.invalid_color' | translate}}</span> 
                                                        <ngx-colors ngx-colors-trigger [palette]="colorPalette" [hideTextInput]="false" [format]="'hex'" cancelLabel="{{'pass.colorPickerCancel' | translate}}" acceptLabel="{{'pass.colorPickerConfirm' | translate}}" formControlName="darkBackground" style="margin-left:10px;" *ngIf="darkMode"></ngx-colors>
                                                        
                                                    </div>
                                                </div>
                                            </mat-list-item>
                                            <mat-divider></mat-divider>
                                            <mat-list-item role="listitem">
                                                <div class="flex-between-center">
                                                    <div>{{'pass.title' | translate}}</div>
                                                    <div class="flex-between-center">
                                                        <span class="px-3" *ngIf="!darkMode && !invalid_lightTitle">{{cdSettingsForm.value.lightTitle}}</span>
                                                        <span *ngIf="!darkMode && invalid_lightTitle" id="invalid_lightTitle" style="color:red;">{{'pass.invalid_color' | translate}}</span> 
                                                        <ngx-colors ngx-colors-trigger [palette]="colorPalette" [hideTextInput]="false" [format]="'hex'" cancelLabel="{{'pass.colorPickerCancel' | translate}}" acceptLabel="{{'pass.colorPickerConfirm' | translate}}" formControlName="lightTitle" style="margin-left:10px;" *ngIf="!darkMode"></ngx-colors>
                                                        
                                                        <span class="px-3" *ngIf="darkMode && !invalid_darkTitle">{{cdSettingsForm.value.darkTitle}}</span>
                                                        <span *ngIf="darkMode && invalid_darkTitle" id="invalid_darkTitle" style="color:red;">{{'pass.invalid_color' | translate}}</span> 
                                                        <ngx-colors ngx-colors-trigger [palette]="colorPalette" [hideTextInput]="false" [format]="'hex'" cancelLabel="{{'pass.colorPickerCancel' | translate}}" acceptLabel="{{'pass.colorPickerConfirm' | translate}}" formControlName="darkTitle" style="margin-left:10px;" *ngIf="darkMode"></ngx-colors>
                                                       
                                                    </div>
                                                </div>
                                            </mat-list-item>
                                            <mat-divider></mat-divider>
                                            <mat-list-item role="listitem">
                                                <div class="flex-between-center">
                                                    <div>{{'pass.subtitle' | translate}}</div>
                                                    <div class="flex-between-center">
                                                        <span *ngIf="!darkMode && invalid_lightSubtitle" id="invalid_lightSubtitle" style="color:red;">{{'pass.invalid_color' | translate}}</span> 
                                                        <span class="px-3" *ngIf="!darkMode && !invalid_lightSubtitle">{{cdSettingsForm.value.lightSubtitle}}</span>
                                                        <ngx-colors ngx-colors-trigger [palette]="colorPalette" [hideTextInput]="false" [format]="'hex'" cancelLabel="{{'pass.colorPickerCancel' | translate}}" acceptLabel="{{'pass.colorPickerConfirm' | translate}}" formControlName="lightSubtitle" style="margin-left:10px;" *ngIf="!darkMode"></ngx-colors>
                                                        
                                                        <span class="px-3" *ngIf="darkMode && !invalid_darkSubtitle">{{cdSettingsForm.value.darkSubtitle}}</span>
                                                        <span *ngIf="darkMode && invalid_darkSubtitle" id="invalid_darkSubtitle" style="color:red;">{{'pass.invalid_color' | translate}}</span>   
                                                        <ngx-colors ngx-colors-trigger [palette]="colorPalette" [hideTextInput]="false" [format]="'hex'" cancelLabel="{{'pass.colorPickerCancel' | translate}}" acceptLabel="{{'pass.colorPickerConfirm' | translate}}" formControlName="darkSubtitle" style="margin-left:10px;" *ngIf="darkMode"></ngx-colors>
                                                                                                       
                                                    </div>
                                                </div>
                                            </mat-list-item>
                                            <mat-divider></mat-divider>
                                            <mat-list-item role="listitem">
                                                <div class="flex-between-center">
                                                    <div>{{'pass.primary' | translate}} <span class="hint-text">{{'colorHintPrimary' | translate}}</span></div>
                                                    <div class="flex-between-center">
                                                        <span class="px-3" *ngIf="!darkMode && !invalid_lightPrimary">{{cdSettingsForm.value.lightPrimary}}</span>
                                                        <span *ngIf="!darkMode && invalid_lightPrimary" id="invalid_lightPrimary" style="color:red;">{{'pass.invalid_color' | translate}}</span>
                                                        <ngx-colors ngx-colors-trigger [palette]="colorPalette" [hideTextInput]="false" [format]="'hex'" cancelLabel="{{'pass.colorPickerCancel' | translate}}" acceptLabel="{{'pass.colorPickerConfirm' | translate}}" formControlName="lightPrimary" style="margin-left:10px;" *ngIf="!darkMode"></ngx-colors>
                                                        
                                                        <span class="px-3" *ngIf="darkMode && !invalid_darkPrimary">{{cdSettingsForm.value.darkPrimary}}</span>
                                                        <span *ngIf="darkMode && invalid_darkPrimary" id="invalid_darkPrimary" style="color:red;">{{'pass.invalid_color' | translate}}</span>
                                                        <ngx-colors ngx-colors-trigger [palette]="colorPalette" [hideTextInput]="false" [format]="'hex'" cancelLabel="{{'pass.colorPickerCancel' | translate}}" acceptLabel="{{'pass.colorPickerConfirm' | translate}}" formControlName="darkPrimary" style="margin-left:10px;" *ngIf="darkMode"></ngx-colors>
                                                    </div>
                                                </div>
                                            </mat-list-item>
                                            <!--  <mat-divider></mat-divider>
                                           <mat-list-item role="listitem">
                                                <div class="flex-between-center">
                                                    <div>{{'pass.secondary' | translate}}</div>
                                                    <div class="flex-between-center">
                                                        <span class="px-3" *ngIf="!darkMode">{{cdSettingsForm.value.lightSecondary}}</span>
                                                        <ngx-colors ngx-colors-trigger [palette]="colorPalette" [hideTextInput]="false" [format]="'hex'" formControlName="lightSecondary" style="margin-left:10px;" *ngIf="!darkMode"></ngx-colors>
                                                        <span class="px-3" *ngIf="darkMode">{{cdSettingsForm.value.darkSecondary}}</span>
                                                        <ngx-colors ngx-colors-trigger [palette]="colorPalette" [hideTextInput]="false" [format]="'hex'" formControlName="darkSecondary" style="margin-left:10px;" *ngIf="darkMode"></ngx-colors>
                                                    </div>
                                                </div>
                                            </mat-list-item> -->
                                            <mat-divider></mat-divider>
                                            <mat-list-item role="listitem">
                                                <div class="flex-between-center">
                                                    <div>{{'pass.accent' | translate}} <span class="hint-text">{{'colorHintAccent' | translate}}</span></div>
                                                    <div class="flex-between-center">
                                                        <span class="px-3" *ngIf="!darkMode && !invalid_lightAccent">{{cdSettingsForm.value.lightAccent}}</span>
                                                        <span *ngIf="!darkMode && invalid_lightAccent" id="invalid_lightAccent" style="color:red;">{{'pass.invalid_color' | translate}}</span>
                                                        <ngx-colors ngx-colors-trigger [palette]="colorPalette" [hideTextInput]="false" [format]="'hex'" cancelLabel="{{'pass.colorPickerCancel' | translate}}" acceptLabel="{{'pass.colorPickerConfirm' | translate}}" formControlName="lightAccent" style="margin-left:10px;" *ngIf="!darkMode"></ngx-colors>
                                                        
                                                        <span class="px-3" *ngIf="darkMode && !invalid_darkAccent">{{cdSettingsForm.value.darkAccent}}</span>
                                                        <span *ngIf="darkMode && invalid_darkAccent" id="invalid_darkAccent" style="color:red;">{{'pass.invalid_color' | translate}}</span>
                                                        <ngx-colors ngx-colors-trigger [palette]="colorPalette" [hideTextInput]="false" [format]="'hex'" cancelLabel="{{'pass.colorPickerCancel' | translate}}" acceptLabel="{{'pass.colorPickerConfirm' | translate}}" formControlName="darkAccent" style="margin-left:10px;" *ngIf="darkMode"></ngx-colors>
                                                    </div>
                                                </div>
                                            </mat-list-item>
                                        </mat-list>

                                        <div class="d-flex justify-content-end my-2">
                                            <button *ngIf="darkMode"
                                                    (click)="copyLightToDark()"
                                                    mat-button
                                                    style="margin-right: 10px;"
                                                    class="secondary-gray-button"
                                            >
                                                <mat-icon>copy_all</mat-icon> {{'pass.darkAsLight' | translate}}
                                            </button>
                                            <button (click)="resetColors()" mat-button 
                                                    class="secondary-gray-button"
                                                    [disabled]="isResetColorsDisabled"
                                            >
                                                <mat-icon>format_color_reset</mat-icon> {{'pass.resetColors' | translate}}
                                            </button>
                                        </div>


                                    </mat-card-content>
                                </mat-tab>

                                <!-- //////////  WIDGET SETTINGS  ///////////////// -->
                                <mat-tab>
                                    <ng-template mat-tab-label>
                                        <mat-icon class="material-symbols-outlined margin-right">splitscreen</mat-icon>  Widgets
                                    </ng-template>
                                    <mat-card-content class="p-4">
                                        <label class="my-3 text-muted fs-6 text-uppercase d-flex align-items-center"><mat-icon style="margin-right: 8px;">rounded_corner</mat-icon> {{'pass.cornerRadius' | translate}}</label>
                                        <mat-form-field appearance="outline" class="w-100 mb-3">
                                            <mat-label>{{'pass.cornerRadius' | translate}}</mat-label>
                                            <input min="0" type="number" matInput formControlName="cornerRadius" type="number" placeholder="10px Standard">
                                            <span matSuffix class="p-3"> px</span>
                                            <mat-hint>{{'pass.cornerRadiusHint' | translate}}</mat-hint>
                                        </mat-form-field>
                                        <label class="my-3 text-muted fs-6 text-uppercase d-flex align-items-center"><mat-icon style="margin-right: 8px;">format_line_spacing</mat-icon> {{'pass.widgetSpacing' | translate}}</label>
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>{{'pass.widgetSpacing' | translate}}</mat-label>
                                            <input type="number" min="0" matInput formControlName="widgetSpacing" placeholder="20px Standard">
                                            <span matSuffix class="p-3"> px</span>
                                            <mat-hint>{{'pass.widgetSpacingHint' | translate}}</mat-hint>
                                        </mat-form-field>
                                    </mat-card-content>
                                </mat-tab>

                                <mat-tab>
                                    <ng-template mat-tab-label>
                                        <mat-icon class="material-symbols-outlined margin-right">font_download</mat-icon>  {{'pass.fontsLc' | translate}}
                                    </ng-template>
                                    <mat-card-content class="p-4">
                                        <mat-accordion>
                                            <mat-expansion-panel expanded>
                                                <mat-expansion-panel-header>
                                                    <mat-panel-title>
                                                        <mat-icon style="margin-right: 10px;">text_fields</mat-icon>
                                                        {{ 'pass.assignFonts' | translate }}
                                                    </mat-panel-title>
                                                </mat-expansion-panel-header>
                                                <div class="font-assign">
                                                    <mat-form-field appearance="outline" class="w-100">
                                                        <mat-label>{{'pass.title' | translate}}</mat-label>
                                                        <mat-select formControlName="titleFont">
                                                            <mat-option value=""> - </mat-option>
                                                            <mat-option *ngFor="let font of uploadedFonts" [value]="font">{{ font.name }}</mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                    <mat-form-field appearance="outline" class="w-100">
                                                        <mat-label>{{'pass.subtitle' | translate}}</mat-label>
                                                        <mat-select formControlName="subtitleFont">
                                                            <mat-option value=""> - </mat-option>
                                                            <mat-option *ngFor="let font of uploadedFonts" [value]="font">{{ font.name }}</mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                    <mat-form-field appearance="outline" class="w-100">
                                                        <mat-label>{{'pass.plain' | translate}}</mat-label>
                                                        <mat-select formControlName="plainFont">
                                                            <mat-option value=""> - </mat-option>
                                                            <mat-option *ngFor="let font of uploadedFonts" [value]="font">{{ font.name }}</mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                    <mat-form-field appearance="outline" class="w-100">
                                                        <mat-label>{{'pass.bold' | translate}}</mat-label>
                                                        <mat-select formControlName="boldFont">
                                                            <mat-option value=""> - </mat-option>
                                                            <mat-option *ngFor="let font of uploadedFonts" [value]="font">{{ font.name }}</mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                    <mat-form-field appearance="outline" class="w-100">
                                                        <mat-label>{{'pass.italic' | translate}}</mat-label>
                                                        <mat-select formControlName="italicFont">
                                                            <mat-option value=""> - </mat-option>
                                                            <mat-option *ngFor="let font of uploadedFonts" [value]="font">{{ font.name }}</mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </div>
                                            </mat-expansion-panel>
                                            <mat-expansion-panel>
                                                <mat-expansion-panel-header>
                                                    <mat-panel-title>
                                                        <mat-icon style="margin-right: 10px;">library_add</mat-icon>
                                                        {{ 'pass.manageFonts' | translate }}
                                                    </mat-panel-title>
                                                </mat-expansion-panel-header>
                                            
                                                <div *ngIf="emptyFonts" appDragAndDrop (files)="fontsDropped($event, 'title')">
                                                    <mat-card class="font-upload-section text-center justify-content-center my-2">
                                                        <div style="margin:0 auto; height:100%;" class="d-flex justify-content-end flex-column">
                                                            <div class="py-2 mb-5">
                                                                <mat-icon class="material-symbols-outlined">upload_file</mat-icon>
                                                                <h2>
                                                                    {{ 'pass.fileUploadMessage' | translate }}
                                                                    <button type="button" class="labelFonts" (click)="triggerFontFileInput()">
                                                                        <strong style="font-family: 'MyFont-Bold', sans-serif;">{{ 'pass.browse' | translate }}</strong>
                                                                    </button>
                                                                    <input type="file" id="myfile-pick-font" name="myfile-pick-font" style="display: none;" accept=".ttf, .otf" (change)="readFontURL($event)">
                                                                </h2>
                                                            </div>
                                                            <p class="py-2 mt-3">{{ 'pass.supportedFiles' | translate }}: .ttf, .otf</p>
                                                        </div>
                                                    </mat-card>
                                                </div>
                                                <div *ngIf="!emptyFonts">
                                                    <div class="my-2">
                                                        <mat-list class="font-list">
                                                            <div *ngFor="let font of uploadedFonts">
                                                                <mat-list-item>
                                                                    <div class="d-flex justify-content-between align-items-center">
                                                                        <div class="font-name">{{ font.name }}</div>
                                                                        <div class="delete-icon">
                                                                            <button mat-icon-button style="color: var(--warmOrange);" (click)="deleteFont(font)">
                                                                                <mat-icon class="material-symbols-outlined">delete</mat-icon>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </mat-list-item>
                                                                <mat-divider></mat-divider>
                                                            </div>
                                                        </mat-list>
                                                        <button mat-button class="secondary-gray-button mt-3" (click)="triggerFontFileInput()">
                                                            <mat-icon>upload</mat-icon>
                                                            <span>{{'uploadNewFont' | translate}}</span>
                                                        </button>
                                                        <input type="file" id="myfile-pick-font" name="myfile-pick-font" style="display: none;" accept=".ttf, .otf" (change)="readFontURL($event)">
                                                        <p style="font-size:12px; color:var(--warmOrange);" class="py-2 mt-3">{{ 'pass.supportedFiles' | translate }}: .ttf, .otf</p>
                                                    </div>
                                                </div>
                                            </mat-expansion-panel>
                                            
                                        </mat-accordion>
                                        
                                    </mat-card-content>
                                </mat-tab>

                            </mat-tab-group>
                        
                        </mat-card-header>
                    </form>
                    <mat-card-actions class="p-3 d-flex justify-content-between">
                        <button mat-button style="color:var(--warmOrange);" (click)="resetCdSettings()">
                            <mat-icon class="material-symbols-outlined">restart_alt</mat-icon> {{'pass.resetAll' | translate}}
                        </button>
                        <button mat-button
                                [disabled]="saveDisabled
                                || cdSettingsForm.invalid"
                                (click)="saveCdSettings()" class="orange-button">{{'save' | translate}}</button>

                    </mat-card-actions>
                </mat-card>
            </div>
        </div>
    </div>
</section>


