<h2 mat-dialog-title>{{'pass.widgets.types.composed' | translate}}</h2>

<mat-dialog-content style="max-width: 600px;">
    <div class="pt-2 pb-3">
        <section class="mt-1 mb-3 rounded m-auto w-100" id="section" >
            <form [formGroup]="composedEditForm">

                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'pass.name' | translate}}</mat-label>
                    <input formControlName="name" type="text" matInput placeholder="{{'pass.name' | translate}}">
                </mat-form-field>

                <div class="d-flex flex-row">
                    <div class="pe-2 flex-grow-1">
                        <div class="input-group">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>{{'pass.title' | translate}}</mat-label>
                                <input formControlName="title" type="text" matInput placeholder="{{'pass.title' | translate}}">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="ps-2 flex-grow-1">
                        <div class="input-group">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>{{'pass.subtitle' | translate}}</mat-label>
                                <input formControlName="subtitle" type="text" matInput placeholder="{{'pass.subtitle' | translate}}">
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <div class="d-flex flex-row justify-content-between align-items-center">
                    <span class="text-muted">{{'pass.composed.content' | translate}}</span>
                
                    <div class="d-flex flex-row justify-content-between align-items-center">
                        <button mat-button class="secondary-gray-button my-3" id="btn-add" (click)="deleteAllContent()" [disabled]="allproperties?.length == 0">
                            <mat-icon>close</mat-icon> {{'delete' | translate}}
                        </button>
            
                        <button mat-button class="secondary-gray-button my-1 ms-2" id="btn-add-composed" [matMenuTriggerFor]="composedTypesNodeAdd" >
                            <mat-icon>add_circle</mat-icon> {{'add' | translate}} 
                        </button>
                    </div>

                    <mat-menu #composedTypesNodeAdd="matMenu">
                        <button mat-menu-item *ngFor="let composed of composedOptions; let index = index;" (click)="addContentNode(composed);"> 
                            <mat-icon>{{composed.icon}}</mat-icon>{{composed.translate | translate}} 
                        </button>
                    </mat-menu>
                </div>
                <mat-card class="mb-4">
                    <mat-card-content>
                        
                        <div class="p-2">
                            <div *ngIf="allproperties?.length==0">{{'empty' | translate}}</div>

                            <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree">
                                <!-- This is the tree node template for leaf nodes -->
                                <!-- There is inline padding applied to this node using styles. This padding value depends on the mat-icon-button width. -->
                                <mat-tree-node *matTreeNodeDef="let node; let index = index" matTreeNodeToggle class=" border-bottom" >
                                    <div class="w-100 d-flex justify-content-between align-items-center">
                                        <div class="d-flex flex-row justify-content-start flex-nowrap align-items-center w-75" (click)="editNodeAttributes(node, index)">
                                            <mat-icon *ngIf="node.type=='text'" style="color:var(--warmOrange);">text_format</mat-icon>
                                            <mat-icon *ngIf="node.type=='image'" style="color:var(--warmOrange);">image</mat-icon>
                                            <mat-icon *ngIf="node.type=='hstack'" style="color:var(--warmOrange);">view_column</mat-icon>
                                            <mat-icon *ngIf="node.type=='vstack'" style="color:var(--warmOrange);">table_rows</mat-icon>
                                            <mat-icon *ngIf="node.type=='zstack'" style="color:var(--warmOrange);">desktop_landscape</mat-icon>
                                            <mat-icon *ngIf="node.type=='circle'" style="color:var(--warmOrange);">circle</mat-icon>
                                            <mat-icon *ngIf="node.type=='rectangle'" style="color:var(--warmOrange);">rectangle</mat-icon>
                                            
                                            <span *ngIf="node.type=='text'" class="p-1 text-truncate w-75" style="color:black;" >{{node?.attributes?.text ? node?.attributes?.text : '""' }}</span>
                                            <span *ngIf="node.type=='image'" class="p-1 text-truncate w-75" style="color:black;" >{{node?.attributes?.url ? node?.attributes?.url : '""' }}</span>
                                            <span *ngIf="node.type=='hstack'" class="p-1" style="color:black;" >H-Stack</span>
                                            <span *ngIf="node.type=='vstack'" class="p-1" style="color:black;" >V-Stack</span>
                                            <span *ngIf="node.type=='zstack'" class="p-1" style="color:black;" >Z-Stack</span>
                                            <span *ngIf="node.type=='circle'" class="p-1" style="color:black;" >Circle</span>
                                            <span *ngIf="node.type=='rectangle'" class="p-1" style="color:black;" >Rectangle</span>
    
                                        </div>
    
                                        <button mat-icon-button (click)="deleteThisNode(node)"><mat-icon>delete</mat-icon></button>
                                    </div>
                                </mat-tree-node>
                                <!-- This is the tree node template for expandable nodes -->
                                <mat-nested-tree-node *matTreeNodeDef="let node;let index = index; when: hasChild" class=" border-bottom" >
                                    <div class="mat-tree-node border-bottom">
                                        <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
                                            <mat-icon class="mat-icon-rtl-mirror">
                                            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                                            </mat-icon>
                                        </button>
                                        <div class="d-flex flex-row justify-content-between flex-nowrap align-items-center w-100">
                                            <div class="d-flex flex-row justify-content-start flex-nowrap align-items-center w-100">
                                                <mat-icon *ngIf="node.type=='text'" style="color:var(--warmOrange);">text_format</mat-icon>
                                                <mat-icon *ngIf="node.type=='image'" style="color:var(--warmOrange);">image</mat-icon>
                                                <mat-icon *ngIf="node.type=='hstack'" style="color:var(--warmOrange);">view_column</mat-icon>
                                                <mat-icon *ngIf="node.type=='vstack'" style="color:var(--warmOrange);">table_rows</mat-icon>
                                                <mat-icon *ngIf="node.type=='zstack'" style="color:var(--warmOrange);">desktop_landscape</mat-icon>
                                                <mat-icon *ngIf="node.type=='circle'" style="color:var(--warmOrange);">circle</mat-icon>
                                                <mat-icon *ngIf="node.type=='rectangle'" style="color:var(--warmOrange);">rectangle</mat-icon>

                                                <span *ngIf="node.type=='text'" class="p-1 text-truncate w-75" style="color:black;" >{{node?.attributes?.text ? node?.attributes?.text : '""' }}</span>
                                                <span *ngIf="node.type=='image'" class="p-1 text-truncate w-75" style="color:black;" >{{node?.attributes?.url ? node?.attributes?.url : '""' }}</span>
                                                <span *ngIf="node.type=='hstack'" class="p-1" style="color:black;" >H-Stack</span>
                                                <span *ngIf="node.type=='vstack'" class="p-1" style="color:black;" >V-Stack</span>
                                                <span *ngIf="node.type=='zstack'" class="p-1" style="color:black;" >Z-Stack</span>
                                                <span *ngIf="node.type=='circle'" class="p-1" style="color:black;" >Circle</span>
                                                <span *ngIf="node.type=='rectangle'" class="p-1" style="color:black;" >Rectangle</span>
                                            </div>
                                            <button mat-icon-button id="btn-edit-node-composed" (click)="editNodeAttributes(node, index)" >
                                                <mat-icon >edit</mat-icon> 
                                            </button>

                                            <button mat-icon-button id="btn-delete-node-composed" (click)="deleteThisNode(node)"><mat-icon>delete</mat-icon></button>

                                            <button mat-icon-button id="btn-add-node-composed" [matMenuTriggerFor]="composedTypesNode" >
                                                <mat-icon >add_circle</mat-icon>
                                            </button>
                                        </div>

                                        <mat-menu #composedTypesNode="matMenu">
                                            <button mat-menu-item *ngFor="let composed of composedOptions; let index = index;" (click)="addChildrenNode(node, composed);"> 
                                                <mat-icon>{{composed.icon}}</mat-icon>{{composed.translate | translate}} </button>
                                        </mat-menu>
                                    </div>
                                    <!-- There is inline padding applied to this div using styles.
                                        This padding value depends on the mat-icon-button width.  -->
                                    <div [class.example-tree-invisible]="!treeControl.isExpanded(node)" role="group">
                                        <ng-container matTreeNodeOutlet></ng-container>
                                    </div>
                                </mat-nested-tree-node>
                            </mat-tree>
                                
                        </div>

                    </mat-card-content>
                </mat-card>

                <div *ngIf="userHasBetaWidgetRole" [app-widget-styling] = "composedEditForm.value" (returnChanges)="returnChangesStyle($event)"></div>

            </form>
        </section>
    </div>

    <div [app-widget-edit-filters] = "composedEditData" (returnChanges)="returnChangesFilters($event)"></div>

</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button style="color:var(--warmOrange);" [mat-dialog-close]="oldData" >{{'cancel' | translate}}</button>
    <button mat-button class="orange-button" (click)="saveChanges();"  >{{'apply' | translate}}</button>
</mat-dialog-actions>
